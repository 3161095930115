import { Component, Input, HostBinding } from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
import { Router } from '@angular/router';
import { SecurityService } from '../../core/services/security.service';

@Component({
	selector: 'app-layout-navbar',
	templateUrl: './layout-navbar.component.html',
	styleUrls: ['./layout-navbar.component.scss']
})
export class LayoutNavbarComponent {
	isExpanded = false;
	isRTL: boolean;

	get userName(): string {
		return this.securityService.currentUser.userName;
	}

	@Input() sidenavToggle = true;

	@HostBinding('class.layout-navbar') private hostClassMain = true;

	constructor(
		private appService: AppService,
		private layoutService: LayoutService,
		private securityService: SecurityService,
		private router: Router) {
			this.isRTL = appService.isRTL;
	}

	currentBg() {
		return `bg-${this.appService.layoutNavbarBg}`;
	}

	toggleSidenav() {
		this.layoutService.toggleCollapsed();
	}

	logout(){
		this.securityService.logout();
		this.router.navigateByUrl('login');
	}
}
