import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LaddaModule } from 'angular2-ladda';
import { SalaryCalcReportListPage } from './salary-calc-reports/list/list.page';
import { SalaryCalcReportPage } from './salary-calc-reports/salary-calc-report/salary-calc-report.page';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { IntlModule } from '@progress/kendo-angular-intl';
import { AnalyticReportListPage } from './analytic-reports/list/list.page';
import { AnalyticReportPage } from './analytic-reports/analytic-report/analytic-report.page';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { RequestToAgreeReportListPage } from './request-to-agree-reports/list/list.page';
import { RequestToAgreeReportPage } from './request-to-agree-reports/request-to-agree-report/request-to-agree-report.page';
import { OrgStructureReportPage } from './org-structure-report/org-structure-report.page';

@NgModule({
  declarations: [

    SalaryCalcReportListPage,
    SalaryCalcReportPage,

    AnalyticReportListPage,
    AnalyticReportPage,

    RequestToAgreeReportListPage,
    RequestToAgreeReportPage,

    OrgStructureReportPage

  ],
  imports: [
    CommonModule,
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    LaddaModule,
    ExcelModule,
    IntlModule,
    LayoutModule
  ]
})
export class ReportsModule { }
