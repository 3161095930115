import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../core/services/notification.service';
import { AppService } from '../../app.service';
import { OrgStructureReporsService } from '../../shared/services/org-structure-report.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { OrgStructureReportUnit } from '../../shared/models/Reports/OrgStructure/OrgStructureReportUnit';
import { OrgStructureReportUnitNodeComponent } from '../../shared/components/org-structure-report-unit-node/org-structure-report-unit-node';
import { TrackChangesComponent } from '../../core/interfaces/component-can-deactivate';
import { OrgStructureEmployee } from '../../shared/models/Reports/OrgStructure/OrgStructureEmployee';

@Component({
  selector: 'org-structure-report',
  templateUrl: './org-structure-report.page.html',
  styleUrls: [
    '../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
    './org-structure-report.page.scss'
  ]
})
export class OrgStructureReportPage implements OnInit, TrackChangesComponent {

  public innerHeight: string;
  public orgStructure: OrgStructureReportUnit[] = [];
  public currentEmployee: OrgStructureEmployee;
  public stage: number = 0;
  public currentOrgUnitId: number = null;
  public emptyResponse: boolean = false;

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    protected dialogService: DialogService,
    public orgStructureService: OrgStructureReporsService
  ) {

    this.appService.pageTitle = "Отчет по орг. структуре";
  }
  hasChanges(): boolean {
    return false;
  };

  ngOnInit() {
    this.loadOrgStructure();
    this.innerHeight = `${window.innerHeight - 170}px`;
  }

  public getApprovalChain(employeeId: number): void {
    this.currentEmployee = null;
    this.stage = 0;
    this.orgStructureService.getApprovalChain(employeeId).subscribe(resp => this.currentEmployee = resp.data);
  }

  public getEmployeeDisplayName(employee: OrgStructureEmployee): string {
    return employee.fullName ? `${employee.fullName}, ${employee.positionName}` : employee.positionName;
  }

  public filterByOrgUnitId(id?: number): void {
    this.currentOrgUnitId = id;
    this.loadOrgStructure();
  }

  private loadOrgStructure(): void {
    this.orgStructureService.getStructure(this.currentOrgUnitId).subscribe(resp => {
      this.orgStructure = resp.data;
      this.emptyResponse = resp.data.length == 0;
    });
  }
}
