import { BaseObject } from "../core/BaseObject";

export class ServiceCenter extends BaseObject {
    serviceCenterId: number;

    serviceCenterName: string;

    id1C: string;
    idSD: string;

    addressString: string;
    postCode: string;
    district: string;
    city: string;
    street: number;
    house: string;
    block: string;
    nearestMetroStation: string;

    latitude: number;
    longitude: number;
}