import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { MailingRegister } from '../models/mailings/mailing-register';

@Injectable()
export class MailingRegistersService extends DataService<MailingRegister> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "mailing-registers";
	}

}