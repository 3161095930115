import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { RequestsService } from '../../../shared/services/requests.service';
import { Request } from '../../../shared/models/Request/Request';
import { EmployeeService } from '../../../shared/services/employee.service';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { Employee } from '../../../shared/models/Employee/Employee';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CompletionCode } from '../../../shared/models/CompletionCode';
import { Customer } from '../../../shared/models/Customer/Customer';
import { CompletionCodeService } from '../../../shared/services/completion-codes.service';
import { CustomerService } from '../../../shared/services/customer.service';
import { MaintainObjectService } from '../../../shared/services/maintain-object.service';
import { MaintainObject } from '../../../shared/models/MaintainObject/MaintainObject';
import { ServiceCenter } from '../../../shared/models/ServiceCenter/ServiceCenter';
import { ServiceCenterService } from '../../../shared/services/service-center.service';

@Component({
	selector: 'document-request',
	templateUrl: './request.page.html',
	styleUrls: ['./request.page.scss']
})
export class RequestPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('requestForm') public requestForm: NgForm;

	hasChanges(): boolean{
		return !this.requestForm.pristine;
	};

	canEditForm: boolean;
	requestId: string;
	request: Request;

	employees: Employee[] = [];
	completionCodes: CompletionCode[] = [];
	customers: Customer[] = [];
	maintainObjects: MaintainObject[] = [];
	selectedMaintainObject: MaintainObject;
	serviceCenters: ServiceCenter[] = [];

	public dropDownFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private requestsService: RequestsService,
		private notificationService: NotificationService,
		private employeeService: EmployeeService,
		private completionCodeService: CompletionCodeService,
		private customerService: CustomerService,
		private maintainObjectService: MaintainObjectService,
		private serviceCenterService: ServiceCenterService) {
			this.appService.pageTitle = "Новая заявка";
		}

	ngOnInit() {

		this.loadDropDownsData();

		this.requestId = this.route.snapshot.paramMap.get('requestId');

		if (this.requestId != null){

			this.requestsService.getById(+this.requestId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = `Заявка ${r.data.requestNumber}`;
			});

		} else {

			this.request = new Request();
			this.request.createdDate = new Date();
			this.request.requestDate = new Date();

			this.request.plannedStartDate = new Date();
			this.request.recoveryDate = new Date();
			this.request.factEndDate = new Date();
			this.request.periodDate = new Date();

			this.request.hasAct = true;

			this.request.actDate = new Date();

			this.request.entityStatus = 1;
			this.request.entityStatusString = "Активен";

			this.dataBind(this.request);
			this.canEditForm = true;
		}

	}

	dataBind(request: Request){

		request.createdDate = this.appService.parseDate(request.createdDate);
		request.requestDate = this.appService.parseDate(request.requestDate);
		request.plannedStartDate = this.appService.parseDate(request.plannedStartDate);
		request.recoveryDate = this.appService.parseDate(request.recoveryDate);
		request.factEndDate = this.appService.parseDate(request.factEndDate);
		request.periodDate = this.appService.parseDate(request.periodDate);
		request.actDate = this.appService.parseDate(request.actDate);

		this.request = request;

		if (this.request.maintainObjectId)
			this.maintainObjectService.getById(this.request.maintainObjectId).subscribe(r => this.selectedMaintainObject = r.data);
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.requestsService.upsert(this.request).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.requestForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.request.requestNumber}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.request.requestNumber}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.request.requestNumber}`,
			message: `Вы уверены что хотите удалить заявку '${this.request.requestNumber}'?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.requestsService.remove(this.request.requestId).subscribe(r => {

				this.router.navigate(['/documents/requests']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Заявка '${this.request.requestNumber}' успешно удалена`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении заявки`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.request.requestNumber}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.requestsService.archive(this.request.requestId).subscribe(r => {

				this.appService.IsEntityDeleted = true;
				this.appService.RouteAfterEntityDeleted = '/documents/requests';

				this.router.navigate([this.appService.RouteAfterEntityDeleted]);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.request.requestNumber}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}



	loadDropDownsData() {

		var employeeState: DataSourceRequestState = { skip: 0, take: 10000,
			sort: [{ field: "surname", dir: "asc" }],
		};

		var state: DataSourceRequestState = { skip: 0, take: 10000,
			sort: [{ field: "name", dir: "asc" }],
		};

		var maintainObjectState: DataSourceRequestState = { skip: 0, take: 10000,
			sort: [{ field: "objectCode", dir: "asc" }],
		};

		var serviceCenterState: DataSourceRequestState = { skip: 0, take: 10000,
			sort: [{ field: "serviceCenterName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]} 
		};

		this.employeeService.list(employeeState).subscribe(x => this.employees = x.data);
		this.completionCodeService.list(state).subscribe(x => this.completionCodes = x.data);
		this.customerService.list(state).subscribe(x => this.customers = x.data);
		this.maintainObjectService.list(maintainObjectState).subscribe(x => this.maintainObjects = x.data);
		this.serviceCenterService.list(serviceCenterState).subscribe(x => this.serviceCenters = x.data);
		
	}

	maintainObjectSelectionChange(maintainObject: MaintainObject): void{
		this.selectedMaintainObject = maintainObject;
		this.request.maintainObjectId = maintainObject.maintainObjectId;
	}

	hasActChanged(){

		if (this.request.hasAct){
			this.request.actDate = new Date();
		} else {
			this.request.actDate = null;
		}

	}

}