import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { MaintainObject } from '../models/MaintainObject/MaintainObject';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class MaintainObjectService extends DataService<MaintainObject> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "maintainObject";
	}
}