import { BaseObject } from "../core/BaseObject";
import { UserRole } from "./UserRole";
import { UserEmployee } from "./UserEmployee";
import { UserOrgUnit } from './UserOrgUnit';

export class User extends BaseObject {
    userId: number;
    surname: string;
    name: string;
    middleName: string;
    displayName: string;

    login: string;
    passwordHash: string;
    isBlocked: boolean;

    userRoles: UserRole[] = [];
    userEmployees: UserEmployee[] = [];
    userOrgUnits: UserOrgUnit[] = [];
}