import { Component, Input } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { KeyValueObject } from '../../../../models/core/KeyValueObject';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'dropdown-filter',
    templateUrl: './dropdown-filter.component.html',
})
export class DropDownFilterComponent extends BaseFilterCellComponent {

    public filterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    @Input()
    public filter: CompositeFilterDescriptor;

    @Input()
    public data: KeyValueObject[] = [];

    @Input()
    public fieldName: string;

    public get defaultItem(): KeyValueObject {
        return new KeyValueObject(null, '(Все)', '');
    }

    public get selectedValue(): any {
        const filter = this.filterByField(this.fieldName);
        return filter ? filter.value : null;
    }

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public onChange(value: any): void {
        this.applyFilter(
            value === null ? // value of the default item
                this.removeFilter(this.fieldName) : // remove the filter
                this.updateFilter({ // add a filter for the field with the value
                    field: this.fieldName,
                    operator: 'eq',
                    value: value
                })
        ); // update the root filter
    }
}
