import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { SalaryCalcReport } from '../../../shared/models/Reports/SalaryCalc/SalaryCalcReport';
import { SalaryCalcReportService } from '../../../shared/services/salary-calc-report.service';
import { SalaryCalcReportRow } from '../../../shared/models/Reports/SalaryCalc/SalaryCalcReportRow';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { EmployeePeriodsComponent } from '../../../shared/components/employee-periods/employee-periods.component';
import { EmployeePeriod } from '../../../shared/models/Reports/employee-period';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from "@progress/kendo-data-query";
import { saveAs } from '@progress/kendo-file-saver';
import { SalaryCalcReportVersion } from '../../../shared/models/SalaryCalcReportVersion';

@Component({
	//encapsulation: ViewEncapsulation.None,
	selector: 'reports-salary-calc-report',
	templateUrl: './salary-calc-report.page.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./salary-calc-report.page.scss'
	]
})

export class SalaryCalcReportPage implements OnInit, TrackChangesComponent {

	isForWeekends: boolean = false;
	listUrl: string = "/reports/salary-calc/workdays";
	pageHeader: string = "Рабочие дни";
	
	@ViewChild('salaryCalcReportForm') public salaryCalcReportForm: NgForm;

	hasChanges(): boolean{
		return !this.salaryCalcReportForm.pristine;
	};

	canEditForm: boolean;
	isNewVersion: boolean;
	salaryCalcReportId: string;
	public salaryCalcReport: SalaryCalcReport;
	isNew: boolean;
	reportGenerating: boolean = false;
	excelGenerating: boolean = false;
	versions: SalaryCalcReportVersion[] = [ {versionId: 0, name: "Старый формат"}, {versionId: 1, name: "Новый формат"} ];

	get gridHeight(): number{
		return window.innerHeight - 240;
	}

	months = [ { id: 1, name: 'Январь' }, { id: 2, name: 'Февраль' }, {id: 3, name: 'Март'} , { id: 4, name: 'Апрель' }, { id: 5, name: 'Май' }, { id: 6, name: 'Июнь' }, {id: 7, name: 'Июль'}, { id: 8, name: 'Август'}, { id: 9, name: 'Сентябрь' }, { id: 10, name: 'Октябрь' }, { id: 11, name: 'Ноябрь' }, { id: 12, name: 'Декабрь' }];

    public state: DataSourceRequestState = {
		skip: 0,
		take: 50,
		sort: [{ field: "employeeName", dir: "asc" }],
		filter: {
			logic: "and",
			filters: []
		}
	};

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private salaryCalcReportService: SalaryCalcReportService,
		private notificationService: NotificationService,
		private filterStorage: FilterStorage,
		protected dialogService: DialogService,
		) {
			// если мы пришли по кнопке Back, следует восстановить фильтр из SessionStorage
			if (appService.IsBackNavButtonPressed){
				this.state = filterStorage.Get(this.state);
			}

			this.appService.pageTitle = "Новый расчет переработок";
		}

	public dataStateChange(state: DataStateChangeEvent): void {
		this.state = state;

		// сохранение фильтра в SessionStorage
		this.filterStorage.Save(this.state);
	}		

	ngOnInit() {
		
		this.isForWeekends = <boolean>this.route.snapshot.data.isForWeekends;

		if (this.isForWeekends){
			this.appService.pageTitle = "Расчет переработок в выходные дни";
			this.listUrl = "/reports/salary-calc/weekends";
			this.pageHeader = "Выходные дни";
		}

		this.salaryCalcReportService.setKind(this.isForWeekends);

		this.salaryCalcReportId = this.route.snapshot.paramMap.get('salaryCalcReportId');

		if (this.salaryCalcReportId != null){

			this.salaryCalcReportService.getById(+this.salaryCalcReportId).subscribe(r => {
				this.dataBind(r.data);
				this.appService.pageTitle = this.excelFileName;
				this.isNewVersion = this.salaryCalcReport.version == this.versions.find(v => v.name == "Новый формат").versionId;
			});

		} else {

			this.salaryCalcReport = new SalaryCalcReport();
			this.salaryCalcReport.createdDate = new Date();
			this.salaryCalcReport.month = this.salaryCalcReport.createdDate.getMonth();
			this.salaryCalcReport.year = this.salaryCalcReport.createdDate.getFullYear();
			this.salaryCalcReport.entityStatus = 1;
			this.salaryCalcReport.entityStatusString = "Активен";
			this.salaryCalcReport.isForWeekends = this.isForWeekends;

			this.dataBind(this.salaryCalcReport);
			this.canEditForm = true;
			this.isNew = true;
			this.salaryCalcReport.version = this.versions.find(v => v.name == "Новый формат").versionId
		}
	}

	getMonthName(monthNumber: number){

		if (!monthNumber)
			return "";

		var filtered = this.months.filter(x => x.id == monthNumber);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}

	get excelFileName() {

		if (!this.salaryCalcReport || !this.salaryCalcReport.month)
			return "Расчет переработок";

		return `Расчет переработок за ${this.getMonthName(this.salaryCalcReport.month)} ${this.salaryCalcReport.year}`;
	}

	dataBind(customer: SalaryCalcReport){
		this.salaryCalcReport = customer;
		this.salaryCalcReport.rows.forEach(r => r.distanceAmountStr = r.distanceAmount.toFixed(2))
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.salaryCalcReportService.upsert(this.salaryCalcReport).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.salaryCalcReportForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.excelFileName}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.excelFileName}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.excelFileName}`,
			message: `Вы уверены что хотите удалить расчет?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.salaryCalcReportService.remove(this.salaryCalcReport.salaryCalcReportId).subscribe(r => {

				this.router.navigate([this.listUrl]);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Расчет успешно удален`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении расчета`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	generateReport() {

		this.reportGenerating = true;

		this.salaryCalcReportService.getUntrackedRequests(this.salaryCalcReport.year, this.salaryCalcReport.month).subscribe(x => {

			if (!x.isSuccessful)
				return;

			if (this.isForWeekends && x.data.length > 0) {

				this.notificationService.confirmation({
					title: `Неоплаченные заявки`,
					message: `Имеются неоплаченные заявки в прошлых периодах. Учесть в текущем отчете?`,
					type: "question",
					confirmButtonText: "Да",
					cancelButtonText: "Нет",
				}, () => {

					var dialog = this.dialogService.open({
						title: `Неоплаченные заявки в прошлых периодах`,
						content: EmployeePeriodsComponent,
						width: 600,
						actionsLayout: 'normal',
						actions: [
							{ text: 'Отмена' },
              				{ text: 'Продолжить', primary: true }
						]
					});

					const employeePeriodsForm = <EmployeePeriodsComponent>dialog.content.instance;
					employeePeriodsForm.gridData = x.data;

					dialog.result.subscribe((result) => {
						if (result instanceof DialogCloseResult) {
							this.reportGenerating = false;
						} else {
							console.log(employeePeriodsForm.selectedEmployeePeriod);
							this.generateReportCore(employeePeriodsForm.selectedEmployeePeriod);
						}
					});

					
		
				}, () => {
					this.generateReportCore([]);
				});

			} else {
				this.generateReportCore([]);
			}

		});


	}

	generateReportCore(employeePeriods: EmployeePeriod[]) {

		this.reportGenerating = true;

		this.salaryCalcReport.untrackedEmployeePeriods = employeePeriods;
		
		this.salaryCalcReportService.generate(this.salaryCalcReport).subscribe(r => {
			this.reportGenerating = false;
			
			if (r.isSuccessful){
				this.appService.MarkFormPristine(this.salaryCalcReportForm);
				this.router.navigate([`/reports/salary-calc/${ this.isForWeekends ? 'weekends' : 'workdays' }`, r.data]);

			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.excelFileName}`, notificationType: NotificationType.Toast});
			}

		});
	}

	public cellClicked(event: any): void {
		let reportRow = <SalaryCalcReportRow>event.dataItem;

		let columnName = event.column.field.toLowerCase();

		let sourceItems = reportRow.rowSources.filter(r => r.columnName.toLowerCase() == columnName);

		if (sourceItems.length === 1) {
			let sourceItem = sourceItems[0];

			let destRoute = "/documents/requests";
			let keyFieldName = "requestId";

			if (columnName.toLowerCase() === "NonReglamentRequests".toLowerCase()) {
				destRoute = "/documents/manual-actions";
				keyFieldName = "manualActionId";
			}

			this.appService.OuterFilterRoute = destRoute;

			this.appService.OuterFilter = {
				skip: 0,
				take: 10,
				sort: [{ field: "createdDate", dir: "desc" }],
				filter: {
					logic: "or",
					filters: []
				}
			}

			this.appService.OuterFilter.filter.filters.push({field: keyFieldName, operator: "eq", value: -1 });

			for (let i = 0; i < sourceItem.requestIds.length; i++) {
				this.appService.OuterFilter.filter.filters.push({field: keyFieldName, operator: "eq", value: sourceItem.requestIds[i] })
			}

			// сохранение фильтра в SessionStorage перед переходом к детализации
			this.filterStorage.Save(this.state);

			this.router.navigate([destRoute]);
		}		
    }

	public exportToExcel(){
		this.excelGenerating = true;
		this.salaryCalcReportService.exportToExcel(+this.salaryCalcReportId)
			.subscribe(result => {
				if (result.isSuccessful) {
					const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.data}`;
					saveAs(dataURI, `${this.excelFileName}.xlsx`);
				}

				this.excelGenerating = false;
			}, error => this.excelGenerating = false);
	}

	public allExcelData = (): ExcelExportData => {
		var self = this;

		var result: ExcelExportData = {
		  data: process(self.salaryCalcReport.rows, {
			filter: self.state.filter,
			sort: self.state.sort,
		  }).data,
		};
	
		return result;
	}

}