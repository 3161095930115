import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoredFilter } from '../../../shared/models/StoredFilter/StoredFilter';
import { StoredFilterItem } from '../../../shared/models/StoredFilter/StoredFilterItem';
import { DataSourceRequestState } from '@progress/kendo-data-query';

@Injectable({
	providedIn: 'root'
})
export class FilterStorage {
    private _filter : StoredFilter = null;

	constructor(private router: Router) {
    }

    /** 
     * Поиск фильтра в хранилище для заданного route
    */
	public Get(defaultValue: DataSourceRequestState): DataSourceRequestState {
        let result : DataSourceRequestState = null;
        
        this.Load();

		let storedFilterItem = this._filter.items.find(item => item.route.toLowerCase() == this.router.routerState.snapshot.url.toLowerCase());

		if (storedFilterItem != null) {
			result = JSON.parse(storedFilterItem.filter, FilterStorage.ReviveDateTime);
		}

		return (result != null) ? result : defaultValue;
	};

	// отдельные усилия по десериализации дат
	private static ReviveDateTime(key: any, value: any): any 
    {
		let dateFormat1 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
		let dateFormat2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

		if (typeof value === "string" && (dateFormat1.test(value) || dateFormat2.test(value))) {
			return new Date(value);
		}
		
		return value;
    }

    /**
     * Сохранение фильтра компонента
     * @param state - текущее состояние фильтра
     */
	public Save(state: DataSourceRequestState): void {
        this.Load();

        let route = this.router.routerState.snapshot.url.toLowerCase();

		let storedFilterItem = this._filter.items.find(item => item.route.toLowerCase() == route);

		if (storedFilterItem != null) {
			let idx = this._filter.items.indexOf(storedFilterItem);

            this._filter.items.splice(idx, 1);
		}

		this._filter.items.push(new StoredFilterItem(route, JSON.stringify(state)));

		sessionStorage.setItem("filter", JSON.stringify(this._filter));
	}

    /**
     * Загрузка фильтра из SessionStorage
     */
	private Load(): void {
		var strFilter = sessionStorage.getItem("filter");

		if (strFilter) {
			this._filter = JSON.parse(strFilter);
		}
		else {
			this._filter = new StoredFilter();
		}
    }
}