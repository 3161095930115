export class KeyValueObject {

    constructor(id: number, name: string, entityStatusString: string) {        
        this.id = id;
        this.name = name;
        this.entityStatusString = entityStatusString;
    }

	id: number;
    name: string;
    entityStatusString: string;
}