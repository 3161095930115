import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { ManualAction } from '../models/ManualAction/ManualAction';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { environment } from '../../../environments/environment';

@Injectable()
export class ManualActionsService extends DataService<ManualAction> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "manualactions";
	}

	public isEmployeeHasRequestsToAgreeForWeekends(employeeId: number, month: number, year: number): Observable<ActionDataResult<boolean>> {
		return this.http.get<ActionDataResult<boolean>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/is-employee-has-requestsToAgree-for-weekends?employeeId=${employeeId}&month=${month}&year=${year}`);
	}
}