import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { RequestToAgreeReportService } from '../../../shared/services/request-to-agree-report.service';
import { RequestToAgreeReport } from '../../../shared/models/Reports/RequestToAgree/request-to-agree-report';

@Component({
	selector: 'request-to-agree-report-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class RequestToAgreeReportListPage extends ListPageBase {

	public reports: ActionDataResult<RequestToAgreeReport[]>;

	public months: any[] = [ { id: 1, name: 'Январь' }, { id: 2, name: 'Февраль' }, {id: 3, name: 'Март'} , { id: 4, name: 'Апрель' }, { id: 5, name: 'Май' }, { id: 6, name: 'Июнь' }, {id: 7, name: 'Июль'}, { id: 8, name: 'Август'}, { id: 9, name: 'Сентябрь' }, { id: 10, name: 'Октябрь' }, { id: 11, name: 'Ноябрь' }, { id: 12, name: 'Декабрь' }];

	constructor(
		appService: AppService,
		router: Router,
		private route: ActivatedRoute,
		filterStorage: FilterStorage,
		private requestToAgreeReportService: RequestToAgreeReportService,
		private notificationService: NotificationService
	)
	{
		super(appService, router, filterStorage, requestToAgreeReportService);
		this.appService.pageTitle = "Отчеты к оплате по заявкам на согласование";
	}

	loadData(): void {
		super.loadData();

		this.requestToAgreeReportService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.reports = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate([`/reports/requests-to-agree/`, dataItem.requestToAgreeReportId]);
	}

	getMonthName(monthNumber: number){

		if (!monthNumber)
			return "";

		var filtered = this.months.filter(x => x.id == monthNumber);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}
}