export class BaseObject{
    constructor() {
        this.entityStatus = 1;
        this.entityStatusString = "Активен";
    }

	createdDate: Date;
    createdUser: any;
    createdUserId: number;

    updatedDate: Date;
    updatedUser: any;
    updatedUserId: number;

    entityStatus: number;
    entityStatusString: string;
    canDelete: boolean;
}