import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { DirtyStateGuard } from '../core/guards/dirty-state.guard';

import { EmployeeListPage } from './employees/list/list.page';
import { EmployeePage } from './employees/employee/employee.page';
import { CustomerListPage } from './customers/list/list.page';
import { CustomerPage } from './customers/customer/customer.page';
import { MaintainObjectListPage } from './maintain-objects/list/list.page';
import { MaintainObjectPage } from './maintain-objects/maintain-object/maintain-object.page';
import { PositionListPage } from './positions/list/list.page';
import { PositionPage } from './positions/position/position.page';
import { OrgUnitListPage } from './org-units/list/list.page';
import { OrgUnitPage } from './org-units/org-unit/org-unit.page';
import { EmployeeCalendarPage } from './employees/employee-calendar/employee-calendar.page';
import { ServiceCenterListPage } from './service-centers/list/list.page';
import { ServiceCenterPage } from './service-centers/service-center/service-center.page';


export const catalogsRoutes: Routes = [

	{ path: 'employees', component: EmployeeListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-employees-read' } },
	{ path: 'employee/new', component: EmployeePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-employees-edit' } },
	{ path: 'employee/:employeeId', component: EmployeePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-employees-read' } },
	{ path: 'employee/:employeeId/calendar', component: EmployeeCalendarPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-employees-read' } },

	{ path: 'customers', component: CustomerListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-customers-read' } },
	{ path: 'customer/new', component: CustomerPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-customers-edit' } },
	{ path: 'customer/:customerId', component: CustomerPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-customers-read' } },

	{ path: 'maintain-objects', component: MaintainObjectListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-maintain-objects-read' } },
	{ path: 'maintain-object/new', component: MaintainObjectPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-maintain-objects-edit' } },
	{ path: 'maintain-object/:maintainObjectId', component: MaintainObjectPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-maintain-objects-read' } },

	{ path: 'positions', component: PositionListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-positions-read' } },
	{ path: 'position/new', component: PositionPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-positions-edit' } },
	{ path: 'position/:positionId', component: PositionPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-positions-read' } },

	{ path: 'org-units', component: OrgUnitListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-org-units-read' } },
	{ path: 'org-unit/new', component: OrgUnitPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-org-units-edit' } },
	{ path: 'org-unit/:orgUnitId', component: OrgUnitPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-org-units-read' } },

	{ path: 'service-centers', component: ServiceCenterListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-service-centers-read' } },
	{ path: 'service-center/new', component: ServiceCenterPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-service-centers-edit' } },
	{ path: 'service-center/:serviceCenterId', component: ServiceCenterPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-catalogs-service-centers-read' } },
];