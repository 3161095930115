import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePage } from './home.page';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    HttpModule,
		HttpClientModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [HomePage],
})
export class HomeModule { }
