import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {

	constructor(
	) { }

	transform(value: string): string {

		if (!value)
			return "";

		if (!value.startsWith("+"))
			return value;

		return `${value.substring(0, 2)} (${value.substring(2, 5)}) ${value.substring(5, 8)}-${value.substring(8, 10)}-${value.substring(10, 12)}`;
	}
}