import { ViewContainerRef, Injectable, Inject } from '@angular/core';
import { SweetAlertType } from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NotificationType } from './notification-type';
import { Router } from '@angular/router';

@Injectable()
export class NotificationService {

	static get NotificationType() {
		return NotificationType;
	}
	public static DEFAULT_ERROR_TITLE = 'notification.error.default.title';
	public static DEFAULT_ERROR_MESSAGE = 'notification.error.default.message';
	public static DEFAULT_SUCCESS_TIMER = 5000;
	public static DEFAULT_INFO_TITLE = 'notification.info.default.title';
	public static DEFAULT_INFO_MESSAGE = 'notification.info.default.message';

	constructor(
		@Inject('sweetAlert') private sweetAlert: Function,
		private toastr: ToastrService,
		private router: Router) { }


	error({
		message = NotificationService.DEFAULT_ERROR_MESSAGE,
		title = NotificationService.DEFAULT_ERROR_TITLE,
		notificationType = NotificationType.Toast,
		confirmButtonText = 'OK'
	} = {}, options = {}) {
		if (notificationType === NotificationType.Toast) {
			this.toastr.error(message, title, Object.assign(this.toastrOptions(), options));
		} else {
			this.showMessage(Object.assign({ title: title, text: message, showConfirmButton: true, type: "error", confirmButtonText: confirmButtonText }, options));
		}

	}

	httpError(status: number, data: any): boolean {
		// FIXME check other https status and make a generic message
		// not-found, unauthorized, forbidden, server error
		if ([500].indexOf(status) > -1) {
			this.error({ message: `notification.http_error.${status}.message` });
			return true; // return true to indicate that the error was already handled
		} else {
			return false;
		}

	}

	success({
		title = 'toast.title.success',
		message = '',
		notificationType = NotificationType.Toast,
		confirmButtonText = 'OK'
	}, options = {}) {
		if (notificationType === NotificationType.Toast) {
			options = Object.assign({ timeOut: NotificationService.DEFAULT_SUCCESS_TIMER });
			this.toastr.success(message, title, Object.assign(this.toastrOptions(), options)
			);
		} else {
			this.showMessage(Object.assign({ title: title, text: message, timer: NotificationService.DEFAULT_SUCCESS_TIMER, confirmButtonText: confirmButtonText }, options));
		}

	}

	confirmation({ title = '', message = '', showCancelButton = true, type = "warning", cancelButtonText = "", confirmButtonText = "", showCloseButton = true }, confirmationFunction: Function = null, dismissFunction: Function = null) {
		return this.showMessage({ title: title, text: message, showCancelButton: showCancelButton, type: type, closeOnConfirm: true, cancelButtonText: cancelButtonText, confirmButtonText: confirmButtonText, showCloseButton: showCloseButton, timer: null }, confirmationFunction, dismissFunction);
	}

	question({ title = '', message = '', showCancelButton = true}, confirmationFunction: Function = null, dismissFunction: Function = null) {
		return this.showMessage({
			title: title,
			text: message,
			showCancelButton: showCancelButton,
			type: "",
			closeOnConfirm: true,
			cancelButtonText: "Пропустить",
			confirmButtonText: "ОК",
			input: "textarea",
			inputPlaceholder: "Введите комментарий",
			showCloseButton: true,
			timer: null
		}, confirmationFunction, dismissFunction);
	}

	info({
		message = NotificationService.DEFAULT_INFO_MESSAGE,
		title = NotificationService.DEFAULT_INFO_TITLE,
		notificationType = NotificationType.Toast
	} = {}, options = {}, confirmationFunction: Function = null, dismissFunction: Function = null) {
		if (notificationType === NotificationType.Toast) {
			this.toastr.info(message, title, Object.assign(this.toastrOptions(), options));
		} else {
			this.showMessage(Object.assign({ title: title, text: message, showConfirmButton: true, type: "info", confirmButtonText: "OK" }, options), confirmationFunction, dismissFunction);
		}

	}

	private showMessage({ title = '', text = '', type = "success", timer = NotificationService.DEFAULT_SUCCESS_TIMER,
		showConfirmButton = true, showCancelButton = false, closeOnConfirm = true, cancelButtonText = "", confirmButtonText = "", showCloseButton = true, input = null, inputPlaceholder = null }, confirmationFunction: Function = null,
		dismissFunction: Function = null) {

		let promise = this.sweetAlert({
			title: title,
			html: text,
			type: <SweetAlertType>type,
			input: input,
			inputPlaceholder: inputPlaceholder,
			//timer: timer,
			showConfirmButton: showConfirmButton,
			showCancelButton: showCancelButton,
			cancelButtonText: cancelButtonText,
			confirmButtonText: confirmButtonText,
			showCloseButton: showCloseButton,
		});

		if (confirmationFunction) {
			promise = promise.then(
				(result) => {
					console.log(result);
					if (result.value || result.value === "") {
						return confirmationFunction(result.value);
					} else if (result.dismiss === 'cancel' && dismissFunction) {
						return dismissFunction(result.value);
					}
				}
			);
		}
		return promise;
	}

	private toastrOptions(options = {}) {
		return {
			allowHtml: true,
			closeButton: false,
			closeHtml: '<button>&times;</button>',
			extendedTimeOut: 1000,
			iconClasses: {
				error: 'toast-error',
				info: 'toast-info',
				success: 'toast-success',
				warning: 'toast-warning'
			},
			positionClass: "toast-bottom-right",
			messageClass: 'toast-message',
			// onHidden: null,
			// onShown: null,
			// onTap: null,
			progressBar: false,
			tapToDismiss: true,
			// templates: {
			// 	toast: 'directives/toast/toast.html',
			// 	progressbar: 'directives/progressbar/progressbar.html'
			// },
			timeOut: 3000,
			titleClass: 'toast-title',
			toastClass: 'toast'
		};
	}



}
