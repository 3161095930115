import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { ProdCalendarService } from '../prod-calendar.service';
import { ProdCalendar } from '../../../shared/models/ProdCalendar/ProdCalendar';
import { ProdCalendarDay } from '../../../shared/models/ProdCalendar/ProdCalendarDay';
import { createDate, isEqualDate, getDate } from '@progress/kendo-date-math';
import { IntlService, parseDate } from '@progress/kendo-angular-intl';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
	selector: 'settings-prod-calendar',
	templateUrl: './prod-calendar.page.html',
	styleUrls: ['./prod-calendar.page.scss']
})
export class ProdCalendarPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('prodCalendarForm') public prodCalendarForm: NgForm;

	hasChanges(): boolean{
		return !this.prodCalendarForm.pristine;
	};

	canEditForm: boolean;
	prodCalendarId: string;
	prodCalendar: ProdCalendar;

	public gridSort: SortDescriptor[] = [{ field: 'day', dir: 'asc' }];

	get calendarMinDate(): Date{
		return createDate(this.prodCalendar.year, 0, 1);
	}

	get calendarMaxDate(): Date{
		return createDate(this.prodCalendar.year, 11, 31);
	}

	dayTypes: any[] = [{ Id: 1, Name: 'Рабочий' }, { Id: 2, Name: 'Выходной' }, { Id: 3, Name: 'Праздничный' }, { Id: 4, Name: 'Предпраздничный' }];

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private intl: IntlService,
		private prodCalendarService: ProdCalendarService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новый календарь";
		}

	ngOnInit() {

		this.prodCalendarId = this.route.snapshot.paramMap.get('prodCalendarId');

		if (this.prodCalendarId != null){

			this.prodCalendarService.getById(+this.prodCalendarId).subscribe(r => {
				this.dataBind(r.data);
			});

		} else {

			this.prodCalendar = new ProdCalendar();
			this.prodCalendar.year = (new Date()).getFullYear() + 1;
			this.prodCalendar.createdDate = new Date();
			this.prodCalendar.entityStatus = 1;
			this.prodCalendar.entityStatusString = "Активен";

			this.dataBind(this.prodCalendar);

			this.canEditForm = true;
		}

	}

	dataBind(prodCalendar: ProdCalendar){

		prodCalendar.days.forEach(x => {
			x.day = this.appService.parseDate(new Date(x.day));
		});

		this.prodCalendar = prodCalendar;

		//this.gridData = process(this.user.roleCategories, { group: [{ field: 'groupName' }], sort: [{ field: "categoryId" }] });

		// if (this.prodCalendar.year && this.prodCalendar.year !== "")
		// 	this.appService.pageTitle = this.prodCalendar.year;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.prodCalendarService.upsert(this.prodCalendar).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.prodCalendarForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `Производственный календарь за ${this.prodCalendar.year} год`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `Производственный календарь за ${this.prodCalendar.year} год`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `Производственный календарь за ${this.prodCalendar.year} год`,
			message: "Вы уверены что хотите удалить календарь?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.prodCalendarService.remove(this.prodCalendar.prodCalendarId).subscribe(r => {

				this.router.navigate(['/settings/ProdCalendars']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Производственный календарь за ${this.prodCalendar.year} год успешно удален`,
						//message: "Пользователь успешно удален",
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: "Ошибка при удалении календаря",
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.prodCalendar.year} год`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.prodCalendarService.archive(this.prodCalendar.prodCalendarId).subscribe(r => {

				this.router.navigate(['/settings/ProdCalendars']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.prodCalendar.year} год' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	getDayTypeString(dayType: number){
		var filtered = this.dayTypes.filter(x => x.Id == dayType);

		if (filtered.length === 0)
			return "";

		return filtered[0].Name;
	}

	get createNewDay(): any {

		var defaultDay = this.calendarMinDate;

		return function() {
			var day = new ProdCalendarDay();
			day.day = defaultDay;
			day.dayType = 3;
			return day;
		};


	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}
	
	public cellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 1)
			return;

        sender.editRow(rowIndex);
        
	}
	
	public saveHandler(obj: any) {
		var addingDay = obj.dataItem.day;
		
		var filtered = this.prodCalendar.days.filter(x => isEqualDate(x.day, addingDay));

		obj.dataItem.day = this.appService.parseDate(obj.dataItem.day);

		if (filtered.length > 0){

			this.notificationService.error({
				title: "Ошибка при добавлении даты",
				message: `День ${this.intl.formatDate(obj.dataItem.day, "dd.MM.yyyy")} уже есть. Отредактируйте табличную часть. Данная строка не будет сохранена.`,
				notificationType: NotificationType.SweetAlert
			});

			obj.dataItem = null;

		}
			

		this.appService.MarkFormDirty(this.prodCalendarForm);
		
	}

	public removeHandler(obj: any) {
        this.appService.MarkFormDirty(this.prodCalendarForm);
    }

}