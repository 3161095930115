import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { DistanceExtraPaysService } from '../distance-extra-pays.service';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, groupBy, DataSourceRequestState } from '@progress/kendo-data-query';
import { DistanceExtraPay } from '../../../shared/models/DistanceExtraPays/DistanceExtraPay';
import { DistanceExtraPayValue } from '../../../shared/models/DistanceExtraPays/DistanceExtraPayValue';
import { ServiceCenter } from '../../../shared/models/ServiceCenter/ServiceCenter';
import { ServiceCenterService } from '../../../shared/services/service-center.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
	selector: 'settings-distance-extra-pay',
	templateUrl: './distance-extra-pay.page.html',
	styleUrls: ['./distance-extra-pay.page.scss']
})
export class DistanceExtraPayPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('distanceExtraPayForm') public distanceExtraPayForm: NgForm;

	hasChanges(): boolean{
		return !this.distanceExtraPayForm.pristine;
	};

	public distanceExtraPayValuesSort: SortDescriptor[] = [
		{ field: 'roadType', dir: 'asc' },
		{ field: 'kmFrom', dir: 'asc' },
		{ field: 'kmTo', dir: 'asc' }
	];

	serviceCenters: ServiceCenter[] = [];

	canEditForm: boolean;
	distanceExtraPayId: string;
	distanceExtraPay: DistanceExtraPay;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private distanceExtraPaysService: DistanceExtraPaysService,
		private notificationService: NotificationService,
		private serviceCenterService: ServiceCenterService) {
			this.appService.pageTitle = "Новая доплата";
		}

	ngOnInit() {

		this.loadDropDownsData();

		this.distanceExtraPayId = this.route.snapshot.paramMap.get('distanceExtraPayId');

		if (this.distanceExtraPayId != null){

			this.distanceExtraPaysService.getById(+this.distanceExtraPayId).subscribe(r => {
				this.dataBind(r.data);
			});

		} else {
			this.distanceExtraPay = new DistanceExtraPay();
			this.distanceExtraPay.createdDate = new Date();
			this.canEditForm = true;
		}

	}

	dataBind(timeSchedule: DistanceExtraPay){
		this.distanceExtraPay = timeSchedule;
		this.distanceExtraPay.values.forEach(v => {
			v.dateStart = this.appService.parseDate(v.dateStart);
			v.dateEnd = this.appService.parseDate(v.dateEnd);
		})
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		// const result = this.distanceExtraPay.values.reduce((ubc, u) => ({
		// 	...ubc,
		// 	[u.roadType]: [ ...(ubc[u.roadType, u.kmFrom, u.kmTo] || []), u ],
		//   }), {});

		var hasDuplicates: Boolean;

		this.distanceExtraPay.values.forEach(v => {
			hasDuplicates = this.distanceExtraPay.values.filter(x =>	x.roadType == v.roadType &&
																		x.kmFrom == v.kmFrom &&
																		x.kmTo == v.kmTo).length > 1;
		});

		if (hasDuplicates){

			this.notificationService.error({
				message: "В таблице заданы пересекающиеся условия. Проверьте таблицу, внесите коррективы и попробуйте сохранить заново.",
				title: `Доплата за удаленность`,
				notificationType: NotificationType.SweetAlert
			});

			return;
		}

		this.distanceExtraPaysService.upsert(this.distanceExtraPay).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.canEditForm = false;
				this.appService.MarkFormPristine(this.distanceExtraPayForm);
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `Доплата за удаленность`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `Доплата за удаленность`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `Доплата за удаленность`,
			message: "Вы уверены что хотите удалить доплату за удаленность?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.distanceExtraPaysService.remove(this.distanceExtraPay.distanceExtraPayId).subscribe(r => {

				this.router.navigate(['/settings/distance-extra-pays']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Доплата за удаленность успешно удалена`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: "Ошибка при удалении доплаты за удаленность",
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `Доплата за удаленность`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.distanceExtraPaysService.archive(this.distanceExtraPay.distanceExtraPayId).subscribe(r => {

				this.router.navigate(['/settings/distance-extra-pays']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}

	get createNew(): any {
		return function() {
			var depv = new DistanceExtraPayValue();
			depv.roadType = 1;
			depv.kmFrom = 0;
			depv.kmTo = 0;
			depv.ratio = 0;
			return depv;
		};
	}

	public cellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 2)
			return;

        sender.editRow(rowIndex);
	}
	
	public saveHandler(obj: any) {
		this.appService.MarkFormDirty(this.distanceExtraPayForm);
	}

	public removeHandler() {
        this.appService.MarkFormDirty(this.distanceExtraPayForm);
    }

	loadDropDownsData() {
		var serviceCenterState: DataSourceRequestState = { skip: 0, take: 10000,
			sort: [{ field: "serviceCenterName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		};
		
		this.serviceCenterService.list(serviceCenterState).subscribe(x => this.serviceCenters = x.data);
	}

	public dropDownFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

	getServiceCenterName(serviceCenterId: number){
		var serviceCenter = this.serviceCenters.find(s => s.serviceCenterId == serviceCenterId);
		return serviceCenter.serviceCenterName;
	}
}