import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UploadModule } from '@progress/kendo-angular-upload';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UploadInterceptor } from './documents.interceptor';
import { LaddaModule } from 'angular2-ladda';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RequestListPage } from './requests/list/list.page';
import { RequestPage } from './requests/request/request.page';
import { RequestFilesListPage } from './request-files/list/list.page';
import { RequestFilePage } from './request-files/request-file/request-file.page';
import { UploadFilesPage } from './request-files/upload-files/upload-files.page';
import { ManualActionListPage } from './manual-actions/list/list.page';
import { ManualActionPage } from './manual-actions/manual-action/manual-action.page';
import { TariffListPage } from './tariffs/list/list.page';
import { TariffPage } from './tariffs/tariff/tariff.page';
import { RequestToAgreeTariffListPage } from './request-to-agree-tariff/list/list.page';
import { RequestToAgreeTariffPage } from './request-to-agree-tariff/request-to-agree-tariff/request-to-agree-tariff.page';
import { RequestToAgreeListPage } from './requests-to-agree/list/list.page';
import { RequestToAgreePage } from './requests-to-agree/request-to-agree/request-to-agree.page';
import { IntlModule } from '@progress/kendo-angular-intl';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { AutosizeModule } from 'ngx-autosize';


@NgModule({
	declarations: [

		RequestListPage,
		RequestPage,

		RequestFilesListPage,
		RequestFilePage,
		UploadFilesPage,

		ManualActionListPage,
		ManualActionPage,

		TariffListPage,
		TariffPage,

		RequestToAgreeTariffListPage,
		RequestToAgreeTariffPage,

		RequestToAgreeListPage,
		RequestToAgreePage

	],
	imports: [
		CommonModule,
		CommonModule,
		SharedModule,
		FormsModule,
		RouterModule,
		UploadModule,
		LaddaModule,
		PerfectScrollbarModule,
		AutosizeModule,
		IntlModule,
		NgbModule,
		ExcelModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UploadInterceptor,
			multi: true
		}
	]
})
export class DocumentsModule { }