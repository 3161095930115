import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OrgStructureEmployee } from '../../models/Reports/OrgStructure/OrgStructureEmployee';
import { OrgStructureReportUnit } from '../../models/Reports/OrgStructure/OrgStructureReportUnit';

@Component({
  selector: 'org-structure-report-unit-node',
  templateUrl: './org-structure-report-unit-node.html',
  styleUrls: ['./org-structure-report-unit-node.css']
})

export class OrgStructureReportUnitNodeComponent {
  @Input() node: OrgStructureReportUnit;
  @Input() currentOrgUnitId: number;
  @Output() employeeIdChoosen: EventEmitter<number> = new EventEmitter();
  @Output() orgUnitIdChoosen: EventEmitter<number> = new EventEmitter();

  public getEmployeeDisplayName(employee: OrgStructureEmployee): string {
    return `${employee.fullName}, ${employee.positionName}`;
  }

  public getDisplayChiefs(orgUnit: OrgStructureReportUnit): string {
    return orgUnit.chief.map(m => this.getEmployeeDisplayName(m)).join('; ');
  }

  public onEmployeeClick(employeeId: number): void {
    this.employeeIdChoosen.emit(employeeId);
  }
  public onFilterByOrgUnit(orgUnitId: number): void {
    if (orgUnitId == this.currentOrgUnitId) {
      orgUnitId = null;
    }
    this.orgUnitIdChoosen.emit(orgUnitId);
  }
}
