import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { TariffsService } from '../../../shared/services/tariffs.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { Tariff } from '../../../shared/models/Tariff/Tariff';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'documents-tariffs',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class TariffListPage extends ListPageBase {

	public tariffs: ActionDataResult<Tariff[]>;

	tariffTypes: KeyValueObject[] = [{ id: 1, name: 'Переработка в рабочие дни', entityStatusString: "" }];

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private tariffsService: TariffsService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, tariffsService);

		this.appService.pageTitle = "Тарифы";
	}

	loadData(): void {
		super.loadData();

		this.tariffsService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.tariffs = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/documents/tariff', dataItem.tariffId]);
	}
}