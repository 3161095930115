import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { FactorService } from '../factor.service';
import { IntlService, parseDate } from '@progress/kendo-angular-intl';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { FactorSet } from '../../../shared/models/Factor/FactorSet';
import { FactorSetValue } from '../../../shared/models/Factor/FactorSetValue';
import { IdleFactor } from '../../../shared/models/Factor/IdleFactor';
import { OnTimeRequestFactor } from '../../../shared/models/Factor/OnTimeRequestFactor';
import { OutOfTimeRequestFactor } from '../../../shared/models/Factor/OutOfTimeRequestFactor';

@Component({
	selector: 'settings-factor-set',
	templateUrl: './factor-set.page.html',
	styleUrls: ['./factor-set.page.scss']
})
export class FactorSetPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('factorsForm') public factorsForm: NgForm;

	hasChanges(): boolean{
		return !this.factorsForm.pristine;
	};

	public factorSetValuesSort: SortDescriptor[] = [{ field: 'factorName', dir: 'asc' }];

	canEditForm: boolean;
	factorSetId: string;
	factorSet: FactorSet;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private intl: IntlService,
		private factorService: FactorService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новые коэффициенты";
		}

	ngOnInit() {

		this.factorSetId = this.route.snapshot.paramMap.get('factorsSetId');

		if (this.factorSetId != null){

			this.factorService.getById(+this.factorSetId).subscribe(r => {
				this.dataBind(r.data);
			});

		} else {

			this.factorService.getNew().subscribe(r => {
				this.dataBind(r.data);
				this.canEditForm = true;
			});

		}

	}

	dataBind(timeSchedule: FactorSet){
		// timeSchedule.startFrom = this.appService.parseDate(new Date(timeSchedule.startFrom));
		this.factorSet = timeSchedule;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.factorService.upsert(this.factorSet).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.factorsForm);
				this.factorsForm.form.markAsPristine();
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `Коэффициенты`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `Коэффициенты`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `Коэффициенты`,
			message: "Вы уверены что хотите удалить коэффициенты?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.factorService.remove(this.factorSet.factorSetId).subscribe(r => {

				this.router.navigate(['/settings/factors']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Коэффициенты успешно удалены`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: "Ошибка при удалении коэффициентов",
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `Коэффициенты`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.factorService.archive(this.factorSet.factorSetId).subscribe(r => {

				this.router.navigate(['/settings/factors']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}

	get createNew(): any {
		return function() {
			var fsv = new FactorSetValue();
			return fsv;
		};
	}

	get createNewIdleFactor(): any {
		return function() {
			var idleFactor = new IdleFactor();
			idleFactor.idlePercent = 0;
			idleFactor.idleAmountPercent = 0;
			return idleFactor;
		};
	}

	get createNewOnTimeRequestFactor(): any {
		return function() {
			var onTimeRequestFactor = new OnTimeRequestFactor();
			onTimeRequestFactor.onTimePercent = 0;
			onTimeRequestFactor.onTimeAmountPercent = 0;
			return onTimeRequestFactor;
		};
	}

	get createNewOutOfTimeRequestFactor(): any {
		return function() {
			var outOfTimeRequestFactor = new OutOfTimeRequestFactor();
			outOfTimeRequestFactor.outOfTimePercent = 0;
			outOfTimeRequestFactor.outOfTimeAmountPercent = 0;
			return outOfTimeRequestFactor;
		};
	}
	
	public cellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 2)
			return;

        sender.editRow(rowIndex);
	}
	
	public saveHandler(obj: any) {
		this.appService.MarkFormDirty(this.factorsForm);
	}

	public removeHandler() {
        this.appService.MarkFormDirty(this.factorsForm);
    }

}