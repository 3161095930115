import { BaseObject } from "../core/BaseObject";
import { EmployeeCustomTimeSchedule } from "./EmployeeCustomTimeSchedule";
import { EmployeeSalary } from "./employee-salary";
import { EmployeeTimeSchedule } from "./employee-time-schedule";
import { EmployeeLogin } from './employee-login';

export class Employee extends BaseObject {
    employeeId: number;
    employeeCategoryId: number;
    movementTypeId: number;
    timeScheduleId: number;
    positionId: number;
    orgUnitId: number;

    surname: string;
    name: string;
    middleName: string;
    email: string;

    startWorkDate: Date;

    externalCode: string;
    includeToSalaryCalcReport: boolean;

    categoryName: string;
    movementTypeName: string;
    timeScheduleName: string;
    positionName: string;
    orgUnitName: string;

    entityStatusDate: Date;

    employeeCustomTimeSchedules: EmployeeCustomTimeSchedule[] = [];
    employeeSalaries: EmployeeSalary[] = [];
    employeeTimeSchedules: EmployeeTimeSchedule[] = [];
    employeeLogins: EmployeeLogin[] = [];

    get displayName(): string {

        var result = "";

        if (this.surname) {
            result += this.surname;
        }

        if (this.name) {
            if (result) {
                result += ` ${this.name.substring(0, 1)}.`;
            }
            else {
                result += `${this.name}`;
            }
        }

        if (this.middleName) {
            result += ` ${this.middleName.substring(0, 1)}.`;
        }

        return result;
    }
}