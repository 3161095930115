import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { NotificationService } from '../../core/services/notification.service';
import { RequestToAgreeReport } from '../models/Reports/RequestToAgree/request-to-agree-report';
import { EmployeePeriod } from '../models/Reports/employee-period';

@Injectable()
export class RequestToAgreeReportService extends DataService<RequestToAgreeReport> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "request-to-agree-report";
	}

	public generate(requestToAgreeReport: RequestToAgreeReport): Observable<ActionDataResult<number>> {
		return this.http.post<ActionDataResult<number>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/generate`, requestToAgreeReport);
	}

	public getUntrackedRequests(year: number, month: number, orgUnitId: number): Observable<ActionDataResult<EmployeePeriod[]>> {
		return this.http.get<ActionDataResult<EmployeePeriod[]>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/untracked-requests?year=${year}&month=${month}&orgUnitId=${orgUnitId}`);
	}
}