import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { AnalyticReport } from '../models/Reports/Analytic/AnalyticReport';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class AnalyticReportService extends DataService<AnalyticReport> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "AnalyticReport";
	}

	setKind(isForWeekends: boolean) {
		this.controllerName = isForWeekends ? "analytic-report/weekends" : "analytic-report/workdays";
	}

	public generate(analyticReport: AnalyticReport): Observable<ActionDataResult<AnalyticReport>> {
		return this.http.post<ActionDataResult<AnalyticReport>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/generate`, analyticReport);
	}
}