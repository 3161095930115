import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { MaintainObject } from '../../../shared/models/MaintainObject/MaintainObject';
import { MaintainObjectService } from '../../../shared/services/maintain-object.service';
import { Observable } from 'rxjs';
import { SuggestionAddress } from '../../../shared/models/MaintainObject/SuggestionAddress';

 import 'rxjs/add/observable/of';
 import 'rxjs/add/operator/catch';
 import 'rxjs/add/operator/debounceTime';
 import 'rxjs/add/operator/distinctUntilChanged';
 import 'rxjs/add/operator/switchMap';
import { Classifier } from '../../../shared/models/Classifier';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { ClassifiersService } from '../../../shared/services/classifiers.service';
import { GeoService } from '../../../shared/services/geo.service';

@Component({
	selector: 'catalogs-maintain-object',
	templateUrl: './maintain-object.page.html',
	styleUrls: ['./maintain-object.page.scss']
})
export class MaintainObjectPage implements OnInit, TrackChangesComponent {

	@ViewChild('maintainObjectForm') public maintainObjectForm: NgForm;

	hasChanges(): boolean {
		return !this.maintainObjectForm.pristine;
	};

	canEditForm: boolean;
	maintainObjectId: string;
	maintainObject: MaintainObject;
	ngbTypeaheadAddress: any;

	classifiers: Classifier[] = [];
	roadTypes: any[] = [{ id: 1, name: 'МКАД' }, { id: 2, name: 'КАД' }];

	formatter = (item: SuggestionAddress) => item.address;

	search = (text$: Observable<string>) =>
		text$
			.debounceTime(600)
			.distinctUntilChanged()
			.switchMap(term =>
				this.geoService.suggest(term, 3).map(response => {
					if (response.isSuccessful) {
						return response.data;
					} else {
						this.notificationService.error({message:'Ошибка при обращении в сервис George, см. подробности в логе.', title: 'Ошибка George', notificationType: NotificationType.SweetAlert});
					}
				})
					.catch(() => {
						return Observable.of([]);
					}));

	onSelectAddress(item) {

		this.geoService.suggest(item.item.address, 1).subscribe(x => {

			if (x.data.length == 0)
				return;

			var info = x.data[0].info;

			this.maintainObject.postCode = info.postCode;
			this.maintainObject.district = info.district;
			this.maintainObject.city = info.city;
			this.maintainObject.street = info.street;
			this.maintainObject.house = info.house;
			this.maintainObject.block = info.block;
			// this.maintainObject.nearestMetroStation = info.nearestMetroStation;
			
			if (info.beltwayHit==='OUT_MKAD'){
				this.maintainObject.roadType = 1;
			} else if (info.beltwayHit==='OUT_KAD'){
				this.maintainObject.roadType = 2;
			}

			this.maintainObject.beltwayDistance = info.beltwayDistance;

			this.maintainObject.latitude = info.latitude;
			this.maintainObject.longitude = info.longitude;
		});

	}

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private maintainObjectService: MaintainObjectService,
		private notificationService: NotificationService,
		private classifierService: ClassifiersService,
		private geoService: GeoService) {
			this.appService.pageTitle = "Новый объект обслуживания";
	}

	ngOnInit() {

		this.loadDropDownsData();

		this.maintainObjectId = this.route.snapshot.paramMap.get('maintainObjectId');

		if (this.maintainObjectId != null) {

			this.maintainObjectService.getById(+this.maintainObjectId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = `Объект обслуживания ${r.data.objectCode}`;
			});

		} else {

			this.maintainObject = new MaintainObject();
			this.maintainObject.classifierId = 7;
			this.maintainObject.createdDate = new Date();
			this.maintainObject.entityStatus = 1;
			this.maintainObject.entityStatusString = "Активен";

			this.dataBind(this.maintainObject);
			this.canEditForm = true;
		}

	}

	dataBind(maintainObject: MaintainObject) {
		this.maintainObject = maintainObject;
		this.ngbTypeaheadAddress = { address: this.maintainObject.address };
	}

	getCoordinates(): string{

        if (!this.maintainObject.latitude || !this.maintainObject.longitude)
            return "";

        return `${this.maintainObject.latitude}, ${this.maintainObject.longitude}`;
	}
	
	get beltwayDistanceString(): string{

		var result = "";

		if (this.maintainObject.beltwayDistance){
			result = `${this.maintainObject.beltwayDistance} км`;
		}

		if (this.maintainObject.roadType){
			result += ` от ${(this.maintainObject.roadType === 1 ? "МКАД" : "КАД" )}`;
		}

		return result;
	}

	toggleFormEdit(): void {
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.maintainObject.address = this.ngbTypeaheadAddress.address;

		this.maintainObjectService.upsert(this.maintainObject).subscribe(r => {

			if (r.isSuccessful) {
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.maintainObjectForm);
				this.canEditForm = false;
				this.notificationService.success({ message: "Изменения сохранены успешно!", title: `${this.maintainObject.objectCode}`, notificationType: NotificationType.Toast });
			} else {
				this.notificationService.error({ message: r.errorDescription, title: `${this.maintainObject.objectCode}`, notificationType: NotificationType.Toast });
			}

		});
	}

	askRemove() {
		this.notificationService.confirmation({
			title: `${this.maintainObject.objectCode}`,
			message: `Вы уверены что хотите удалить объект обслуживания '${this.maintainObject.objectCode}'?`,
			type: "question",
			confirmButtonText: "Да",
			cancelButtonText: "Нет",
			showCloseButton: true
		}, () => {

			this.maintainObjectService.remove(this.maintainObject.maintainObjectId).subscribe(r => {

				this.router.navigate(['/catalogs/maintain-objects']);

				if (r.isSuccessful) {
					this.notificationService.success({
						title: `Объект обслуживания '${this.maintainObject.objectCode}' успешно удален`,
						notificationType: NotificationType.SweetAlert
					});

				} else {
					this.notificationService.error({
						title: `Ошибка при удалении объекта обслуживания`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.maintainObject.objectCode}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.maintainObjectService.archive(this.maintainObject.maintainObjectId).subscribe(r => {

				this.router.navigate(['/catalogs/maintain-objects']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.maintainObject.objectCode}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	loadDropDownsData() {

		var state: DataSourceRequestState = {
			skip: 0,
			take: 10000,
			sort: [{ field: "name", dir: "asc" }],
		};

		this.classifierService.list(state).subscribe(x => this.classifiers = x.data);
		
	}

	public beltwayDistanceChange(value: number) {

		if (value == null){
			this.maintainObject.beltwayDistance = null;
			this.maintainObject.roadType = null;
		}
        
	}
}