import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../../shared/models/core/ActionDataResult';
import { MailingGroup } from '../../../../shared/models/mailings/mailing-group';
import { AppService } from '../../../../app.service';
import { FilterStorage } from '../../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../../shared/components/list-page-base/list-page-base';
import { MailingGroupsService } from '../../../../shared/services/mailing-groups.service';

@Component({
	selector: 'settings-mailing-groups',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class MailingGroupsListPage extends ListPageBase {

	public mailingGroups: ActionDataResult<MailingGroup[]>;

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private mailingGroupsService: MailingGroupsService,
		) {
		super(appService, router, filterStorage, mailingGroupsService);
		this.appService.pageTitle = "Группы рассылок";
	}

	loadData(): void {
		super.loadData();

		this.mailingGroupsService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.mailingGroups = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/settings/mailing-group', dataItem.mailingGroupId]);
	}
}