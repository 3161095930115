import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { RequestToAgreeService } from '../../../shared/services/request-to-agree.service';
import { RequestToAgree } from '../../../shared/models/requests-to-agree/requests-to-agree';
import { EmployeeService } from '../../../shared/services/employee.service';
import { RequestToAgreeTariffService } from '../../../shared/services/request-to-agree-tariff.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';

@Component({
	selector: 'documents-requests-to-agree',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class RequestToAgreeListPage extends ListPageBase {

	requests: ActionDataResult<RequestToAgree[]>;
	actionTypeId: string;

	statuses: KeyValueObject[] = [
		{ id: 1, name: 'Создана', entityStatusString: "" },
		{ id: 2, name: 'На согласовании', entityStatusString: "" },
		{ id: 3, name: 'В работе', entityStatusString: "" },
		{ id: 4, name: 'Выполнена', entityStatusString: "" },
		{ id: 5, name: 'Отклонена', entityStatusString: "" },
		{ id: 6, name: 'Не готова', entityStatusString: "" },
		{ id: 7, name: 'Ознакомление', entityStatusString: "" },
		{ id: 8, name: 'Оплачена', entityStatusString: "" },
		{ id: 9, name: 'Подтверждена', entityStatusString: "" },
	];

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private route: ActivatedRoute,
		private requestToAgreeService: RequestToAgreeService,
		public employeeService: EmployeeService,
		public requestToAgreeTariffService: RequestToAgreeTariffService
		) {
		super(appService, router, filterStorage, requestToAgreeService);
		this.appService.pageTitle = "Заявки на согласование";
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}



	loadData(): void {
		super.loadData();

		this.actionTypeId = this.route.snapshot.paramMap.get('actionTypeId');

		this.requestToAgreeService.requestsList(this.state, this.actionTypeId).subscribe(data => {
			this.loading = false;
			return this.requests = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/documents/request-to-agree', dataItem.requestToAgreeId]);
	}
}