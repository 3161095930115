import { Injectable } from '@angular/core';
import { CanDeactivate } from "@angular/router";
import { TrackChangesComponent } from '../interfaces/component-can-deactivate';
import { NotificationService } from '../services/notification.service';

@Injectable({
    providedIn: 'root'
})
export class DirtyStateGuard implements CanDeactivate<TrackChangesComponent>{

    constructor(
        private notificationService: NotificationService) { }

    canDeactivate(component: TrackChangesComponent): boolean {

        if (!component.hasChanges())
            return true;

        return this.notificationService.confirmation({
            //title: "contextbar.edition.leave.confirmation.title",
            message: "Данные не были сохранены и будут потеряны, хотите продолжить?",
            cancelButtonText: "Нет, остаться на странице",
            confirmButtonText: "Да, не сохранять изменения!",
            showCloseButton: true
        }, () => true, () => false);

    }
}