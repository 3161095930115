import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SecurityService } from '../../core/services/security.service';

@Directive({
	selector: '[hasClaim]'
})
export class HasClaimDirective {
	@Input() set hasClaim(claimKey: any) {
		if (this.securityService.hasClaim(claimKey)) {
			// Add template to DOM
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			// Remove template from DOM
			this.viewContainer.clear();
		}
	}

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private securityService: SecurityService) { }

}