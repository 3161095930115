import { BaseObject } from "../../core/BaseObject";
import { SalaryCalcReportRow } from "./SalaryCalcReportRow";
import { EmployeePeriod } from '../employee-period';

export class SalaryCalcReport extends BaseObject {
    salaryCalcReportId: number;
    month: number;
    year: number;
    name: string;
    hasPaid: number;
    isForWeekends: boolean;
    version: number;
    rows: SalaryCalcReportRow[] = [];
    untrackedEmployeePeriods: EmployeePeriod[] = [];
}