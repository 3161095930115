import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { RequestToAgreeTariffService } from '../../../shared/services/request-to-agree-tariff.service';
import { RequestToAgreeTariff } from '../../../shared/models/request-to-agree-tariffs/request-to-agree-tariff';

@Component({
	selector: 'documents-tariffs',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class RequestToAgreeTariffListPage extends ListPageBase {

	public tariffs: ActionDataResult<RequestToAgreeTariff[]>;

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private requestToAgreeTariffService: RequestToAgreeTariffService) {

		super(appService, router, filterStorage, requestToAgreeTariffService);

		this.appService.pageTitle = "Тарифы для заявок на согласование";
	}

	loadData(): void {
		super.loadData();

		this.requestToAgreeTariffService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.tariffs = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/documents/request-to-agree-tariff', dataItem.requestToAgreeTariffId]);
	}
}