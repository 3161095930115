import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { TimeScheduleService } from '../../../shared/services/time-schedule.service';
import { NotificationService } from '../../../core/services/notification.service';
import { TimeSchedule } from '../../../shared/models/TimeSchedule/TimeSchedule';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'settings-timeSchedule',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class TimeScheduleListPage extends ListPageBase {

	public timeSchedules: ActionDataResult<TimeSchedule[]>;

	constructor(
		router: Router,
		appService: AppService,
		filterStorage: FilterStorage,
		private timeScheduleService: TimeScheduleService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, timeScheduleService);

		this.appService.pageTitle = "Графики работы";
	}

	ngOnInit(): void {
		this.loadData();
	}

	loadData(): void {
		super.loadData();

		this.timeScheduleService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.timeSchedules = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/settings/timeSchedule', dataItem.timeScheduleId]);
	}
}