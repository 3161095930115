import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { Tariff } from '../models/Tariff/Tariff';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class TariffsService extends DataService<Tariff> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "tariffs";
	}

}