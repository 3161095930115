import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { DataSourceRequestState, aggregateBy, DataResult, process } from '@progress/kendo-data-query';
import { DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage';
import { RequestToAgreeReportService } from '../../../shared/services/request-to-agree-report.service';
import { RequestToAgreeReport } from '../../../shared/models/Reports/RequestToAgree/request-to-agree-report';
import { OrgUnit } from '../../../shared/models/org-unit/org-unit';
import { OrgUnitService } from '../../../shared/services/org-unit.service';
import { EmployeeService } from '../../../shared/services/employee.service';
import { PositionService } from '../../../shared/services/position.service';
import { RequestToAgreeTariff } from '../../../shared/models/request-to-agree-tariffs/request-to-agree-tariff';
import { RequestToAgreeTariffService } from '../../../shared/services/request-to-agree-tariff.service';
import { EmployeePeriod } from '../../../shared/models/Reports/employee-period';
import { EmployeePeriodsComponent } from '../../../shared/components/employee-periods/employee-periods.component';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
	//encapsulation: ViewEncapsulation.None,
	selector: 'request-to-agree-report',
	templateUrl: './request-to-agree-report.page.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./request-to-agree-report.page.scss'
	]
})
export class RequestToAgreeReportPage implements OnInit, TrackChangesComponent {

	@ViewChild('requestToAgreeReportForm') public requestToAgreeReportForm: NgForm;

	hasChanges(): boolean {
		return !this.requestToAgreeReportForm.pristine;
	};

	canEditForm: boolean;
	requestToAgreeReportId: string;
	requestToAgreeReport: RequestToAgreeReport;
	isNew: boolean;
	reportGenerating: boolean;
	gridData: DataResult;
	total: any;

	get gridHeight(): number {
		return window.innerHeight - 240;
	}

	months = [{ id: 1, name: 'Январь' }, { id: 2, name: 'Февраль' }, { id: 3, name: 'Март' }, { id: 4, name: 'Апрель' }, { id: 5, name: 'Май' }, { id: 6, name: 'Июнь' }, { id: 7, name: 'Июль' }, { id: 8, name: 'Август' }, { id: 9, name: 'Сентябрь' }, { id: 10, name: 'Октябрь' }, { id: 11, name: 'Ноябрь' }, { id: 12, name: 'Декабрь' }];

	public aggregates: any[] = [{ field: 'requestToAgreeTotalAmount', aggregate: 'sum' }, { field: 'finesAndBonuses', aggregate: 'sum' }, { field: 'totalAmount', aggregate: 'sum' }];

	public state: DataSourceRequestState = {
		skip: 0,
		take: 50,
		sort: [{ field: "performerDisplayName", dir: "asc" }],
		filter: {
			logic: "and",
			filters: []
		},
		group: [
			{ field: 'performerOrgUnitName', aggregates: this.aggregates },
			{ field: 'performerDisplayName', aggregates: this.aggregates }
		]

	};

	orgUnits: OrgUnit[] = [];

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private requestToAgreeReportService: RequestToAgreeReportService,
		private notificationService: NotificationService,
		filterStorage: FilterStorage,
		protected dialogService: DialogService,
		public orgUnitService: OrgUnitService,
		public employeeService: EmployeeService,
		public positionService: PositionService,
		public requestToAgreeTariffService: RequestToAgreeTariffService
	) {
		// если мы пришли по кнопке Back, следует восстановить фильтр из SessionStorage
		if (appService.IsBackNavButtonPressed) {
			this.state = filterStorage.Get(this.state);
		}

		this.appService.pageTitle = "Отчет к оплате по заявкам на согласование";
	}

	ngOnInit() {

		this.orgUnitService.activeList("orgUnitName")
			.subscribe(data => this.orgUnits = data.data);

		this.requestToAgreeReportId = this.route.snapshot.paramMap.get('requestToAgreeReportId');

		if (this.requestToAgreeReportId != null) {

			this.requestToAgreeReportService.getById(+this.requestToAgreeReportId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = this.excelFileName;
			});

		} else {

			this.requestToAgreeReport = new RequestToAgreeReport();
			this.requestToAgreeReport.createdDate = new Date();
			this.requestToAgreeReport.month = this.requestToAgreeReport.createdDate.getMonth();
			this.requestToAgreeReport.year = this.requestToAgreeReport.createdDate.getFullYear();
			this.requestToAgreeReport.entityStatus = 1;
			this.requestToAgreeReport.entityStatusString = "Активен";

			this.dataBind(this.requestToAgreeReport);
			this.canEditForm = true;
			this.isNew = true;
		}

	}

	getMonthName(monthNumber: number) {

		if (!monthNumber)
			return "";

		var filtered = this.months.filter(x => x.id == monthNumber);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}

	get excelFileName() {

		if (!this.requestToAgreeReport || !this.requestToAgreeReport.month)
			return "Отчет к оплате по заявкам на согласование";

		return `Отчет к оплате по заявкам на согласование за ${this.getMonthName(this.requestToAgreeReport.month)} ${this.requestToAgreeReport.year}`;
	}

	dataBind(requestToAgreeReport: RequestToAgreeReport) {
		this.requestToAgreeReport = requestToAgreeReport;

		// this.requestToAgreeReport.rows.forEach(x => {

		// 	if (x.requestToAgreeFirstApproveDate)
		// 		x.requestToAgreeFirstApproveDate = this.appService.parseDateTime(x.requestToAgreeFirstApproveDate);

		// 	if (x.plannedStartDate)
		// 		x.plannedStartDate = this.appService.parseDateTime(x.plannedStartDate);

		// 	if (x.plannedEndDate)
		// 		x.plannedEndDate = this.appService.parseDateTime(x.plannedEndDate);

		// 	if (x.factStartDate)
		// 		x.factStartDate = this.appService.parseDateTime(x.factStartDate);

		// 	if (x.factEndDate)
		// 		x.factEndDate = this.appService.parseDateTime(x.factEndDate);

		// 	console.log(x.factEndDate);

		// });

		this.gridData = process(this.requestToAgreeReport.rows, this.state);
		this.total = aggregateBy(this.requestToAgreeReport.rows, this.aggregates);
	}

	public dataStateChange(state: DataStateChangeEvent): void {
		this.state = state;

		if (state && state.group) {
			state.group.map(group => group.aggregates = this.aggregates);
		}

		this.gridData = process(this.requestToAgreeReport.rows, this.state);

		// сохранение фильтра в SessionStorage
		//this.filterStorage.Save(this.state);
	}

	toggleFormEdit(): void {
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.requestToAgreeReportService.upsert(this.requestToAgreeReport).subscribe(r => {

			if (r.isSuccessful) {
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.requestToAgreeReportForm);
				this.canEditForm = false;
				this.notificationService.success({ message: "Изменения сохранены успешно!", title: `${this.excelFileName}`, notificationType: NotificationType.Toast });
			} else {
				this.notificationService.error({ message: r.errorDescription, title: `${this.excelFileName}`, notificationType: NotificationType.Toast });
			}

		});
	}

	askRemove() {
		this.notificationService.confirmation({
			title: `${this.excelFileName}`,
			message: `Вы уверены что хотите удалить отчет?`,
			type: "question",
			confirmButtonText: "Да",
			cancelButtonText: "Нет",
			showCloseButton: true
		}, () => {

			this.requestToAgreeReportService.remove(this.requestToAgreeReport.requestToAgreeReportId).subscribe(r => {

				this.router.navigate(['/reports/requests-to-agree']);

				if (r.isSuccessful) {
					this.notificationService.success({
						title: `Отчет успешно удален`,
						notificationType: NotificationType.SweetAlert
					});

				} else {
					this.notificationService.error({
						title: `Ошибка при удалении отчета`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			});

		});
	}

	generateReport() {

		this.reportGenerating = true;

		this.requestToAgreeReportService.getUntrackedRequests(this.requestToAgreeReport.year, this.requestToAgreeReport.month, this.requestToAgreeReport.orgUnitId).subscribe(x => {

			if (!x.isSuccessful)
				return;

			if (x.data.length > 0) {

				this.notificationService.confirmation({
					title: `Неоплаченные заявки`,
					message: `Имеются неоплаченные заявки в прошлых периодах. Учесть в текущем отчете?`,
					type: "question",
					confirmButtonText: "Да",
					cancelButtonText: "Нет",
				}, () => {

					var dialog = this.dialogService.open({
						title: `Неоплаченные заявки в прошлых периодах`,
						content: EmployeePeriodsComponent,
						width: 600,
						actionsLayout: 'normal',
						actions: [
							{ text: 'Отмена' },
              				{ text: 'Продолжить', primary: true }
						]
					});

					const employeePeriodsForm = <EmployeePeriodsComponent>dialog.content.instance;
					employeePeriodsForm.gridData = x.data;

					dialog.result.subscribe((result) => {
						if (result instanceof DialogCloseResult) {
							this.reportGenerating = false;
						} else {
							console.log(employeePeriodsForm.selectedEmployeePeriod);
							this.generateReportCore(employeePeriodsForm.selectedEmployeePeriod);
						}
					});

					
		
				}, () => {
					this.generateReportCore([]);
				});

			} else {
				this.generateReportCore([]);
			}

		});


	}

	generateReportCore(employeePeriods: EmployeePeriod[]) {

		this.reportGenerating = true;

		this.requestToAgreeReport.untrackedEmployeePeriods = employeePeriods;

		this.requestToAgreeReportService.generate(this.requestToAgreeReport).subscribe(r => {
			this.reportGenerating = false;

			if (r.isSuccessful) {
				this.appService.MarkFormPristine(this.requestToAgreeReportForm);
				this.router.navigate([`/reports/requests-to-agree/`, r.data]);

			} else {
				this.notificationService.error({ message: r.errorDescription, title: `${this.excelFileName}`, notificationType: NotificationType.Toast });
			}

		});
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}

	public cellClick({ dataItem }: any): void {

		if (dataItem.requestToAgreeId != null) {
			this.router.navigate(['/documents/request-to-agree', dataItem.requestToAgreeId]);
		} else if (dataItem.manualActionId != null) {
			this.router.navigate(['/documents/manual-action', dataItem.manualActionId]);
		}

		
	}

	public allExcelData = (): ExcelExportData => {
		var self = this;

		var result: ExcelExportData = {
		  data: process(self.requestToAgreeReport.rows, {
			group: self.state.group,
			filter: self.state.filter,
			sort: self.state.sort,
		  }).data,
		  group: self.state.group,
		};
	
		return result;
	}

}