export class DistanceExtraPayValue {
    distanceExtraPayValueId: number;
    distanceExtraPayId: number;

    roadType: number;
    kmFrom: number;
    kmTo: number;
    ratio: number;

    dateStart: Date;
    dateEnd: Date;
    serviceCenterId: number;
    serviceCenterName: string;
}