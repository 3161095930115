import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { IntlService } from '@progress/kendo-angular-intl';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { Router, NavigationStart, RoutesRecognized } from '@angular/router';
import { Subscription } from "rxjs";

@Injectable()
export class AppService {
	private navigationEventsSubscription: Subscription;

	// последний переход произошел с использованием кнопки Back
	private _isBackNavButtonPressed : boolean;

	get IsBackNavButtonPressed(): boolean {
		return this._isBackNavButtonPressed;
	}

	constructor(private titleService: Title,
				private intl: IntlService,
				private router: Router) {
		this.navigationEventsSubscription = this.router.events
			.subscribe(event => {
				if (event instanceof NavigationStart) {
					var navStartEvent = <NavigationStart>event;
			
					// была нажата кнопка Back
					if (navStartEvent.navigationTrigger === "popstate") {
						this._isBackNavButtonPressed = true;
					}
					else {
						this._isBackNavButtonPressed = false;
					}
				}
			});
		
		// отписываться не будем, но можем
		//this.navigationEventsSubscription.unsubscribe();
	}

	// была удалена запись, после чего должен был произойти переход по маршруту RouteAfterEntityDeleted
	public IsEntityDeleted : boolean;
	public RouteAfterEntityDeleted : string;

	get pageTitle(): string {
		return this.titleService.getTitle();
	}	

	// Set page title
	set pageTitle(value: string) {

		var title = "Выплаты";

		if (!!value){
			title = `${value} - ${title}`;
		}

		this.titleService.setTitle(title);
	}

	// Check for RTL layout
	get isRTL() {
		return document.documentElement.getAttribute('dir') === 'rtl' ||
			document.body.getAttribute('dir') === 'rtl';
	}

	// Check if IE10
	get isIE10() {
		return typeof document['documentMode'] === 'number' && document['documentMode'] === 10;
	}

	// Layout navbar color
	get layoutNavbarBg() {
		return 'dark';
	}

	// Layout sidenav color
	get layoutSidenavBg() {
		return 'sidenav-theme';
	}

	// Layout footer color
	get layoutFooterBg() {
		return 'footer-theme';
	}

	// Animate scrollTop
	scrollTop(to: number, duration: number, element = document.scrollingElement || document.documentElement) {
		if (element.scrollTop === to) { return; }
		const start = element.scrollTop;
		const change = to - start;
		const startDate = +new Date();

		// t = current time; b = start value; c = change in value; d = duration
		const easeInOutQuad = (t, b, c, d) => {
			t /= d / 2;
			if (t < 1) { return c / 2 * t * t + b; }
			t--;
			return -c / 2 * (t * (t - 2) - 1) + b;
		};

		const animateScroll = function () {
			const currentDate = +new Date();
			const currentTime = currentDate - startDate;
			element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
			if (currentTime < duration) {
				requestAnimationFrame(animateScroll);
			} else {
				element.scrollTop = to;
			}
		};

		animateScroll();
	}

	MarkFormPristine(form: NgForm): void {
		Object.keys(form.controls).forEach(control => {
			form.controls[control].markAsPristine();
		});
	}

	MarkFormDirty(form: NgForm): void {
		Object.keys(form.controls).forEach(control => {
			form.controls[control].markAsDirty();
		});
	}

	parseDate(date: Date): Date{
		let parsedDate = new Date(this.intl.formatDate(date, 'yyyy-MM-dd'));
		return parsedDate.toString() === "Invalid Date" ? null : parsedDate;
	}

	parseDateTime(date: Date): Date {
		return new Date(this.intl.formatDate(date, 'yyyy-MM-ddTHH:mm'));
	}

	
	convertTimeFromString(timeStr: string): Date {
		var timeParts = timeStr.split(":");

		var dtNow = new Date();
		dtNow.setHours(+timeParts[0], +timeParts[1], 0);

		return dtNow;
	}

	convertTimeToString(date: Date): string {
		return this.intl.formatDate(date, 'HH:mm');
	}

	/* наименование компонента, для которого предназначен OuterFilter */
	public OuterFilterRoute : string = "";

	/* фильтр, устанавливаемый для компонента извне */
	public OuterFilter : DataSourceRequestState = null;
}