import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { SuggestionAddress } from '../models/MaintainObject/SuggestionAddress';

@Injectable()
export class GeoService {

	protected controllerVersion = 1;
	protected controllerName = "Geo";

	constructor(public http: HttpClient) { }

	public suggest(address: string, count: number): Observable<ActionDataResult<SuggestionAddress[]>> {
		return this.http.get<ActionDataResult<SuggestionAddress[]>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/suggest?address=${address}&count=${count}`);
	}
}