import { Claim } from "./Claim"

export class AuthUserDto {
	userId: number;
	login: string = "";
	userName: string = "";
	bearerToken: string = "";
	isAuthenticated: boolean = false;
	claims: Claim[] = [];
	employeeId: number;
	employeeDisplayName: string;
	subordinateEmployeeIds: number[] = [];
}