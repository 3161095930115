import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { CompletionCode } from '../models/CompletionCode';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class CompletionCodeService extends DataService<CompletionCode> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "completioncodes";
	}

}