import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
	selector: 'home',
	templateUrl: './home.page.html',
	styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {

	todayIs: string;
	currentMonth: string;

	constructor(
		private appService: AppService) {
		this.appService.pageTitle = '';
	}

	ngOnInit(): void {
		// this.dashboardService.getDashboard().subscribe(x => {
		// 	this.dashboardData = x.data;
		// 	this.dashboardData.dailySales.forEach(x => x.dailySales.forEach(y => y.date = this.intl.parseDate(y.date + '')));
		// });
	}

	

}