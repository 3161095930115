import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { RequestToAgreeTariffService } from '../../../shared/services/request-to-agree-tariff.service';
import { RequestToAgreeTariff } from '../../../shared/models/request-to-agree-tariffs/request-to-agree-tariff';
import { RequestToAgree } from '../../../shared/models/requests-to-agree/requests-to-agree';
import { RequestToAgreeService } from '../../../shared/services/request-to-agree.service';
import { EmployeeService } from '../../../shared/services/employee.service';
import { Employee } from '../../../shared/models/Employee/Employee';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SecurityService } from '../../../core/services/security.service';
import { orderBy } from '@progress/kendo-data-query';
import { RequestToAgreeAction } from '../../../shared/models/requests-to-agree/request-to-agree-action';
import { RequestToAgreeActionResult } from '../../../shared/models/enums/request-to-agree-action-result';
import { getDate } from '@progress/kendo-date-math';

@Component({
	selector: 'document-request-to-agree',
	templateUrl: './request-to-agree.page.html',
	styleUrls: [
		'./request-to-agree.page.scss',
		//'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class RequestToAgreePage implements OnInit, TrackChangesComponent {

	public dropDownFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };
	
	@ViewChild('requestForm') public requestForm: NgForm;

	hasChanges(): boolean{
		return !this.requestForm.pristine;
	};

	employees: Employee[] = [];
	requestToAgreeTariffs: RequestToAgreeTariff[] = [];

	isNew: boolean = false;
	canEditForm: boolean;
	requestToAgreeId: string;
	request: RequestToAgree;
	isDialogConfirmed: boolean = true;
	saveInProgress: boolean = false;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private notificationService: NotificationService,
		private requestToAgreeService: RequestToAgreeService,
		private employeeService: EmployeeService,
		private requestToAgreeTariffService: RequestToAgreeTariffService,
		private securityService: SecurityService

		) {
			this.appService.pageTitle = "Заявка на согласование";
		}

	ngOnInit() {

		this.loadDropDownsData();

		this.requestToAgreeId = this.route.snapshot.paramMap.get('requestToAgreeId');

		if (this.requestToAgreeId != null){

			this.requestToAgreeService.getById(+this.requestToAgreeId).subscribe(r => {
				this.dataBind(r.data);

				//this.appService.pageTitle = r.data.name;
			});

		} else {

			this.request = new RequestToAgree();
			this.request.createdDate = new Date();
			this.request.entityStatusDate = new Date();
			this.request.entityStatus = 1;
			this.request.entityStatusString = "Активен";
			this.request.statusString = "Создан";
			this.request.comments = '';
			this.request.status = 1;
			this.request.responsibleEmployeeId = this.securityService.currentUser.employeeId;
			this.request.higherRatio = 0.5;
			// this.request.plannedLunchTime = 0.5;
			// this.request.factLunchTime = 0.5;
			this.request.totalAmount = 0;
			// this.request.plannedStartDate = 
			// 	this.request.plannedEndDate =
			// 		/*this.request.factStartDate =
			// 			this.request.factEndDate =*/ new Date();

			this.canEditForm = true;
			this.isNew = true;
		}

		

	}

	dataBind(request: RequestToAgree) {

		request.plannedStartDate = this.appService.parseDateTime(request.plannedStartDate);
		request.plannedEndDate = this.appService.parseDateTime(request.plannedEndDate);

		if (request.factStartDate)
			request.factStartDate = this.appService.parseDateTime(request.factStartDate);

		if (request.factEndDate)
			request.factEndDate = this.appService.parseDateTime(request.factEndDate);

		request.entityStatusDate = this.appService.parseDate(request.entityStatusDate);

		this.request = request;
	}

	public plannedDatesChange(): void {
		this.request.plannedLunchTime = this.plannedTime <= 4 ? 0.5 : 1;
	}

	public factDatesChange(): void {
		this.request.factLunchTime = this.factTime <= 4 ? 0.5 : 1;
	}

	get plannedTime(): number {

		if (!this.request.plannedEndDate || !this.request.plannedStartDate)
			return 0;

		var plannedTime = Math.abs(this.request.plannedEndDate.getTime() - this.request.plannedStartDate.getTime()) / 60000 / 60 - (this.request.plannedLunchTime ? this.request.plannedLunchTime : 0);
		this.request.plannedTime = Math.round(plannedTime * 100) / 100;
		return this.request.plannedTime;
	}

	get factTime(): number {

		if (!this.request.factEndDate || !this.request.factStartDate)
			return 0;

		var factTime = Math.abs(this.request.factEndDate.getTime() - this.request.factStartDate.getTime()) / 60000 / 60 - (this.request.factLunchTime ? this.request.factLunchTime : 0);
		this.request.factTime = Math.round(factTime * 100) / 100;
		return this.request.factTime;
	}

	get isFreeTariff(): boolean {
		if (!this.request.requestToAgreeTariffId || this.requestToAgreeTariffs.length === 0)
			return false;

		return this.requestToAgreeTariffs.some(x => x.requestToAgreeTariffId == this.request.requestToAgreeTariffId && x.isFree);
	}

	get requestTariff(): RequestToAgreeTariff {
		if (!this.request.requestToAgreeTariffId || this.requestToAgreeTariffs.length === 0)
			return null;

		var filtered = this.requestToAgreeTariffs.filter(x => x.requestToAgreeTariffId == this.request.requestToAgreeTariffId);

		return filtered.length !== 0 ? filtered[0] : null;
	}

	get plannedAmount(): number {

		var amount = 0;

		if (this.isFreeTariff){

			if (!this.request.plannedPricePerUnit || !this.request.plannedQuantity)
				return 0;

			amount = this.request.plannedPricePerUnit * this.request.plannedQuantity;

		} else {
			if (!this.request.performerEmployeeId || this.employees.length === 0 || !this.request.plannedEndDate)
				return 0;

			var employee = this.employees.find(x => x.employeeId == this.request.performerEmployeeId);

			if (!employee)
				return 0;

			var salary = this.getEmployeeSalary(employee, this.request.plannedEndDate);

			var tariff = this.requestTariff;

			if (!tariff)
				return 0;

			var coeff = ((salary / (tariff.hoursQuantity * tariff.daysQuantity)) * (tariff.factor + (this.request.useHigherRatio ? this.request.higherRatio : 0)));

			amount = coeff * this.plannedTime;
		}

		this.request.plannedAmount = Math.round(amount * 100) / 100;
		return this.request.plannedAmount;
	}

	get factAmount(): number {

		var amount = 0;

		if (this.isFreeTariff){

			if (!this.request.factPricePerUnit || !this.request.factQuantity)
				return 0;

			amount = this.request.factPricePerUnit * this.request.factQuantity;

		} else {
			if (!this.request.performerEmployeeId || this.employees.length === 0 || !this.request.factEndDate)
				return 0;

			var employee = this.employees.find(x => x.employeeId == this.request.performerEmployeeId);

			if (!employee)
				return 0;

			var salary = this.getEmployeeSalary(employee, this.request.factEndDate);

			var tariff = this.requestTariff;

			if (!tariff)
				return 0;

			var coeff = ((salary / (tariff.hoursQuantity * tariff.daysQuantity)) * (tariff.factor + (this.request.useHigherRatio ? this.request.higherRatio : 0)));

			amount = coeff * this.factTime;
		}

		this.request.totalAmount = this.request.factAmount = Math.round(amount * 100) / 100;
		return this.request.factAmount;
	}

	get factPricePerUnit(): number{
		this.request.factPricePerUnit = this.request.plannedPricePerUnit;
		return this.request.factPricePerUnit;
	}

	getEmployeeSalary(employee: Employee, date: Date): number {

        if (employee.employeeSalaries.length === 0)
            return 0;

        var salaries = orderBy(employee.employeeSalaries, [{ field: "startsFrom", dir: "desc" }]);

        var employeeSalary = salaries.filter(x => getDate(x.startsFrom) <= getDate(date))[0];

        if (!employeeSalary)
			return 0;

        return employeeSalary.amount;
	}
	
	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}


	get requestPerformer(): Employee {
		if (!this.request.performerEmployeeId || this.employees.length === 0)
			return null;

		var filtered = this.employees.filter(x => x.employeeId == this.request.performerEmployeeId);

		return filtered.length !== 0 ? filtered[0] : null;
	}

	saveChanges(): void {

		if (!this.isNew)
		{
			this.upsert();
			return;
		}

		var self = this;

		this.saveInProgress = true;
		this.requestToAgreeService.validate(this.request).subscribe(x => {

			this.saveInProgress = false;

			if (!x.isSuccessful){
				this.notificationService.error({message: x.errorDescription, title: 'Заявка на согласование', notificationType: NotificationType.Toast});
				return;
			}

			this.dataBind(x.data);

			var notConfirmedValidations = this.request.validations.filter(x => !x.answer);

			if (notConfirmedValidations.length === 0){
				this.upsert();
				return;
			}

			this.notificationService.confirmation({
				title: this.appService.pageTitle,
				message: notConfirmedValidations[0].description,
				type: "question",
				confirmButtonText: "Да",
				cancelButtonText: "Нет",
				showCloseButton: false
			}, () => {

				this.request.comments += 
				`\n----------------------------------------` +
				`\n${notConfirmedValidations[0].description} Ответ: Да` +
				'\n----------------------------------------';

				this.request.validations.forEach(x => x.answer = true);

				self.saveChanges();

			});

		});

	}


	upsert(){

		this.saveInProgress = true;
		this.requestToAgreeService.upsert(this.request).subscribe(r => {

			this.saveInProgress = false;
			if (r.isSuccessful){

				if (this.requestToAgreeId == null){
					this.appService.MarkFormPristine(this.requestForm);
					this.router.navigate(['/documents/request-to-agree', r.data.requestToAgreeId]);
				} else {
					this.dataBind(r.data);
					this.appService.MarkFormPristine(this.requestForm);
					this.canEditForm = false;
					this.notificationService.success({message: "Изменения сохранены успешно!", title: 'Заявка на согласование', notificationType: NotificationType.Toast});
				}

				
			} else{
				this.notificationService.error({message: r.errorDescription, title: 'Заявка на согласование', notificationType: NotificationType.Toast});
			}
			
		});

	}

	askRemove(){
		this.notificationService.confirmation({
            title: this.appService.pageTitle,
			message: `Вы уверены что хотите удалить заявку на согласование?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.requestToAgreeService.remove(this.request.requestToAgreeId).subscribe(r => {

				this.router.navigate(['/documents/requests-to-agree']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись успешно удалено`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.appService.pageTitle}`,
			message: "Вы уверены что хотите архивировать заявку на согласование?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.requestToAgreeService.archive(this.request.requestToAgreeId).subscribe(r => {

				this.router.navigate(['/documents/requests-to-agree']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.appService.pageTitle}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	public rowClass() {
		return {
			"cursor-pointer": true
		};
	}

	loadDropDownsData() {

		this.employeeService.activeList("displayName").subscribe(x =>{
			
			if (!x.isSuccessful)
				return;

			this.employees = x.data

			this.employees.forEach(e => {
				e.employeeSalaries.forEach(s => s.startsFrom = this.appService.parseDate(s.startsFrom));
			});
		});

		this.requestToAgreeTariffService.activeList("name").subscribe(x => {

			if (!x.isSuccessful)
				return;

			this.requestToAgreeTariffs = x.data;
			
			this.requestToAgreeTariffs.forEach(t => {
				t.requestToAgreeTariffPeriods.forEach(p => {
					p.dtTimeFrom = this.appService.convertTimeFromString(p.timeFrom);
					p.dtTimeTo = this.appService.convertTimeFromString(p.timeTo);
				});
			});


			
		});
	}

	
	get isUserAdmin() : boolean {
		return this.securityService.hasClaim('IsAdmin');
	}
	

	get actionForCurrentUserId(): RequestToAgreeAction {

		if (!this.request || !this.securityService.currentUser.employeeId)
			return null;

		var filtered = this.isUserAdmin ? this.request.actions
					 					: this.request.actions.filter(x => x.employeeId == this.securityService.currentUser.employeeId && x.result == null);

		if (filtered.length === 0)
			return null;

		return filtered[0];
	}

	get actionForCurrentUserIdOrHisSubordinate (): RequestToAgreeAction {

		if (!this.request || !this.securityService.currentUser.employeeId)
			return null;

		var filtered = this.request.actions.filter(x => (x.employeeId == this.securityService.currentUser.employeeId || this.securityService.currentUser.subordinateEmployeeIds.some(bId => bId === x.employeeId)) && x.result == null);

		if (filtered.length === 0)
			return null;

		return filtered[0];
	}

	get isCurrentActionForPerformer(): boolean {

		if (!this.request || !this.request.performerEmployeeId)
			return false;

		return this.request.actions.some(x => x.employeeId == this.request.performerEmployeeId && x.result == null);
	}

	makeAction(actionTypeId: number){
		this.requestToAgreeService.makeAction(this.request.requestToAgreeId, actionTypeId).subscribe(x => {

			if (x.isSuccessful){
				this.dataBind(x.data);
			} else{
				this.notificationService.error({message: x.errorDescription, title: 'Заявка на согласование', notificationType: NotificationType.Toast});
			}

			
		});
	}

	setActionResultDialog(resultAction: RequestToAgreeActionResult) {

		this.notificationService.question({ showCancelButton: resultAction !== RequestToAgreeActionResult.Canceled },
			(comment: string) => {
				this.preSetActionResult(resultAction, comment);
			}, () => {
				this.preSetActionResult(resultAction, "");
			});

	}

	preSetActionResult(resultAction: RequestToAgreeActionResult, comment: string) {

		if (resultAction === RequestToAgreeActionResult.Completed) {

			console.log('isFreeTariff', this.isFreeTariff);
			console.log('factLunchTime', this.request.factLunchTime);
			console.log('factQuantity', this.request.factQuantity);

			if (!this.request.factStartDate ||
				!this.request.factEndDate ||
				(!this.isFreeTariff && this.request.factLunchTime == null) ||
				(this.isFreeTariff && this.request.factQuantity == null)
			) {

				this.notificationService.error({message: 'Не заполнены поля фактическая дата начала, фактическая дата окончания или количество', title: 'Заявка на согласование', notificationType: NotificationType.Toast});
				return;
			}

			this.requestToAgreeService.updateFactsFields(this.request).subscribe(x => {
				this.setActionResult(resultAction, comment);
			});
		} else {
			this.setActionResult(resultAction, comment);
		}

	}

	setActionResult(resultAction: RequestToAgreeActionResult, comment: string) {

		this.requestToAgreeService.makeActionResult(this.request.requestToAgreeId, resultAction, comment).subscribe(x => {

			if (x.isSuccessful){
				this.dataBind(x.data);
				this.appService.MarkFormPristine(this.requestForm);
			} else{
				this.notificationService.error({message: x.errorDescription, title: 'Заявка на согласование', notificationType: NotificationType.Toast});
			}

		});

	}

	get factFieldsAvailable(): boolean {
		return false;
	}


}
