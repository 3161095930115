import { Component, Input, OnInit } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators';
import { DataSourceRequestState, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DataService } from '../../../../../core/services/data.service';

@Component({
    selector: 'virtual-dropdown-filter',
    templateUrl: './virtual-dropdown-filter.component.html',
})
export class VirtualDropDownFilterComponent extends BaseFilterCellComponent implements OnInit  {

    public filterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

    @Input()
    public filter: CompositeFilterDescriptor;
    
    @Input()
    dataService: DataService<any>;

    @Input()
    keyFieldName: string;

    @Input()
    valueFieldName: string;
    
    @Input()
    public filterFieldName: string;
    
    public data: any[] = [];

    private filterValueSubject: Subject<string> = new Subject();

    public get defaultItem(): any {
        return {
            [this.keyFieldName]: null,
            [this.valueFieldName]: "(Все)"
        };
    }

    public get selectedValue(): any {
        const filter = this.filterByField(this.filterFieldName);

        if (filter) {
            if (this.data) {
                let selectedItem = this.data.find(x => x[this.keyFieldName] == filter.value);

                // такого элемента нет в коллекции,
                // но непонятно почему - то ли еще не прогрузились,
                // то ли и не было запроса на поиск
                if (!selectedItem) {
                    // если сейчас данные не запрашиваются с сервера - запрашиваем
                    if (!this._dataIsLoading) {
                        this.employeeFilter("", filter.value);
                    }
                }
            }
        }

        return filter ? filter.value : null;
    }

    public state: DataSourceRequestState = {
		skip: 0,
		take: 10,
        sort: [],
        filter: {
			logic: "and",
			filters: []
		}
	};

    constructor(filterService: FilterService) {
        super(filterService);
    }

    ngOnInit(): void {
        
		this.filterValueSubject.pipe(debounceTime(400)).subscribe(value => {
			this.employeeFilter(value);
        });
        
        this.employeeFilter("");
    }

    public onChange(value: any): void {

        this.applyFilter(
            value === null ? // value of the default item
                this.removeFilter(this.filterFieldName) : // remove the filter
                this.updateFilter({ // add a filter for the field with the value
                    field: this.filterFieldName,
                    operator: 'eq',
                    value: value
                })
        ); // update the root filter
    }

    handleFilter(value) {
		this.filterValueSubject.next(value);
    }
    
    private _dataIsLoading : boolean;   // признак текущего обращения к API

    employeeFilter(value: string, id: number = 0){
        this.state.sort = [];

        this.state.sort.push({ field: this.valueFieldName, dir: "asc" });

		this.state.filter.filters = [{ field: "entityStatus", operator: "eq", value: 1 }];

		if (value !== "") {
			this.state.filter.filters.push({
				field: this.valueFieldName,
				operator: 'contains',
				value: value,
				ignoreCase: true
			});
        }

        if (id !== 0) {
			this.state.filter.filters.push({
				field: this.keyFieldName,
				operator: 'eq',
				value: id
			});
        }

        this._dataIsLoading = true;
        
		this.dataService.list(this.state).subscribe(data => {
            this._dataIsLoading = false;

            this.data = data.data;
		});
	}
}
