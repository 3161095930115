import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EmployeeListPage } from './employees/list/list.page';
import { EmployeePage } from './employees/employee/employee.page';
import { EmployeeService } from '../shared/services/employee.service';
import { CustomerListPage } from './customers/list/list.page';
import { CustomerPage } from './customers/customer/customer.page';
import { MaintainObjectListPage } from './maintain-objects/list/list.page';
import { MaintainObjectPage } from './maintain-objects/maintain-object/maintain-object.page';
import { PositionListPage } from './positions/list/list.page';
import { PositionPage } from './positions/position/position.page';
import { OrgUnitListPage } from './org-units/list/list.page';
import { OrgUnitPage } from './org-units/org-unit/org-unit.page';
import { LaddaModule } from 'angular2-ladda';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { EmployeeCalendarPage } from './employees/employee-calendar/employee-calendar.page';
import { ServiceCenterListPage } from './service-centers/list/list.page';
import { ServiceCenterPage } from './service-centers/service-center/service-center.page';

@NgModule({
	declarations: [
		EmployeeListPage,
		EmployeePage,
		EmployeeCalendarPage,

		CustomerListPage,
		CustomerPage,

		MaintainObjectListPage,
		MaintainObjectPage,

		PositionListPage,
		PositionPage,

		OrgUnitListPage,
		OrgUnitPage,

		ServiceCenterListPage,
		ServiceCenterPage

	],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		RouterModule,
		NgbModule,
		LaddaModule,
		ExcelModule
	],
	providers: [
		EmployeeService
	]
})
export class CatalogsModule { }
