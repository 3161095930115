import { OnInit } from '@angular/core';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage';
import { Observable } from 'rxjs/Observable';

export class ListPageBase implements OnInit {

	public loading: boolean;

	public state: DataSourceRequestState = {
		skip: 0,
		take: 10,
		sort: [{ field: "createdDate", dir: "desc" }],
		filter: {
			logic: "and",
			filters: [
				{ field: "entityStatus", operator: "eq", value: 1 }
			]
		}
	};

	constructor(
		protected appService: AppService,
		protected router: Router,
		protected filterStorage: FilterStorage,
		protected entityService: any) {

		// если мы пришли по кнопке Back, следует восстановить фильтр из SessionStorage
		if (appService.IsBackNavButtonPressed) {
			this.state = filterStorage.Get(this.state);
		}
		// если был удален элемент и мы перешли к его списочному представлению - нужно восстановить фильтр
		else if (this.appService.IsEntityDeleted
			&& this.appService.RouteAfterEntityDeleted == this.router.routerState.snapshot.url.toLowerCase()) {
			this.state = filterStorage.Get(this.state);
		}

		this.appService.IsEntityDeleted = false;
		this.appService.RouteAfterEntityDeleted = "";
		this.allData = this.allData.bind(this);
	}

	ngOnInit(): void {
		this.loadData();
	}

	public loadData(): void {
		this.loading = true;

		// сохранение фильтра в SessionStorage
		this.filterStorage.Save(this.state);
	}

	public dataStateChange(state: DataStateChangeEvent): void {
		this.state = state;
		this.loadData();
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}

	get gridHeight(): number {
		return window.innerHeight - 280;
	}

	public allData = (): Observable<any> => {

		var excelState: DataSourceRequestState = {
			skip: 0,
			take: null,
			sort: this.state.sort,
			filter: this.state.filter
		};

        return this.entityService.list(excelState);
    }
}