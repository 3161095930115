import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { EmployeeCategory } from '../../../shared/models/EmployeeCategory/EmployeeCategory';
import { EmployeeCategoriesService } from '../../../shared/services/employee-categories.service';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'settings-employee-categories',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class EmployeeCategoryListPage extends ListPageBase {

	public employeeCategories: ActionDataResult<EmployeeCategory[]>;

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private employeeCategoriesService: EmployeeCategoriesService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, employeeCategoriesService);

		this.appService.pageTitle = "Категории сотрудников";
	}

	loadData(): void {
		super.loadData();

		this.employeeCategoriesService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.employeeCategories = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/settings/employee-category', dataItem.employeeCategoryId]);
	}
}