import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { EmployeeService } from '../../../shared/services/employee.service';
import { DataSourceRequestState, SortDescriptor } from '@progress/kendo-data-query';
import { Employee } from '../../../shared/models/Employee/Employee';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ManualActionsService } from '../../../shared/services/manual-actions.service';
import { ManualAction } from '../../../shared/models/ManualAction/ManualAction';
import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators';
import { createDate, addMonths } from '@progress/kendo-date-math';
import { TariffsService } from '../../../shared/services/tariffs.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { Tariff } from '../../../shared/models/Tariff/Tariff';
import { RowClassArgs } from '@progress/kendo-angular-grid';

@Component({
	selector: 'document-tariff',
	templateUrl: './tariff.page.html',
	styleUrls: ['./tariff.page.scss']
})
export class TariffPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('tariffForm') public tariffForm: NgForm;

	hasChanges(): boolean{
		return !this.tariffForm.pristine;
	};

	public tariffEmployeeCategoriesSort: SortDescriptor[] = [{ field: 'factorName', dir: 'asc' }];

	canEditForm: boolean;
	tariffId: string;
	tariff: Tariff;

	tariffTypes: KeyValueObject[] = [{ id: 1, name: 'Переработка в рабочие дни', entityStatusString: "" }];

	public dropDownFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
	};
	
	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private tariffsService: TariffsService,
		private notificationService: NotificationService,
		private employeeService: EmployeeService) {
			this.appService.pageTitle = "Новый тариф";
		}

	ngOnInit() {

		this.tariffId = this.route.snapshot.paramMap.get('tariffId');

		if (this.tariffId != null){

			this.tariffsService.getById(+this.tariffId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = `Тариф`;
			});

		} else {

			this.tariffsService.getNew().subscribe(r => {
				this.dataBind(r.data);
				this.canEditForm = true;
			});

		}

	}

	dataBind(tariff: Tariff) {
		this.tariff = tariff;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.tariffsService.upsert(this.tariff).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.tariffForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: this.appService.pageTitle, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: this.appService.pageTitle, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: this.appService.pageTitle,
			message: `Вы уверены что хотите удалить действие?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.tariffsService.remove(this.tariff.tariffId).subscribe(r => {

				this.router.navigate(['/documents/tariffs']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Действие успешно удалено`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении действия`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.appService.pageTitle}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.tariffsService.archive(this.tariff.tariffId).subscribe(r => {

				this.router.navigate(['/documents/tariffs']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.appService.pageTitle}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	get tariffTypeString(){

		if (!this.tariff)
			return "";

		var filtered = this.tariffTypes.filter(x => x.id == this.tariff.tariffType);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}

	get createNew(): any {
		return function() {
			var fsv = new Tariff();
			return fsv;
		};
	}

	public cellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 3)
			return;

        sender.editRow(rowIndex);
	}
	
	public saveHandler(obj: any) {
		this.appService.MarkFormDirty(this.tariffForm);
	}

}