import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { EmployeeCategory } from '../../../shared/models/EmployeeCategory/EmployeeCategory';
import { EmployeeCategoriesService } from '../../../shared/services/employee-categories.service';

@Component({
	selector: 'settings-employee-category',
	templateUrl: './employee-category.page.html',
	styleUrls: ['./employee-category.page.scss']
})
export class EmployeeCategoryPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('employeeCategoryForm') public employeeCategoryForm: NgForm;

	hasChanges(): boolean{
		return !this.employeeCategoryForm.pristine;
	};

	canEditForm: boolean;
	employeeCategoryId: string;
	employeeCategory: EmployeeCategory;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private employeeCategoriesService: EmployeeCategoriesService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новая категория";
		}

	ngOnInit() {

		this.employeeCategoryId = this.route.snapshot.paramMap.get('employeeCategoryId');

		if (this.employeeCategoryId != null){

			this.employeeCategoriesService.getById(+this.employeeCategoryId).subscribe(r => {
				this.dataBind(r.data);
			});

		} else {

			this.employeeCategory = new EmployeeCategory();
			this.employeeCategory.createdDate = new Date();
			this.employeeCategory.entityStatus = 1;
			this.employeeCategory.entityStatusString = "Активен";

			this.dataBind(this.employeeCategory);

			this.canEditForm = true;
		}

	}

	dataBind(employeeCategory: EmployeeCategory){
		this.employeeCategory = employeeCategory;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.employeeCategoriesService.upsert(this.employeeCategory).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.employeeCategoryForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.employeeCategory.name}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.employeeCategory.name}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.employeeCategory.name}`,
			message: `Вы уверены что хотите удалить категорию '${this.employeeCategory.name}'?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.employeeCategoriesService.remove(this.employeeCategory.employeeCategoryId).subscribe(r => {

				this.router.navigate(['/settings/employee-categories']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Категория ${this.employeeCategory.name} успешно удалена`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении категории ${this.employeeCategory.name}`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.employeeCategory.name}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.employeeCategoriesService.archive(this.employeeCategory.employeeCategoryId).subscribe(r => {

				this.router.navigate(['/settings/employee-categories']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.employeeCategory.name}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

}