import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { RequestFilesService } from '../../../shared/services/request-files.service';
import { RequestFile } from '../../../shared/models/RequestFile/RequestFile';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { UsersService } from '../../../settings/users/users.service';
import { timer, from, Subscription } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'documents-request-files',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class RequestFilesListPage extends ListPageBase implements OnInit, OnDestroy {

	public requestFiles: ActionDataResult<RequestFile[]>;
	private timerSubscription: Subscription;

	uploadStatuses: KeyValueObject[] = [{ id: 1, name: 'Новый', entityStatusString: "" }, { id: 2, name: 'Обработка', entityStatusString: "" }, { id: 3, name: 'Ошибка', entityStatusString: "" }, { id: 4, name: 'Успешно загружен', entityStatusString: "" }];

	constructor(
		appService: AppService,
	 	router: Router,
		filterStorage: FilterStorage,
		private requestFilesService: RequestFilesService,
		public usersService: UsersService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, requestFilesService);	

		this.appService.pageTitle = "Реестр загрузок";
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.timerSubscription = 
			timer(0, 4000)
			.pipe(concatMap(() => from(this.requestFilesService.list(this.state))))
			.subscribe(r => {
				this.requestFiles = r

				if (this.requestFiles.data.every(x => x.uploadState > 2)){
					this.timerSubscription.unsubscribe();			
				}
			});
	}

	ngOnDestroy(): void {
		this.timerSubscription.unsubscribe();
	}

	loadData(): void {
		super.loadData();

		this.requestFilesService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.requestFiles = data;
		});
	}

	public rowClass({ dataItem }: any) {
		return {
			"cursor-pointer": true,
			"bg-primary": dataItem.uploadState === 2,
			"bg-danger": dataItem.uploadState === 3,
			"bg-success": dataItem.uploadState === 4,
		};
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/documents/request-file', dataItem.requestFileId]);
	}
}