import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { PositionService } from '../../../shared/services/position.service';
import { Position } from '../../../shared/models/position/position';

@Component({
	selector: 'catalogs-positions',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class PositionListPage extends ListPageBase {

	public positions: ActionDataResult<Position[]>;

	constructor(
		appService: AppService,
	    router: Router,
		filterStorage: FilterStorage,
		private positionService: PositionService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, positionService);	

		this.appService.pageTitle = "Должности";
	}

	loadData(): void {
		super.loadData();

		this.positionService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.positions = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/catalogs/position', dataItem.positionId]);
	}
}