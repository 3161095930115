import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhonePipe } from './pipes/Phone.pipe';
import { DateFormatPipe } from './pipes/DateFormatPipe.pipe';
import { NumberFormatPipe } from './pipes/NumberFormatPipe.pipe';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';

import 'hammerjs';
import { EntityStateFilterComponent } from './components/grid/customFilters/entitystate/entitystate-filter.component';
import { FormsModule } from '@angular/forms';
import { BooleanFilterComponent } from './components/grid/customFilters/boolean/boolean-filter.component';
import { EmployeeService } from './services/employee.service';
import { HasClaimDirective } from './directives/has-claim.directive';
import { DropDownFilterComponent } from './components/grid/customFilters/dropdown-filter/dropdown-filter.component';
import { VirtualDropDownFilterComponent } from './components/grid/customFilters/virtual-dropdown-filter/virtual-dropdown-filter.component';
import { EmployeeCategoriesService } from './services/employee-categories.service';
import { MovementTypesService } from './services/movement-types.service';
import { TimeScheduleService } from './services/time-schedule.service';
import { CustomerService } from './services/customer.service';
import { MaintainObjectService } from './services/maintain-object.service';
import { ClassifiersService } from './services/classifiers.service';
import { CompletionCodeService } from './services/completion-codes.service';
import { RequestsService } from './services/requests.service';
import { RequestFilesService } from './services/request-files.service';
import { ManualActionsService } from './services/manual-actions.service';
import { TariffsService } from './services/tariffs.service';
import { SalaryCalcReportService } from './services/salary-calc-report.service';
import { AnalyticReportService } from './services/analytic-report.service';
import { PositionService } from './services/position.service';
import { OrgUnitService } from './services/org-unit.service';
import { RequestToAgreeTariffService } from './services/request-to-agree-tariff.service';
import { RequestToAgreeService } from './services/request-to-agree.service';
import { RequestToAgreeReportService } from './services/request-to-agree-report.service';
import { EmployeePeriodsComponent } from './components/employee-periods/employee-periods.component';
import { MailingGroupsService } from './services/mailing-groups.service';
import { MailingRegistersService } from './services/mailing-registers.service';
import { OrgStructureReporsService } from './services/org-structure-report.service';
import { OrgStructureReportUnitNodeComponent } from './components/org-structure-report-unit-node/org-structure-report-unit-node';
import { ServiceCenterService } from './services/service-center.service';
import { GeoService } from './services/geo.service';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    GridModule,
    DropDownsModule,
    InputsModule,
    FormsModule,
    DateInputsModule,
    DialogsModule
  ],
  entryComponents: [
    EmployeePeriodsComponent
  ],
  declarations: [
    //Components
    LoadingIndicatorComponent,
    EntityStateFilterComponent,
    BooleanFilterComponent,
    DropDownFilterComponent,
    VirtualDropDownFilterComponent,
    EmployeePeriodsComponent,
    OrgStructureReportUnitNodeComponent,

    HasClaimDirective,

    //Pipes
    PhonePipe,
    DateFormatPipe,
    NumberFormatPipe
  ],
  exports: [
    //Components
    LoadingIndicatorComponent,
    EntityStateFilterComponent,
    BooleanFilterComponent,
    DropDownFilterComponent,
    VirtualDropDownFilterComponent,
    EmployeePeriodsComponent,

    HasClaimDirective,
    OrgStructureReportUnitNodeComponent,

    //Pipes
    PhonePipe,
    DateFormatPipe,
    NumberFormatPipe,

		GridModule,
		DropDownsModule,
		InputsModule,
		DateInputsModule,
		DialogsModule
	],
	providers: [
		EmployeeService,
		EmployeeCategoriesService,
		MovementTypesService,
		TimeScheduleService,
		CustomerService,
		MaintainObjectService,
		ClassifiersService,
		CompletionCodeService,
		RequestsService,
		RequestFilesService,
		ManualActionsService,
		TariffsService,
		SalaryCalcReportService,
		AnalyticReportService,
		PositionService,
		OrgUnitService,
		RequestToAgreeTariffService,
		RequestToAgreeService,
		RequestToAgreeReportService,
		MailingGroupsService,
        MailingRegistersService,
        OrgStructureReporsService,
    ServiceCenterService,
    GeoService
	]
})
export class SharedModule { }
