import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { RequestToAgreeTariff } from '../models/request-to-agree-tariffs/request-to-agree-tariff';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class RequestToAgreeTariffService extends DataService<RequestToAgreeTariff> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "request-to-agree-tariff";
	}

}