import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'PageNotFoundPage',
	templateUrl: './PageNotFound.page.html',
	styleUrls: ['./PageNotFound.page.scss']
})
export class PageNotFoundPage {

	constructor(private _location: Location) { }

	goBack() {
		this._location.back();
	}
}