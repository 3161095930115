import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs';
import { ServiceCenter } from '../../../shared/models/ServiceCenter/ServiceCenter';
import { ServiceCenterService } from '../../../shared/services/service-center.service';
import { SuggestionAddress } from '../../../shared/models/MaintainObject/SuggestionAddress';
import { GeoService } from '../../../shared/services/geo.service';

@Component({
	selector: 'catalogs-service-center',
	templateUrl: './service-center.page.html',
	styleUrls: ['./service-center.page.scss']
})
export class ServiceCenterPage implements OnInit, TrackChangesComponent {

	@ViewChild('serviceCenterForm') public serviceCenterForm: NgForm;

	hasChanges(): boolean {
		return !this.serviceCenterForm.pristine;
	};

	canEditForm: boolean;
	serviceCenterId: string;
	serviceCenter: ServiceCenter;
	ngbTypeaheadAddress: any;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private serviceCenterService: ServiceCenterService,
		private notificationService: NotificationService,
		private geoService: GeoService) {
			this.appService.pageTitle = "Новый сервисный центр";
	}

	ngOnInit() {
		this.serviceCenterId = this.route.snapshot.paramMap.get('serviceCenterId');

		if (this.serviceCenterId != null) {

			this.serviceCenterService.getById(+this.serviceCenterId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = `Сервисный центр ${r.data.serviceCenterName}`;
			});

		} else {

			this.serviceCenter = new ServiceCenter();
			this.serviceCenter.createdDate = new Date();
			this.serviceCenter.entityStatus = 1;
			this.serviceCenter.entityStatusString = "Активен";

			this.dataBind(this.serviceCenter);
			this.canEditForm = true;
		}

	}

	dataBind(serviceCenter: ServiceCenter) {
		this.serviceCenter = serviceCenter;
		this.ngbTypeaheadAddress = { address: this.serviceCenter.addressString };
	}

	getCoordinates(): string{

        if (!this.serviceCenter.latitude || !this.serviceCenter.longitude)
            return "";

        return `${this.serviceCenter.latitude}, ${this.serviceCenter.longitude}`;
	}

	toggleFormEdit(): void {
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {
		this.serviceCenter.addressString = this.ngbTypeaheadAddress.address;

			if (!(/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/).test(this.serviceCenter.idSD)) {
				this.notificationService.error({ message: "Некорректно заполнено поле ID SD. Требуется GUID.", title: `${this.serviceCenter.serviceCenterName}`, notificationType: NotificationType.Toast });
				return;
			}

			if (this.serviceCenter.addressString === undefined)
			{
				this.notificationService.error({ message: "Адрес заполнен некорректно.", title: `${this.serviceCenter.serviceCenterName}`, notificationType: NotificationType.Toast });
				return;
			}

		this.serviceCenterService.upsert(this.serviceCenter).subscribe(r => {

			if (r.isSuccessful) {
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.serviceCenterForm);
				this.canEditForm = false;
				this.notificationService.success({ message: "Изменения сохранены успешно!", title: `${this.serviceCenter.serviceCenterName}`, notificationType: NotificationType.Toast });
			} else {
				this.notificationService.error({ message: r.errorDescription, title: `${this.serviceCenter.serviceCenterName}`, notificationType: NotificationType.Toast });
			}

		});
	}

	askRemove() {
		this.notificationService.confirmation({
			title: `${this.serviceCenter.serviceCenterName}`,
			message: `Вы уверены что хотите удалить объект обслуживания '${this.serviceCenter.serviceCenterName}'?`,
			type: "question",
			confirmButtonText: "Да",
			cancelButtonText: "Нет",
			showCloseButton: true
		}, () => {

			this.serviceCenterService.remove(this.serviceCenter.serviceCenterId).subscribe(r => {
				if (r.isSuccessful) {
					this.notificationService.success({
						title: `Сервисный центр '${this.serviceCenter.serviceCenterName}' успешно удален`,
						notificationType: NotificationType.SweetAlert
					});
					this.router.navigate(['/catalogs/service-centers']);
				} else {
					this.notificationService.error({
						title: `Ошибка при удалении объекта обслуживания`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.serviceCenter.serviceCenterName}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.serviceCenterService.archive(this.serviceCenter.serviceCenterId).subscribe(r => {
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.serviceCenter.serviceCenterName}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
					this.router.navigate(['/catalogs/service-centers']);
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	search = (text$: Observable<string>) =>
	text$
		.debounceTime(600)
		.distinctUntilChanged()
		.switchMap(term =>
			this.geoService.suggest(term, 3).map(response => {
				if (response.isSuccessful) {
					return response.data;
				} else {
					this.notificationService.error({message:'Ошибка при обращении в сервис George, см. подробности в логе.', title: 'Ошибка George', notificationType: NotificationType.SweetAlert});
				}
				
			})
				.catch(() => {
					return Observable.of([]);
				}));
				
	formatter = (item: SuggestionAddress) => item.address;

	onSelectAddress(item) {

		this.geoService.suggest(item.item.address, 1).subscribe(x => {

			if (x.data.length == 0)
				return;

			var info = x.data[0].info;

			this.serviceCenter.postCode = info.postCode;
			this.serviceCenter.district = info.district;
			this.serviceCenter.city = info.city;
			this.serviceCenter.street = info.street;
			this.serviceCenter.house = info.house;
			this.serviceCenter.block = info.block;
			//this.serviceCenter.nearestMetroStation = info.nearestMetroStation;

			this.serviceCenter.latitude = info.latitude;
			this.serviceCenter.longitude = info.longitude;
		});
	}
}