import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { DistanceExtraPaysService } from '../distance-extra-pays.service';
import { NotificationService } from '../../../core/services/notification.service';
import { DistanceExtraPay } from '../../../shared/models/DistanceExtraPays/DistanceExtraPay';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'settings-factors',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class DistanceExtraPayListPage extends ListPageBase implements OnInit {

	public distanceExtraPays: ActionDataResult<DistanceExtraPay[]>;
	public hasActive: boolean = true;

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private distanceExtraPaysService: DistanceExtraPaysService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, distanceExtraPaysService);

		this.appService.pageTitle = "Доплата за удаленность";
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.distanceExtraPaysService.hasActive().subscribe(x => this.hasActive = x.data);
	}

	loadData(): void {
		super.loadData();

		this.distanceExtraPaysService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.distanceExtraPays = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/settings/distance-extra-pay', dataItem.distanceExtraPayId]);
	}
}