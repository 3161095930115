import { Component, Input } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'boolean-filter',
    templateUrl: './boolean-filter.component.html',
})
export class BooleanFilterComponent extends BaseFilterCellComponent {

    @Input()
    public filter: CompositeFilterDescriptor;

    @Input()
    public field: string;

    public data: any[] = [
		{ Name: "Да", Value: true },
		{ Name: "Нет", Value: false }
	];

    public get defaultItem(): any {
        return {
            Name: '(Все)',
            Value: null
        };
    }

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public onChange(value: any): void {

        this.applyFilter(
            value === null ? // value of the default item
                this.removeFilter(this.field) : // remove the filter
                this.updateFilter({ // add a filter for the field with the value
                    field: this.field,
                    operator: 'eq',
                    value: value
                })
        ); // update the root filter
    }
}