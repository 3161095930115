import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { Position } from '../../../shared/models/position/position';
import { PositionService } from '../../../shared/services/position.service';

@Component({
	selector: 'catalogs-position',
	templateUrl: './position.page.html',
	styleUrls: ['./position.page.scss']
})
export class PositionPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('positionForm') public positionForm: NgForm;

	hasChanges(): boolean{
		return !this.positionForm.pristine;
	};

	canEditForm: boolean;
	positionId: string;
	position: Position;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private positionService: PositionService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новая должность";
		}

	ngOnInit() {

		this.positionId = this.route.snapshot.paramMap.get('positionId');

		if (this.positionId != null){

			this.positionService.getById(+this.positionId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = r.data.positionName;
			});

		} else {

			this.position = new Position();
			this.position.createdDate = new Date();
			this.position.entityStatus = 1;
			this.position.entityStatusString = "Активен";
			this.position.hasEmployeeCategory = false;
			this.position.hasFullCycle = true;

			this.dataBind(this.position);
			this.canEditForm = true;
		}

	}

	dataBind(position: Position){
		this.position = position;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.positionService.upsert(this.position).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.positionForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.position.positionName}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.position.positionName}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.position.positionName}`,
			message: `Вы уверены что хотите удалить должность'?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.positionService.remove(this.position.positionId).subscribe(r => {

				this.router.navigate(['/catalogs/positions']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Должность '${this.position.positionName}' успешно удалена`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении должности`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.position.positionName}`,
			message: "Вы уверены что хотите архивировать данную должность?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.positionService.archive(this.position.positionId).subscribe(r => {

				this.router.navigate(['/catalogs/positions']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.position.positionName}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

}