import { BaseObject } from "../../core/BaseObject";
import { RequestToAgreeReportRow } from "./request-to-agree-report-row";
import { EmployeePeriod } from '../employee-period';

export class RequestToAgreeReport extends BaseObject {
    requestToAgreeReportId: number;
    month: number;
    year: number;
    name: string;
    orgUnitId: number;
    orgUnitName: string;
    hasPaid: boolean;
    rows: RequestToAgreeReportRow[] = [];
    untrackedEmployeePeriods: EmployeePeriod[] = [];
}