import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { ServiceCenter } from '../../../shared/models/ServiceCenter/ServiceCenter';
import { ServiceCenterService } from '../../../shared/services/service-center.service';

@Component({
	selector: 'catalogs-service-centers',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class ServiceCenterListPage extends ListPageBase {

	public serviceCenters: ActionDataResult<ServiceCenter[]>;

	constructor(
		appService: AppService,
		router: Router,		
		filterStorage: FilterStorage,
		private serviceCenterService: ServiceCenterService) {

		super(appService, router, filterStorage, serviceCenterService);
		this.appService.pageTitle = "Сервисные центры";
	}

	loadData(): void {
		super.loadData();

		this.serviceCenterService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.serviceCenters = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/catalogs/service-center', dataItem.serviceCenterId]);
	}
}