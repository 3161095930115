import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { Employee } from '../../../shared/models/Employee/Employee';
import { EmployeeService } from '../../../shared/services/employee.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { EmployeeCategoriesService } from '../../../shared/services/employee-categories.service';
import { MovementTypesService } from '../../../shared/services/movement-types.service';
import { TimeScheduleService } from '../../../shared/services/time-schedule.service';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { OrgUnitService } from '../../../shared/services/org-unit.service';
import { PositionService } from '../../../shared/services/position.service';
import { Observable } from 'rxjs/Observable';
import { DataSourceRequestState } from '@progress/kendo-data-query';

@Component({
	selector: 'catalogs-employees',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class EmployeeListPage extends ListPageBase {

	public employees: ActionDataResult<Employee[]>;
	public employeeCategories: KeyValueObject[] = [];
	public movementTypes: KeyValueObject[] = [];
	public timeSchedules: KeyValueObject[] = [];
	public orgUnits: KeyValueObject[] = [];
	public positions: KeyValueObject[] = [];

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private notificationService: NotificationService,
		private employeeService: EmployeeService,
		private employeeCategoriesService: EmployeeCategoriesService,
		private movementTypesService: MovementTypesService,
		private timeScheduleService: TimeScheduleService,
		private orgUnitService: OrgUnitService,
		private positionService: PositionService,

		) {
		super(appService, router, filterStorage, employeeService);	
		this.appService.pageTitle = "Сотрудники";
	}

	loadData(): void {
		super.loadData();

		this.employeeService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.employees = data;
		});

		this.employeeCategoriesService.list({
			skip: 0,
			take: 10000,
			sort: [{ field: "name", dir: "asc" }],
		})
		.map(x => x.data)
		.map(x => x.map(x => new KeyValueObject(x.employeeCategoryId, x.name, x.entityStatusString)))
		.subscribe(data => this.employeeCategories = data);

		this.movementTypesService.list({
			skip: 0,
			take: 10000,
			sort: [{ field: "name", dir: "asc" }],
		})
		.map(x => x.data)
		.map(x => x.map(x => new KeyValueObject(x.movementTypeId, x.name, x.entityStatusString)))
		.subscribe(data => this.movementTypes = data);

		this.timeScheduleService.list({
			skip: 0,
			take: 10000,
			sort: [{ field: "name", dir: "asc" }],
		})
		.map(x => x.data)
		.map(x => x.map(x => new KeyValueObject(x.timeScheduleId, x.name, x.entityStatusString)))
		.subscribe(data => this.timeSchedules = data);

		this.orgUnitService.list({
			skip: 0,
			take: null,
			sort: [{ field: "orgUnitName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.map(x => x.map(x => new KeyValueObject(x.orgUnitId, x.orgUnitName, x.entityStatusString)))
		.subscribe(data => this.orgUnits = data);

		this.positionService.list({
			skip: 0,
			take: null,
			sort: [{ field: "positionName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.map(x => x.map(x => new KeyValueObject(x.positionId, x.positionName, x.entityStatusString)))
		.subscribe(data => this.positions = data);
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/catalogs/employee', dataItem.employeeId]);
	}

}