import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { SalaryCalcReportService } from '../../../shared/services/salary-calc-report.service';
import { SalaryCalcReport } from '../../../shared/models/Reports/SalaryCalc/SalaryCalcReport';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'reports-salary-calc-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class SalaryCalcReportListPage extends ListPageBase {

	isForWeekends: boolean = false;
	newItemUrl: string = "/reports/salary-calc/workdays/new";
	pageHeader: string = "Рабочие дни";
	public reports: ActionDataResult<SalaryCalcReport[]>;

	public months: any[] = [ { id: 1, name: 'Январь' }, { id: 2, name: 'Февраль' }, {id: 3, name: 'Март'} , { id: 4, name: 'Апрель' }, { id: 5, name: 'Май' }, { id: 6, name: 'Июнь' }, {id: 7, name: 'Июль'}, { id: 8, name: 'Август'}, { id: 9, name: 'Сентябрь' }, { id: 10, name: 'Октябрь' }, { id: 11, name: 'Ноябрь' }, { id: 12, name: 'Декабрь' }];

	constructor(
		appService: AppService,
		router: Router,
		private route: ActivatedRoute,
		filterStorage: FilterStorage,
		private salaryCalcReportService: SalaryCalcReportService,
		private notificationService: NotificationService)
	{
		super(appService, router, filterStorage, salaryCalcReportService);
		//this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.appService.pageTitle = "Расчеты переработок в рабочие дни";
	}

	loadData(): void {
		super.loadData();

		this.isForWeekends = <boolean>this.route.snapshot.data.isForWeekends;

		if (this.isForWeekends){
			this.appService.pageTitle = "Расчеты переработок в выходные дни";
			this.newItemUrl = "/reports/salary-calc/weekends/new";
			this.pageHeader = "Выходные дни";
		}

		this.salaryCalcReportService.setKind(this.isForWeekends);

		this.salaryCalcReportService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.reports = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate([`/reports/salary-calc/${ this.isForWeekends ? 'weekends' : 'workdays' }`, dataItem.salaryCalcReportId]);
	}

	getMonthName(monthNumber: number){

		if (!monthNumber)
			return "";

		var filtered = this.months.filter(x => x.id == monthNumber);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}
}