import { BaseObject } from "../core/BaseObject";
import { TimeScheduleDay } from "./TimeScheduleDay";

export class TimeSchedule extends BaseObject {
    timeScheduleId: number;
    name: string;
    startFrom: Date;
    hasPreHolidays: boolean;
    preHolidayHoursToDecrease: number;
    preHolidayIsWeekend: number;

    days: TimeScheduleDay[] = [];
}