import { Pipe, PipeTransform } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';

@Pipe({ name: 'numberFormat' })
export class NumberFormatPipe implements PipeTransform {

	constructor(
		private intl: IntlService
	) { }

	transform(value: number, format: string): string {
		return this.intl.formatNumber(value, format);
	}
}