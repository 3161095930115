import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { SortDescriptor, DataSourceRequestState } from '@progress/kendo-data-query';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { RequestToAgreeTariffService } from '../../../shared/services/request-to-agree-tariff.service';
import { RequestToAgreeTariff } from '../../../shared/models/request-to-agree-tariffs/request-to-agree-tariff';
import { TimeScheduleService } from '../../../shared/services/time-schedule.service';
import { TimeSchedule } from '../../../shared/models/TimeSchedule/TimeSchedule';
import { RequestToAgreeTariffTimeSchedule } from '../../../shared/models/request-to-agree-tariffs/request-to-agree-tariff-time-schedule';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { RequestToAgreeTariffDayType } from '../../../shared/models/request-to-agree-tariffs/request-to-agree-tariff-day-type';
import { RequestToAgreeTariffPeriod } from '../../../shared/models/request-to-agree-tariffs/request-to-agree-tariff-period';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
	selector: 'document-request-to-agree-tariff',
	templateUrl: './request-to-agree-tariff.page.html',
	styleUrls: ['./request-to-agree-tariff.page.scss']
})
export class RequestToAgreeTariffPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('tariffForm') public tariffForm: NgForm;

	hasChanges(): boolean{
		return !this.tariffForm.pristine;
	};

	public tariffEmployeeCategoriesSort: SortDescriptor[] = [{ field: 'factorName', dir: 'asc' }];

	canEditForm: boolean;
	tariffId: string;
	tariff: RequestToAgreeTariff;
	timeSchedules: TimeSchedule[] = [];

	tariffTypes: KeyValueObject[] = [{ id: 1, name: 'Переработка в рабочие дни', entityStatusString: "" }];

	dayTypes: any[] = [{ id: 1, name: 'Рабочий' }, { id: 2, name: 'Выходной' }, { id: 3, name: 'Праздничный' }, { id: 4, name: 'Предпраздничный' }, { id: 5, name: 'Отпуск' }, { id: 6, name: 'Отгул' }];

	public dropDownFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
	};
	
	constructor(
		private appService: AppService,
		private intl: IntlService,
		private route: ActivatedRoute,
		private router: Router,
		private notificationService: NotificationService,
		private requestToAgreeTariffService: RequestToAgreeTariffService,
		private timeScheduleService: TimeScheduleService,
		) {
			this.appService.pageTitle = "Тарифы для заявок на согласование";
		}

	ngOnInit() {

		this.loadDropDownsData();

		this.tariffId = this.route.snapshot.paramMap.get('requestToAgreeTariffId');

		if (this.tariffId != null){

			this.requestToAgreeTariffService.getById(+this.tariffId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = r.data.name;
			});

		} else {

			this.tariff = new RequestToAgreeTariff();
			this.tariff.createdDate = new Date();
			this.tariff.entityStatusDate = new Date();
			this.tariff.entityStatus = 1;
			this.tariff.entityStatusString = "Активен";
			this.tariff.canDelete = true;
			this.canEditForm = true;

			this.tariff.startsFrom = new Date();
		}

	}

	dataBind(tariff: RequestToAgreeTariff) {
		tariff.startsFrom = this.appService.parseDate(tariff.startsFrom);

		tariff.requestToAgreeTariffPeriods.forEach(x => {
			x.dtTimeFrom = this.appService.convertTimeFromString(x.timeFrom);
			x.dtTimeTo = this.appService.convertTimeFromString(x.timeTo);
		});

		this.tariff = tariff;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.requestToAgreeTariffService.upsert(this.tariff).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.tariffForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: 'Тариф для заявок на согласование', notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: 'Тариф для заявок на согласование', notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: this.appService.pageTitle,
			message: `Вы уверены что хотите удалить запись?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.requestToAgreeTariffService.remove(this.tariff.requestToAgreeTariffId).subscribe(r => {

				this.router.navigate(['/documents/request-to-agree-tariffs']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись успешно удалено`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.appService.pageTitle}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.requestToAgreeTariffService.archive(this.tariff.requestToAgreeTariffId).subscribe(r => {

				this.router.navigate(['/documents/request-to-agree-tariffs']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.appService.pageTitle}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}

	loadDropDownsData() {

		var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			sort: [{ field: "name", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		};

		this.timeScheduleService.list(state).subscribe(x => this.timeSchedules = x.data);
	}

	getTimeScheduleNameString(timeScheduleId: number){
		var filtered = this.timeSchedules.filter(x => x.timeScheduleId == timeScheduleId);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}

	get createNewTimeSchedule(): any {

		return function() {
			var timeSchedule = new RequestToAgreeTariffTimeSchedule();
			return timeSchedule;
		};
	}

	public timeScheduleCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (columnIndex > 0 || !this.canEditForm || !this.tariff.canDelete)
			return;

        sender.editRow(rowIndex);
        
	}

	public timeScheduleSaveHandler(obj: any) {

		if (this.tariff.requestToAgreeTariffTimeSchedules.some(x => x.timeScheduleId == (<RequestToAgreeTariffTimeSchedule>obj.dataItem).timeScheduleId))
		{
			obj.dataItem = null;
			return;
		}

		this.appService.MarkFormDirty(this.tariffForm);
	}

	public employeeUserRemoveHandler(obj: any) {
		this.appService.MarkFormDirty(this.tariffForm);
	}

	getDayTypeNameString(dayTypeId: number){
		var filtered = this.dayTypes.filter(x => x.id == dayTypeId);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}

	get createNewDayType(): any {

		return function() {
			var tariffDayType = new RequestToAgreeTariffDayType();
			return tariffDayType;
		};
	}

	public dayTypesSaveHandler(obj: any) {

		if (this.tariff.requestToAgreeTariffDayTypes.some(x => x.dayType == (<RequestToAgreeTariffDayType>obj.dataItem).dayType))
		{
			obj.dataItem = null;
			return;
		}

		this.appService.MarkFormDirty(this.tariffForm);
	}

	get createNewTariffPeriod(): any {

		return function() {
			var tariffPeriod = new RequestToAgreeTariffPeriod();
			return tariffPeriod;
		};
	}

	handleTimeFromChange(dateTime: Date, dataItem: RequestToAgreeTariffPeriod){
		dataItem.timeFrom = this.appService.convertTimeToString(dateTime);
	}

	handleTimeToChange(dateTime: Date, dataItem: RequestToAgreeTariffPeriod){
		dataItem.timeTo = this.appService.convertTimeToString(dateTime);
	}

	public tariffPeriodsCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (columnIndex > 1 || !this.canEditForm || !this.tariff.canDelete)
			return;

        sender.editRow(rowIndex);
        
	}


	public tariffPeriodsSaveHandler(obj: any) {

		// if (this.tariff.requestToAgreeTariffDayTypes.some(x => x.dayType == (<RequestToAgreeTariffDayType>obj.dataItem).dayType))
		// {
		// 	obj.dataItem = null;
		// 	return;
		// }

		this.appService.MarkFormDirty(this.tariffForm);
	}

	public dayTypesCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (columnIndex > 0 || !this.canEditForm || !this.tariff.canDelete)
			return;

        sender.editRow(rowIndex);
        
	}

	


}