import { Component } from '@angular/core';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { UsersService } from '../users.service';
import { User } from '../../../shared/models/User/User';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { MarkBlockUnblock } from '../../../shared/models/User/MarkBlockUnblock';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'settings-roles',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class UsersListPage extends ListPageBase {

	public users: ActionDataResult<User[]>;

	public selectedUserIds: number[] = [];
    public selectAllState: SelectAllCheckboxState = 'unchecked';

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private usersService: UsersService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, usersService);


		this.appService.pageTitle = "Пользователи";
	}

	loadData(): void {
		super.loadData();

		this.usersService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.users = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/settings/user', dataItem.userId]);
	}

	markBlockUnblock(value: boolean){

		var action = value ? "блокировать" : "активировать";

		var markBlockUnblock = new MarkBlockUnblock();
		markBlockUnblock.userIds = this.selectedUserIds;
		markBlockUnblock.isBlocked = value;

		this.notificationService.confirmation({
            //title: this.role.name,
			message: `Вы уверены что хотите ${action} выбранных пользователей?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.usersService.markBlockUnblock(markBlockUnblock).subscribe(r => {

				if (r.isSuccessful){

					this.loadData();

					this.notificationService.success({
						title: "Действие выполнено успешно",
						//message: "Роль успешно удалена",
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: "Ошибка",
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});

	}

	public onSelectedKeysChange(e) {
        const len = this.selectedUserIds.length;

        if (len === 0) {
            this.selectAllState = 'unchecked';
        } else if (len > 0 && len < this.users.data.length) {
            this.selectAllState = 'indeterminate';
        } else {
            this.selectAllState = 'checked';
        }
    }

    public onSelectAllChange(checkedState: SelectAllCheckboxState) {
        if (checkedState === 'checked') {
            this.selectedUserIds = this.users.data.map((item) => item.userId);
            this.selectAllState = 'checked';
        } else {
            this.selectedUserIds = [];
            this.selectAllState = 'unchecked';
        }
    }
}