import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { MovementType } from '../../shared/models/MovementType/MovementType';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class MovementTypesService extends DataService<MovementType> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "movementtype";
	}

}