import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { MovementType } from '../../../shared/models/MovementType/MovementType';
import { MovementTypesService } from '../../../shared/services/movement-types.service';

@Component({
	selector: 'settings-movement-type',
	templateUrl: './movement-type.page.html',
	styleUrls: ['./movement-type.page.scss']
})
export class MovementTypePage implements OnInit, TrackChangesComponent {
	
	@ViewChild('movementTypeForm') public movementTypeForm: NgForm;

	hasChanges(): boolean{
		return !this.movementTypeForm.pristine;
	};

	canEditForm: boolean;
	movementTypeId: string;
	movementType: MovementType;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private movementTypesService: MovementTypesService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новый тип";
		}

	ngOnInit() {

		this.movementTypeId = this.route.snapshot.paramMap.get('movementTypeId');

		if (this.movementTypeId != null){

			this.movementTypesService.getById(+this.movementTypeId).subscribe(r => {
				this.dataBind(r.data);
			});

		} else {

			this.movementType = new MovementType();
			this.movementType.createdDate = new Date();
			this.movementType.entityStatus = 1;
			this.movementType.entityStatusString = "Активен";

			this.dataBind(this.movementType);

			this.canEditForm = true;
		}

	}

	dataBind(movementType: MovementType){
		this.movementType = movementType;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.movementTypesService.upsert(this.movementType).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.movementTypeForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.movementType.name}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.movementType.name}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.movementType.name}`,
			message: `Вы уверены что хотите удалить тип передвижения '${this.movementType.name}'?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.movementTypesService.remove(this.movementType.movementTypeId).subscribe(r => {

				this.router.navigate(['/settings/movement-types']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Тип '${this.movementType.name}' успешно удален`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении типа ${this.movementType.name}`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.movementType.name}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.movementTypesService.archive(this.movementType.movementTypeId).subscribe(r => {

				this.router.navigate(['/settings/movement-types']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.movementType.name}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

}