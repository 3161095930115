import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { SalaryCalcReport } from '../models/Reports/SalaryCalc/SalaryCalcReport';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { NotificationService } from '../../core/services/notification.service';
import { EmployeePeriod } from '../models/Reports/employee-period';
import { tap } from 'rxjs/internal/operators/tap';
import { NotificationType } from '../../core/services/notification-type';

@Injectable()
export class SalaryCalcReportService extends DataService<SalaryCalcReport> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "salary-calc/workdays";
	}

	setKind(isForWeekends: boolean) {
		this.controllerName = isForWeekends ? "salary-calc/weekends" : "salary-calc/workdays";
	}

	public generate(salaryCalcReport: SalaryCalcReport): Observable<ActionDataResult<number>> {
		return this.http.post<ActionDataResult<number>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/generate`, salaryCalcReport);
	}

	public getUntrackedRequests(year: number, month: number): Observable<ActionDataResult<EmployeePeriod[]>> {
		return this.http.get<ActionDataResult<EmployeePeriod[]>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/untracked-requests?year=${year}&month=${month}`);
	}

	public exportToExcel(salaryCalcReportId: number): Observable<ActionDataResult<string>> {
		const url = `${environment.salaryCalcServiceUrl}${this.controllerName}/export-excel?salaryCalcReportId=${salaryCalcReportId}`;
		
		return this.http.get<ActionDataResult<string>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при скачивании файла',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при скачивании файла',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}
}