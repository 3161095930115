import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../shared/models/User/User';
import { DataService } from '../../core/services/data.service';
import { Observable } from 'rxjs/Observable';
import { ActionResult } from '../../shared/models/core/ActionResult';
import { MarkBlockUnblock } from '../../shared/models/User/MarkBlockUnblock';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class UsersService extends DataService<User> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "users";
	}

	public markBlockUnblock(markBlockUnblock: MarkBlockUnblock): Observable<ActionResult> {
		return this.http.put<ActionResult>(`${environment.salaryCalcServiceUrl}${this.controllerName}/markBlockUnblock`, markBlockUnblock);
	}

}