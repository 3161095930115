import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { AnalyticReport } from '../../../shared/models/Reports/Analytic/AnalyticReport';
import { AnalyticReportService } from '../../../shared/services/analytic-report.service';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from "@progress/kendo-data-query";

@Component({
	selector: 'reports-analytic-report',
	templateUrl: './analytic-report.page.html',
	styleUrls: ['./analytic-report.page.scss']
})
export class AnalyticReportPage implements OnInit, TrackChangesComponent {

	isForWeekends: boolean = false;
	listUrl: string = "/reports/analytic/workdays";
	pageHeader: string = "Рабочие дни";
	
	@ViewChild('analyticReportForm') public analyticReportForm: NgForm;

	hasChanges(): boolean{
		return !this.analyticReportForm.pristine;
	};

	canEditForm: boolean;
	analyticReportId: string;
	analyticReport: AnalyticReport;
	isNew: boolean;
	loading: boolean;

	get gridHeight(): number{
		return window.innerHeight - 240;
	}

	months = [ { id: 1, name: 'Январь' }, { id: 2, name: 'Февраль' }, {id: 3, name: 'Март'} , { id: 4, name: 'Апрель' }, { id: 5, name: 'Май' }, { id: 6, name: 'Июнь' }, {id: 7, name: 'Июль'}, { id: 8, name: 'Август'}, { id: 9, name: 'Сентябрь' }, { id: 10, name: 'Октябрь' }, { id: 11, name: 'Ноябрь' }, { id: 12, name: 'Декабрь' }];

    public state: DataSourceRequestState = {
		skip: 0,
		take: 50,
		sort: [{ field: "employeeName", dir: "asc" }],
		filter: {
			logic: "and",
			filters: []
		}
	};

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private analyticReportService: AnalyticReportService,
		private notificationService: NotificationService,
		private filterStorage: FilterStorage) {
			// если мы пришли по кнопке Back, следует восстановить фильтр из SessionStorage
			if (appService.IsBackNavButtonPressed){
				this.state = filterStorage.Get(this.state);
			}

			this.appService.pageTitle = "Отчет аналитика по рабочим дням";
		}

	public dataStateChange(state: DataStateChangeEvent): void {
		this.state = state;

		console.log(this.state);
	
		// сохранение фильтра в SessionStorage
		this.filterStorage.Save(this.state);
	}			

	ngOnInit() {

		this.isForWeekends = <boolean>this.route.snapshot.data.isForWeekends;

		if (this.isForWeekends){
			this.appService.pageTitle = "Отчет аналитика по выходным дням";
			this.listUrl = "/reports/analytic/weekends";
			this.pageHeader = "Выходные дни";
		}

		this.analyticReportService.setKind(this.isForWeekends);

		this.analyticReportId = this.route.snapshot.paramMap.get('analyticReportId');

		if (this.analyticReportId != null){

			this.analyticReportService.getById(+this.analyticReportId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = this.excelFileName;
			});

		} else {

			this.analyticReport = new AnalyticReport();
			this.analyticReport.createdDate = new Date();
			this.analyticReport.month = this.analyticReport.createdDate.getMonth();
			this.analyticReport.year = this.analyticReport.createdDate.getFullYear();
			this.analyticReport.entityStatus = 1;
			this.analyticReport.entityStatusString = "Активен";
			this.analyticReport.isForWeekends = this.isForWeekends;

			this.dataBind(this.analyticReport);
			this.canEditForm = true;
			this.isNew = true;
		}

	}

	getMonthName(monthNumber: number){

		if (!monthNumber)
			return "";

		var filtered = this.months.filter(x => x.id == monthNumber);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}

	get excelFileName() {

		if (!this.analyticReport || !this.analyticReport.month)
			return "Отчет аналитика";

		return `Отчет аналитика за ${this.getMonthName(this.analyticReport.month)} ${this.analyticReport.year}`;
	}

	dataBind(analyticReport: AnalyticReport){
		this.analyticReport = analyticReport;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.analyticReportService.upsert(this.analyticReport).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.analyticReportForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.excelFileName}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.excelFileName}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.excelFileName}`,
			message: `Вы уверены что хотите удалить расчет?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.analyticReportService.remove(this.analyticReport.salaryCalcReportId).subscribe(r => {

				this.router.navigate([this.listUrl]);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Расчет успешно удален`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении расчета`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	generateReport() {
		
		this.loading = true;
		
		this.analyticReportService.generate(this.analyticReport).subscribe(r => {
			this.loading = false;
			
			if (r.isSuccessful){
				this.analyticReport.rows = r.data.rows;
				this.appService.MarkFormPristine(this.analyticReportForm);
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.excelFileName}`, notificationType: NotificationType.Toast});
			}

		});
	}

	public allExcelData = (): ExcelExportData => {
		var self = this;

		var result: ExcelExportData = {
		  data: process(self.analyticReport.rows, {
			filter: self.state.filter,
			sort: self.state.sort,
		  }).data,
		};
	
		return result;
	}

}