import { BaseObject } from "../core/BaseObject";

export class OrgUnit extends BaseObject {
    orgUnitId: number;
    orgUnitName: string;
    hasTakenPartInRequestAgreement: boolean;

    parentOrgUnitId: number;
    parentOrgUnitName: string;

    chiefPositionId: number;
    chiefPositionName: string;
}