import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { MaintainObject } from '../../../shared/models/MaintainObject/MaintainObject';
import { MaintainObjectService } from '../../../shared/services/maintain-object.service';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'catalogs-maintain-objects',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class MaintainObjectListPage extends ListPageBase {

	public maintainObjects: ActionDataResult<MaintainObject[]>;

	constructor(
		appService: AppService,
		router: Router,		
		filterStorage: FilterStorage,
		private maintainObjectService: MaintainObjectService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, maintainObjectService);
		this.appService.pageTitle = "Объекты обслуживания";
	}

	loadData(): void {
		super.loadData();
	
		this.maintainObjectService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.maintainObjects = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/catalogs/maintain-object', dataItem.maintainObjectId]);
	}
}