// элемент сохраненного фильтра для табличного компонента
export class StoredFilterItem {
    route : string = "";
    filter : string = "";

    constructor (
        route: string, 
        filter: string) {
        this.route = route;
        this.filter = filter;
    }
}