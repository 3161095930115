import { Component, Input, ChangeDetectionStrategy, AfterViewInit, HostBinding, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { LayoutService } from '../layout.service';
import { RequestToAgreeService } from '../../shared/services/request-to-agree.service';
import { RequestToAgreeCounter } from '../../shared/models/requests-to-agree/requests-to-agree-counter';
import { timer } from 'rxjs/internal/observable/timer';
import { Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-layout-sidenav',
	templateUrl: './layout-sidenav.component.html',
	styles: [':host { display: block; }'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSidenavComponent implements AfterViewInit, OnDestroy {

	@Input() orientation = 'vertical';

	@HostBinding('class.layout-sidenav') private hostClassVertical = false;
	@HostBinding('class.layout-sidenav-horizontal') private hostClassHorizontal = false;
	@HostBinding('class.flex-grow-0') private hostClassFlex = false;
	
	counters: RequestToAgreeCounter[] = [];
	isTabActive: boolean = true;
	sub: Subscription;

	constructor(private router: Router,
				private appService: AppService,
				private layoutService: LayoutService,
				private requestToAgreeService: RequestToAgreeService,
				private cdr: ChangeDetectorRef) {
		// Set host classes
		this.hostClassVertical = this.orientation !== 'horizontal';
		this.hostClassHorizontal = !this.hostClassVertical;
		this.hostClassFlex = this.hostClassHorizontal;
	}

	ngAfterViewInit() {
		// Safari bugfix
		this.layoutService._redrawLayoutSidenav();

		this.requestToAgreeService.counters$.subscribe(x => {
			this.counters = x;

			if (!this.cdr['destroyed']) {
				this.cdr.detectChanges();
			}
		});

		window.onfocus = () => this.isTabActive = true;
		window.onblur = () => this.isTabActive = false; 
		
		const source = timer(7000, 7000);
		
		this.sub = source.subscribe(() => {
			
			if (!this.isTabActive)
				return;

			this.requestToAgreeService.refreshCounters();
		});
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	getCountersByActionType(actionType: number){
		
		var filtered = this.counters.filter(x => x.actionType === actionType);

		if (filtered.length == 0)
			return 0;

		return filtered[0].quantity;
	}

	getClasses() {
		let bg = this.appService.layoutSidenavBg;

		if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
			bg = bg
				.replace(' sidenav-dark', '')
				.replace(' sidenav-light', '')
				.replace('-darker', '')
				.replace('-dark', '');
		}

		return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
	}

	isActive(url) {
		return this.router.isActive(url, true);
	}

	isMenuActive(url) {
		return this.router.isActive(url, false);
	}

	isMenuOpen(url) {
		return this.router.isActive(url, false) && this.orientation !== 'horizontal';
	}

	toggleSidenav() {
		this.layoutService.toggleCollapsed();
	}
}
