import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { RequestFile } from '../../../shared/models/RequestFile/RequestFile';
import { RequestFilesService } from '../../../shared/services/request-files.service';

@Component({
	selector: 'document-request-file',
	templateUrl: './request-file.page.html',
	styleUrls: [
		'./request-file.page.scss',
		'../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class RequestFilePage implements OnInit, TrackChangesComponent {
	
	hasChanges(): boolean {
		return false;
	};

	canEditForm: boolean;
	requestFileId: string;
	requestFile: RequestFile;
	downloadInProgress: boolean = false;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private requestFilesService: RequestFilesService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новый файл";
		}

	ngOnInit() {

		this.requestFileId = this.route.snapshot.paramMap.get('requestFileId');

		if (this.requestFileId != null){

			this.requestFilesService.getById(+this.requestFileId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = `${r.data.fileName}`;
			});

		} else {

			this.requestFile = new RequestFile();
			this.requestFile.createdDate = new Date();

			this.requestFile.entityStatus = 1;
			this.requestFile.entityStatusString = "Активен";

			this.dataBind(this.requestFile);
			this.canEditForm = true;
		}

	}

	dataBind(requestFile: RequestFile){

		requestFile.createdDate = this.appService.parseDate(requestFile.createdDate);

		// if (requestFile.errors){
		// 	requestFile.errors = requestFile.errors.replace("\n", "<br />");
		// }
		
		this.requestFile = requestFile;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	downloadFile(){

		if (this.downloadInProgress)
			return;

		this.downloadInProgress = true;

		this.requestFilesService.downloadFile(this.requestFile.requestFileId).subscribe(blob => {

			saveAs(blob, this.requestFile.fileName, {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});

			this.downloadInProgress = false;
		});
		
	}

	reloadFile(){

		this.requestFilesService.reloadFile(this.requestFile.requestFileId).subscribe(x => {
			this.router.navigate(['/documents/request-files']);
		});
		
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.requestFile.fileName}`,
			message: `Вы уверены что хотите удалить файл '${this.requestFile.fileName}'?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.requestFilesService.remove(this.requestFile.requestFileId).subscribe(r => {

				this.router.navigate(['/documents/request-files']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Файл '${this.requestFile.fileName}' успешно удален`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении файла`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.requestFile.fileName}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.requestFilesService.archive(this.requestFile.requestFileId).subscribe(r => {

				this.router.navigate(['/documents/request-files']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.requestFile.fileName}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

}