import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { Request } from '../models/Request/Request';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class RequestsService extends DataService<Request> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "requests";
	}

}