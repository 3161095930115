import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { RequestsService } from '../../../shared/services/requests.service';
import { AppService } from '../../../app.service';
import { Request } from '../../../shared/models/Request/Request';
import { RequestFilesService } from '../../../shared/services/request-files.service';
import { EmployeeService } from '../../../shared/services/employee.service';
import { CompletionCodeService } from '../../../shared/services/completion-codes.service';
import { CustomerService } from '../../../shared/services/customer.service';
import { MaintainObjectService } from '../../../shared/services/maintain-object.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { TouchSequence } from 'selenium-webdriver';

@Component({
	selector: 'documents-requests',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class RequestListPage extends ListPageBase {

	public requests: ActionDataResult<Request[]>;

	constructor(
	    appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private requestsService: RequestsService,
		public requestFilesService: RequestFilesService,
		public employeeService: EmployeeService,
		public customersService: CustomerService,
		public maintainObjectService: MaintainObjectService,
		public completionCodeService: CompletionCodeService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, requestsService);	

		this.appService.pageTitle = "Заявки";
	}

	ngOnInit(): void {
		if (this.appService.OuterFilterRoute === this.router.routerState.snapshot.url) {
			this.state = this.appService.OuterFilter;

			this.appService.OuterFilterRoute = null;
			this.appService.OuterFilter = null;
		}

        super.ngOnInit();
	}

	loadData(): void {
		super.loadData();

		this.requestsService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.requests = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/documents/request', dataItem.requestId]);
	}
}