import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { AppService } from '../../../app.service';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { EmployeeCalendar } from '../../../shared/models/Employee/employee-calendar';
import { EmployeeDay } from '../../../shared/models/Employee/employee-day';
import { EmployeeService } from '../../../shared/services/employee.service';

@Component({
	selector: 'catalogs-employee-calendar',
	templateUrl: './employee-calendar.page.html',
	styleUrls: [
		'./employee-calendar.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class EmployeeCalendarPage implements OnInit, TrackChangesComponent {

	hasChanges(): boolean{
		return false;
	};

	public gridSort: SortDescriptor[] = [{ field: 'day', dir: 'desc' }];

	employeeId: string;
	employeeCalendar: EmployeeCalendar;

	constructor(
			private appService: AppService,
			private router: Router,
			private route: ActivatedRoute,
			private employeeService: EmployeeService,
		) {
			this.appService.pageTitle = "Персональный каледнарь";
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		}

	ngOnInit() {

		this.employeeId = this.route.snapshot.paramMap.get('employeeId');

		if (this.employeeId != null){

			this.employeeService.getCalendar(+this.employeeId).subscribe(res => {
				this.employeeCalendar = res.data;
				this.appService.pageTitle = `Персональный каледнарь ${this.employeeCalendar.employeeDisplayName}`;"";

				this.employeeCalendar.days.forEach(x => {
					x.day = this.appService.parseDate(x.day);
				});
			});

		}

	}

	public rowClass(context: RowClassArgs) {

		var item = <EmployeeDay>context.dataItem;
		var now = new Date();

		return {
			"bg-primary":	item.day.getFullYear() === now.getFullYear() &&
							item.day.getMonth() === now.getMonth() &&
							item.day.getDate() === now.getDate()
		};
	}

}