import { BaseObject } from "../core/BaseObject";

export class Request extends BaseObject{
    requestId: number;
    requestNumber: string;
    requestDate: Date;
    requestsFileId: number;

    responsibleEmployeeId: number;
    installedEmployeeId: number;
    completionCodeId: number;
    customerId: number;
    maintainObjectId: number;
    plannedStartDate: Date;
    recoveryDate: Date;
    factEndDate: Date;
    periodDate: Date;
    hasAct: boolean;
    actDate: Date;

    requestsFileName: string;
    responsibleEmployeeName: string;
    installedEmployeeName: string;
    completionCodeName: string;
    customerName: string;
    maintainObjectCode: string;
    maintainObjectAddress: string;
    outsideTheCity: boolean;
    maintainObjectBeltwayDistance: number;

    serviceCenterId: number;
    distance: number;
}