import { Pipe, PipeTransform } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {

	constructor(
		private intl: IntlService
	) { }

	transform(value: string, format: string): string {
		return this.intl.formatDate(this.intl.parseDate(value), format);
	}
}