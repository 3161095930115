import { BaseObject } from "../core/BaseObject";

export class ManualAction extends BaseObject {
    manualActionId: number;
    employeeId: number;
    employeeName: string;
    manualActionType: number;
    forReport: number;
    month: number;
    year: number;
    amount: number;
    comment: string;
    requestToAgreeId: number;

    manualActionTypeName: string;
    measureUnitName: string;
}