import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { MovementType } from '../../../shared/models/MovementType/MovementType';
import { MovementTypesService } from '../../../shared/services/movement-types.service';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'settings-movement-types',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class MovementTypeListPage extends ListPageBase {

	public movementTypes: ActionDataResult<MovementType[]>;

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private movementTypesService: MovementTypesService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, movementTypesService);

		this.appService.pageTitle = "Типы передвижения";

		console.log("Constructor");
	}

	loadData(): void {
		super.loadData();

		this.movementTypesService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.movementTypes = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/settings/movement-type', dataItem.movementTypeId]);
	}
}