import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	toDataSourceRequestString,
	DataSourceRequestState
} from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import { ActionResult } from '../../shared/models/core/ActionResult';
import { tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { NotificationType } from './notification-type';
import { filter } from 'rxjs/operators';

@Injectable()
export class DataService<T> {

	protected controllerName: string = "roles";

	constructor(protected http: HttpClient,
				protected notificationService: NotificationService
	) { }

	public list(state: DataSourceRequestState): Observable<ActionDataResult<T[]>> {
		var queryStr = `${toDataSourceRequestString(state)}`;

		//хак, это ошибка компонента грид
		queryStr = queryStr.replace(/T\d\d-00-0/g, 'T00-00-00');

		return this.http.get<ActionDataResult<T[]>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/list?${queryStr}`)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}
			
		}))
		.pipe(filter(x => x.isSuccessful))
		
	}

	public getById(id: number): Observable<ActionDataResult<T>> {
		return this.http.get<ActionDataResult<T>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/${id}`)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}
			
		}));
	}

	public getNew(): Observable<ActionDataResult<T>> {
		return this.http.get<ActionDataResult<T>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/new`);
	}

	public activeList(sortBy: string): Observable<ActionDataResult<T[]>> {
		return this.list(<DataSourceRequestState>{
			skip: 0,
			take: null,
			sort: [{ field: sortBy, dir: "asc" }],
			filter: { filters: [{ field: 'entityStatus', operator: 'eq', value: 1 } ]} });
	  }

	public upsert(role: T): Observable<ActionDataResult<T>> {
		return this.http.post<ActionDataResult<T>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/upsert`, role);
	}

	public archive(id: number): Observable<ActionResult> {
		return this.http.put<ActionResult>(`${environment.salaryCalcServiceUrl}${this.controllerName}/archive/${id}`, null);
	}

	public remove(id: number): Observable<ActionResult> {
		return this.http.delete<ActionResult>(`${environment.salaryCalcServiceUrl}${this.controllerName}/${id}`);
	}
}