import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { OrgUnitService } from '../../../shared/services/org-unit.service';
import { OrgUnit } from '../../../shared/models/org-unit/org-unit';
import { PositionService } from '../../../shared/services/position.service';


@Component({
	selector: 'catalogs-org-units',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class OrgUnitListPage extends ListPageBase {

	public orgUnits: ActionDataResult<OrgUnit[]>;
	public positions: KeyValueObject[] = [];
	public orgUnitsToFilter: KeyValueObject[] = [];

	constructor(
		appService: AppService,
	    router: Router,
		filterStorage: FilterStorage,
		private orgUnitService: OrgUnitService,
		private positionService: PositionService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, orgUnitService);	
		this.appService.pageTitle = "Орг. единицы";
	}

	loadData(): void {
		super.loadData();

		this.positionService.list({
			skip: 0,
			take: null,
			sort: [{ field: "positionName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.map(x => x.map(x => new KeyValueObject(x.positionId, x.positionName, x.entityStatusString)))
		.subscribe(data => this.positions = data);

		this.orgUnitService.list({
			skip: 0,
			take: null,
			sort: [{ field: "orgUnitName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.map(x => x.map(x => new KeyValueObject(x.orgUnitId, x.orgUnitName, x.entityStatusString)))
		.subscribe(data => this.orgUnitsToFilter = data);

		this.orgUnitService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.orgUnits = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/catalogs/org-unit', dataItem.orgUnitId]);
	}

}