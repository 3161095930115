import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { DistanceExtraPay } from '../../shared/models/DistanceExtraPays/DistanceExtraPay';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class DistanceExtraPaysService extends DataService<DistanceExtraPay> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "distancepays";
	}

	public hasActive(): Observable<ActionDataResult<boolean>> {
		return this.http.get<ActionDataResult<boolean>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/hasActive`);
	}

}