import { Component, OnInit, ViewChild } from '@angular/core';
import { RolesService } from '../roles.service';
import { Role } from '../../../shared/models/Role/Role';
import { GroupDescriptor, DataResult, process } from '@progress/kendo-data-query';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../../../app.service';
import { getBootstrapListener } from '@angular/router/src/router_module';


@Component({
	selector: 'settings-role',
	templateUrl: './role.page.html',
	styleUrls: ['./role.page.scss']
})
export class RolePage implements OnInit, TrackChangesComponent {
	
	@ViewChild('roleForm') public roleForm: NgForm;

	hasChanges(): boolean{
		return !this.roleForm.pristine;
	};

	canEditForm: boolean;
	roleId: string;
	role: Role;
	gridData: DataResult;
	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private rolesService: RolesService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новая роль";
		}

	ngOnInit() {

		this.roleId = this.route.snapshot.paramMap.get('roleId');

		var roleObservable: Observable<ActionDataResult<Role>>;

		if (this.roleId != null){
			roleObservable = this.rolesService.getById(+this.roleId);
		} else {
			roleObservable = this.rolesService.getNew();
			this.canEditForm = true;
		}

		roleObservable.subscribe(r => {
			this.dataBind(r.data);
		});

	}

	dataBind(role: Role){
		this.role = role;
		this.gridData = process(this.role.roleCategories, { group: [{ field: 'groupName' }], sort: [{ field: "categoryId" }] });

		if (this.role.name && this.role.name !== "")
			this.appService.pageTitle = this.role.name;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.rolesService.upsert(this.role).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.roleForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: this.role.name, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: "Ошибка при сохранении изменений!", title: this.role.name, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemoveRole(): void {

		this.notificationService.confirmation({
            title: this.role.name,
			message: "Вы уверены что хотите удалить роль?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.rolesService.remove(this.role.roleId).subscribe(r => {

				this.router.navigate(['/settings/roles']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: this.role.name,
						message: "Роль успешно удалена",
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: "Ошибка при удалении роли",
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});

	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.role.name}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.rolesService.archive(this.role.roleId).subscribe(r => {

				this.router.navigate(['/settings/roles']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.role.name}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

}