import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { DirtyStateGuard } from '../core/guards/dirty-state.guard';

import { RolesListPage } from './roles/list/list.page';
import { RolePage } from './roles/role/role.page';
import { UsersListPage } from './users/list/list.page';
import { UserPage } from './users/user/user.page';
import { ProdCalendarListPage } from './prod-calendar/list/list.page';
import { ProdCalendarPage } from './prod-calendar/prod-calendar/prod-calendar.page';
import { TimeScheduleListPage } from './time-schedules/list/list.page';
import { TimeSchedulePage } from './time-schedules/time-schedule/time-schedule.page';
import { FactorListPage } from './factor/list/list.page';
import { FactorSetPage } from './factor/factor-set/factor-set.page';
import { DistanceExtraPayListPage } from './distance-extra-pays/list/list.page';
import { DistanceExtraPayPage } from './distance-extra-pays/distance-extra-pay/distance-extra-pay.page';
import { EmployeeCategoryListPage } from './employee-categories/list/list.page';
import { EmployeeCategoryPage } from './employee-categories/employee-category/employee-category.page';
import { MovementTypeListPage } from './movement-types/list/list.page';
import { MovementTypePage } from './movement-types/movement-type/movement-type.page';
import { MailingGroupsListPage } from './mailings/mailing-groups/list/list.page';
import { MailingGroupPage } from './mailings/mailing-groups/mailing-group/mailing-group.page';
import { MailingRegistersListPage } from './mailings/mailing-registers/list/list.page';
import { MailingRegisterPage } from './mailings/mailing-registers/mailing-register/mailing-register.page';

export const settingsRoutes: Routes = [

	{ path: 'users', component: UsersListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-users-read' } },
	{ path: 'user/new', component: UserPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-users-edit' } },
	{ path: 'user/:userId', component: UserPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-users-read' } },

	{ path: 'roles', component: RolesListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-roles-read' } },
	{ path: 'role/new', component: RolePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-roles-edit' } },
	{ path: 'role/:roleId', component: RolePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-roles-read' } },

	{ path: 'ProdCalendars', component: ProdCalendarListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-prodCalendar-read' } },
	{ path: 'ProdCalendar/new', component: ProdCalendarPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-prodCalendar-edit' } },
	{ path: 'ProdCalendar/:prodCalendarId', component: ProdCalendarPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-prodCalendar-read' } },

	{ path: 'timeSchedules', component: TimeScheduleListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-timeSchedule-read' } },
	{ path: 'timeSchedule/new', component: TimeSchedulePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-timeSchedule-edit' } },
	{ path: 'timeSchedule/:timeScheduleId', component: TimeSchedulePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-timeSchedule-read' } },

	{ path: 'factors', component: FactorListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-Factors-read' } },
	{ path: 'factor/new', component: FactorSetPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-Factors-edit' } },
	{ path: 'factor/:factorsSetId', component: FactorSetPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-Factors-read' } },

	{ path: 'distance-extra-pays', component: DistanceExtraPayListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-distance-extra-pays-read' } },
	{ path: 'distance-extra-pay/new', component: DistanceExtraPayPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-distance-extra-pays-edit' } },
	{ path: 'distance-extra-pay/:distanceExtraPayId', component: DistanceExtraPayPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-distance-extra-pays-read' } },

	{ path: 'employee-categories', component: EmployeeCategoryListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-employee-categories-read' } },
	{ path: 'employee-category/new', component: EmployeeCategoryPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-employee-categories-edit' } },
	{ path: 'employee-category/:employeeCategoryId', component: EmployeeCategoryPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-employee-categories-read' } },

	{ path: 'movement-types', component: MovementTypeListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-movement-types-read' } },
	{ path: 'movement-type/new', component: MovementTypePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-movement-types-edit' } },
	{ path: 'movement-type/:movementTypeId', component: MovementTypePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-movement-types-read' } },

	{ path: 'mailing-groups', component: MailingGroupsListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-mailing-groups-read' } },
	{ path: 'mailing-group/new', component: MailingGroupPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-mailing-groups-edit' } },
	{ path: 'mailing-group/:mailingGroupId', component: MailingGroupPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-mailing-groups-read' } },

	{ path: 'mailing-registers', component: MailingRegistersListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-mailing-register-read' } },
	{ path: 'mailing-register/new', component: MailingRegisterPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-mailing-register-edit' } },
	{ path: 'mailing-register/:mailingRegisterId', component: MailingRegisterPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-settings-mailing-register-read' } },

	
	
];