import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { RequestFile } from '../models/RequestFile/RequestFile';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActionResult } from '../models/core/ActionResult';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class RequestFilesService extends DataService<RequestFile> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "requestfiles";
	}

	public uploadFiles(formData: FormData): Observable<ActionResult> {
		return this.http.post<ActionResult>(`${environment.salaryCalcServiceUrl}${this.controllerName}/upload`, formData);
	}

	public reloadFile(fileId: number): Observable<ActionResult> {
		return this.http.post<ActionResult>(`${environment.salaryCalcServiceUrl}${this.controllerName}/reload/${fileId}`, null);
	}

	public downloadFile(fileId: number): Observable<any> {
		var apiDownloadUrl = `${environment.salaryCalcServiceUrl}${this.controllerName}/download/${fileId}`;

		return this.http.get(apiDownloadUrl, { responseType: 'blob' });
	}

}