import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { EmployeeService } from '../../../shared/services/employee.service';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { Employee } from '../../../shared/models/Employee/Employee';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ManualActionsService } from '../../../shared/services/manual-actions.service';
import { ManualAction } from '../../../shared/models/ManualAction/ManualAction';
import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators';
import { createDate, addMonths } from '@progress/kendo-date-math';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { RequestToAgreeService } from '../../../shared/services/request-to-agree.service';

@Component({
	selector: 'document-manual-action',
	templateUrl: './manual-action.page.html',
	styleUrls: [
		'./manual-action.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ManualActionPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('manualActionForm') public manualActionForm: NgForm;

	hasChanges(): boolean{
		return !this.manualActionForm.pristine;
	};

	public employeesState: DataSourceRequestState = {
		skip: 0,
		take: 100000,
		sort: [{ field: "Surname", dir: "asc" }],
		filter: {
			logic: "and",
			filters: [
				{ field: "entityStatus", operator: "eq", value: 1 }
			]
		}
	};

	months = [ { id: 1, name: 'Январь' }, { id: 2, name: 'Февраль' }, {id: 3, name: 'Март'} , { id: 4, name: 'Апрель' }, { id: 5, name: 'Май' }, { id: 6, name: 'Июнь' }, {id: 7, name: 'Июль'}, { id: 8, name: 'Август'}, { id: 9, name: 'Сентябрь' }, { id: 10, name: 'Октябрь' }, { id: 11, name: 'Ноябрь' }, { id: 12, name: 'Декабрь' }];

	canEditForm: boolean;
	isNew: boolean = false;
	manualActionId: string;
	manualAction: ManualAction;
	monthYearDate: Date;
	requestsToAgree: KeyValueObject[] = [];
	saveInProgress: boolean = false;

	employees: Employee[] = [];
	manualActionTypes: any[] = [{ Id: 1, Name: 'Штраф' }, { Id: 2, Name: 'Не регламентное время' }, { Id: 3, Name: 'Премия' }];
	manualActionReports: any[] = [{ Id: 1, Name: 'Расчет переработок в будни дни' }, { Id: 2, Name: 'Расчет переработок в выходные дни' }, { Id: 3, Name: 'Отчет к оплате по заявкам на согласование' }];

	public dropDownFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
	};

	
	public get requestToAgreeDefaultItem(): any {
        return {
            id: null,
            name: '(Не выбрана)'
        };
    }
	
	private employeeFilterValueSubject: Subject<string> = new Subject();

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private manualActionsService: ManualActionsService,
		private requestToAgreeService: RequestToAgreeService,
		private notificationService: NotificationService,
		private employeeService: EmployeeService) {
			this.appService.pageTitle = "Новое действие";
		}

	ngOnInit() {

		this.employeeFilter("");

		this.employeeFilterValueSubject.pipe(debounceTime(400)).subscribe(value => {
			this.employeeFilter(value);
		});

		this.manualActionId = this.route.snapshot.paramMap.get('manualActionId');

		if (this.manualActionId != null){

			this.manualActionsService.getById(+this.manualActionId).subscribe(r => {
				this.dataBind(r.data);
				this.loadRequestsForManualAction(this.manualAction.year, this.manualAction.month, this.manualAction.employeeId);

				this.appService.pageTitle = `Ручное действие`;
			});

		} else {

			this.manualAction = new ManualAction();
			this.manualAction.createdDate = new Date();
			this.manualAction.month = this.manualAction.createdDate.getMonth();
			this.manualAction.year = this.manualAction.createdDate.getFullYear()
			this.manualAction.manualActionType = 1;
			this.manualAction.entityStatus = 1;
			this.manualAction.entityStatusString = "Активен";
			this.manualAction.comment = "";

			this.dataBind(this.manualAction);
			this.canEditForm = true;
			this.isNew = true;
		}

	}

	dataBind(manualAction: ManualAction) {
		this.manualAction = manualAction;
	}

	loadRequestsForManualAction(year: number, month: number, employeeId: number) {

		this.requestToAgreeService
			.getRequestsForManualAction(this.manualAction.year, this.manualAction.month, this.manualAction.employeeId)
			.subscribe(res => {
				this.requestsToAgree = res.data;
			});

	}

	employeeChange(employeeId: number){
		this.loadRequestsForManualAction(this.manualAction.year, this.manualAction.month, employeeId);
	}

	monthChange(monthNumber: number){
		this.loadRequestsForManualAction(this.manualAction.year, monthNumber, this.manualAction.employeeId);
	}

	yearChange(year: number){
		this.loadRequestsForManualAction(year, this.manualAction.month, this.manualAction.employeeId);
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.saveInProgress = true;

		if (this.isNew){

			this.manualActionsService.isEmployeeHasRequestsToAgreeForWeekends(this.manualAction.employeeId, this.manualAction.month, this.manualAction.year).subscribe(r => {

				if (this.manualAction.forReport === 2 && r.isSuccessful && !r.data){

					var message = `Для этого сотрудника нет заявки на согласование с выходом в выходные дни в заданный период. Данный ${this.manualActionTypeString.toLocaleLowerCase()} не попадёт в отчёт`;
					
					this.notificationService.info({
						title: this.manualActionTypeString,
						message: message,
						notificationType: NotificationType.SweetAlert
					}, () => {
						this.upsert(message);
					}, () => {
						this.upsert(message);
					});

				} else{
					this.upsert();
				}
				
			});

		} else{
			this.upsert();
		}

	}

	upsert(additionComment: string = null) {

		if (additionComment){
			this.manualAction.comment = this.manualAction.comment + additionComment;
		}

		this.manualActionsService.upsert(this.manualAction).subscribe(r => {

			this.saveInProgress = true;

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.manualActionForm);
				this.canEditForm = false;
				this.isNew = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: this.appService.pageTitle, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: this.appService.pageTitle, notificationType: NotificationType.Toast});
			}

			this.saveInProgress = false;
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: this.appService.pageTitle,
			message: `Вы уверены что хотите удалить действие?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.manualActionsService.remove(this.manualAction.manualActionId).subscribe(r => {

				this.router.navigate(['/documents/manual-actions']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Действие успешно удалено`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении действия`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.appService.pageTitle}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.manualActionsService.archive(this.manualAction.manualActionId).subscribe(r => {

				this.router.navigate(['/documents/manual-actions']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}


	handleEmployeeFilter(value) {
		this.employeeFilterValueSubject.next(value);
	}

	employeeFilter(value: string){

		this.employeesState.filter.filters = [];

		this.employeesState.filter.filters.push({ field: "entityStatus", operator: "eq", value: 1 });
		
		if (value !== "") {
			this.employeesState.filter.filters.push({
				field: 'Surname',
				operator: 'contains',
				value: value,
				ignoreCase: true
			});
		}

		this.employeeService.list(this.employeesState).subscribe(data => {
			return this.employees = data.data;
		});
	}

	get manualActionTypeString(): string {

		if (!this.manualAction)
			return "";

		var filtered = this.manualActionTypes.filter(x => x.Id == this.manualAction.manualActionType);

		if (filtered.length === 0)
			return "";

		return filtered[0].Name;
	}

}