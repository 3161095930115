import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Employee } from '../../shared/models/Employee/Employee';
import { EmployeeCalendar } from '../../shared/models/Employee/employee-calendar';
import { DataService } from '../../core/services/data.service';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { NotificationService } from '../../core/services/notification.service';
import { NotificationType } from '../../core/services/notification-type';
import { tap } from 'rxjs/operators';

@Injectable()
export class EmployeeService extends DataService<Employee> {
	
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "employees";
	}

	public cloneEmployee(employeeId: number): Observable<ActionDataResult<number>> {
		return this.http.post<ActionDataResult<number>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/clone/${employeeId}`, null);
	}

	public getCalendar(employeeId: number): Observable<ActionDataResult<EmployeeCalendar>> {
		return this.http.get<ActionDataResult<EmployeeCalendar>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/${employeeId}/calendar`)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}
			
		}));
	}
}