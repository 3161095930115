import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { Employee } from '../../../shared/models/Employee/Employee';
import { EmployeeService } from '../../../shared/services/employee.service';
import { TimeSchedule } from '../../../shared/models/TimeSchedule/TimeSchedule';
import { EmployeeCategory } from '../../../shared/models/EmployeeCategory/EmployeeCategory';
import { MovementType } from '../../../shared/models/MovementType/MovementType';
import { EmployeeCategoriesService } from '../../../shared/services/employee-categories.service';
import { MovementTypesService } from '../../../shared/services/movement-types.service';
import { TimeScheduleService } from '../../../shared/services/time-schedule.service';
import { DataSourceRequestState, SortDescriptor } from '@progress/kendo-data-query';
import { EmployeeCustomTimeSchedule } from '../../../shared/models/Employee/EmployeeCustomTimeSchedule';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { PositionService } from '../../../shared/services/position.service';
import { OrgUnitService } from '../../../shared/services/org-unit.service';
import { Position } from '../../../shared/models/position/position';
import { OrgUnit } from '../../../shared/models/org-unit/org-unit';
import { EmployeeSalary } from '../../../shared/models/Employee/employee-salary';
import { User } from '../../../shared/models/User/User';
import { UsersService } from '../../../settings/users/users.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
	selector: 'catalogs-employee',
	templateUrl: './employee.page.html',
	styleUrls: [
		'./employee.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class EmployeePage implements OnInit, TrackChangesComponent {
	
	@ViewChild('employeeForm') public employeeForm: NgForm;

	hasChanges(): boolean{
		return !this.employeeForm.pristine;
	};

	canEditForm: boolean;
	saveInProgress: boolean = false;
	employeeId: string;
	employee: Employee;

	timeSchedules: TimeSchedule[] = [];
	employeeCategories: EmployeeCategory[] = [];
	movementTypes: MovementType[] = [];
	positions: Position[] = [];
	orgUnits: OrgUnit[] = [];
	users: User[] = [];
	isNew: boolean = false;

	public userToAddDefaultItem: { displayName: string, userId: number } = { displayName: "(Выберите пользователя)", userId: null };

	public customScheduleGridSort: SortDescriptor[] = [{ field: 'dateFrom', dir: 'asc' }];
	public employeeSalariesGridSort: SortDescriptor[] = [{ field: 'startsFrom', dir: 'desc' }];

	dayTypes: any[] = [{ Id: 1, Name: 'Рабочие' }, { Id: 2, Name: 'Выходные' }, { Id: 5, Name: 'Отпуск' }, { Id: 6, Name: 'Отгулы' }];

	cloneInProgress: boolean = false;

	public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

	emailPattern: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;

	constructor(
			private appService: AppService,
			private route: ActivatedRoute,
			private router: Router,
			private notificationService: NotificationService,
			private employeeService: EmployeeService,
			private employeeCategoriesService: EmployeeCategoriesService,
			private movementTypesService: MovementTypesService,
			private timeScheduleService: TimeScheduleService,
			private orgUnitService: OrgUnitService,
			private positionService: PositionService,
			private usersService: UsersService
		) {
			this.appService.pageTitle = "Новый сотрудник";
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		}

	ngOnInit() {

		this.loadDropDownsData();

		this.employeeId = this.route.snapshot.paramMap.get('employeeId');

		if (this.employeeId != null){

			this.employeeService.getById(+this.employeeId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = r.data.displayName;
			});

		} else {

			this.isNew = true;
			this.employee = new Employee();
			this.employee.createdDate = new Date();
			this.employee.startWorkDate = new Date();
			this.employee.entityStatusDate = new Date();
			this.employee.entityStatus = 1;
			this.employee.entityStatusString = "Активен";
			this.employee.canDelete = true;
			this.employee.includeToSalaryCalcReport = true;
			this.employee.canDelete = true;

			this.dataBind(this.employee);
			this.canEditForm = true;
		}

	}

	dataBind(employee: Employee){
		this.employee = employee;
		this.employee.startWorkDate = this.appService.parseDate(employee.startWorkDate);

		this.employee.employeeCustomTimeSchedules.forEach(x => {
			x.dateFrom =  this.appService.parseDate(x.dateFrom);
			x.dateTo = this.appService.parseDate(x.dateTo);
		});

		this.employee.employeeSalaries.forEach(x => {
			x.startsFrom =  this.appService.parseDate(x.startsFrom);
		});
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		if (!this.isNew) {
			this.saveChangesCore();
			return;
		}

		this.employeeService.list({
			skip: 0,
			take: 5,
			sort: [{ field: "surname" }],
			filter: {
				logic: "and",
				filters: [
					{ field: "surname", operator: "eq", value: this.employee.surname }
				]
			}
		}).subscribe(res => {
			if (res.total > 0){
				this.informAboutSameSurname(res.data);
			} else {
				this.saveChangesCore();
			}
		});

	}

	informAboutSameSurname(employees: Employee[]) {

		this.saveInProgress = true;

		var messageText = "";

		employees.forEach(x => {
			messageText += `<a href="/catalogs/employee/${x.employeeId}" target="_blank">${x.surname} ${x.name} ${x.middleName}</a>. Должность: ${(!!x.positionName ? x.positionName :  '-')}, Орг. единица: ${!!x.orgUnitName ? x.orgUnitName: '-'}<br /><br />`;
		});

		this.notificationService.confirmation({
            title: employees.length == 1 ? 'Внимание! Сотрудник с данной фамилией уже зарегистрирован!' : 'Внимание! Сотрудники с данной фамилией уже зарегистрированы!',
			message: messageText,
			type: "warning",
			confirmButtonText: "Все равно создать текущего",
            cancelButtonText: "Отмена",
            showCloseButton: false
        }, () => this.saveChangesCore(),
		() => this.saveInProgress = false);
	}

	saveChangesCore(): void {

		this.saveInProgress = true;

		this.employeeService.upsert(this.employee).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.employeeForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.employee.displayName} ${this.employee.name}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.employee.displayName}`, notificationType: NotificationType.Toast});
			}
			
			this.saveInProgress = false;
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.employee.name}`,
			message: `Вы уверены что хотите удалить сотрудника '${this.employee.displayName}'?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.employeeService.remove(this.employee.employeeId).subscribe(r => {

				this.router.navigate(['/catalogs/employees']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Сотрудник '${this.employee.displayName}' успешно удален`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении сотрудника`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.employee.displayName}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.employeeService.archive(this.employee.employeeId).subscribe(r => {

				this.router.navigate(['/catalogs/employees']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.employee.displayName}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	loadDropDownsData() {

		var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			sort: [{ field: "name", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		};

		this.timeScheduleService.list(state).subscribe(x => this.timeSchedules = x.data);
		this.employeeCategoriesService.list(state).subscribe(x => this.employeeCategories = x.data);
		this.movementTypesService.list(state).subscribe(x => this.movementTypes = x.data);

		this.positionService.list({
			skip: 0,
			take: null,
			sort: [{ field: "positionName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.subscribe(data => this.positions = data);

		this.orgUnitService.list({
			skip: 0,
			take: null,
			sort: [{ field: "orgUnitName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.subscribe(data => this.orgUnits = data);

		this.usersService.list({
			skip: 0,
			take: null,
			sort: [{ field: "surname", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.subscribe(data => this.users = data);
		
	}

	get createNewItem(): any {

		var date = this.appService.parseDate(new Date());

		return function() {
			var ects = new EmployeeCustomTimeSchedule();
			ects.employeeCustomTimeScheduleId = 0;
			ects.rangeDaysType = 1;
			ects.allowEdit = true;
			ects.dateFrom = date;
			ects.dateTo =  date;
			return ects;
		};
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}
	
	public cellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 2 /*|| !dataItem.allowEdit*/)
			return;

        sender.editRow(rowIndex);
        
	}
	
	public saveHandler(obj: any) {
		// var addingDay = obj.dataItem.day;
		
		// var filtered = this.prodCalendar.days.filter(x => isEqualDate(x.day, addingDay));

		// obj.dataItem.day = this.appService.parseDate(obj.dataItem.day);

		// if (filtered.length > 0){

			// this.notificationService.error({
			// 	title: "Ошибка при добавлении даты",
			// 	message: `Данный промежуток дат уже участвует в активном расчете переработок`,
			// 	notificationType: NotificationType.SweetAlert
			// });

			// obj.dataItem = null;

		// }

		obj.dataItem.dateFrom = this.appService.parseDate(obj.dataItem.dateFrom);
		obj.dataItem.dateTo = this.appService.parseDate(obj.dataItem.dateTo);
			

		this.appService.MarkFormDirty(this.employeeForm);
	}

	public removeHandler(obj: any) {

		if (obj.dataItem.employeeCustomTimeScheduleId === 0)
			return;

		var employeeCustomTimeScheduleIdToDelete = obj.dataItem.employeeCustomTimeScheduleId;

		this.notificationService.confirmation({
			title: `Вы уверены что хотите удалить период?`,
			message: `Удаление приведет к изменению рассчитанных значений выплат(если они были)`,
			type: "question",
			confirmButtonText: "Да",
			cancelButtonText: "Нет",
			showCloseButton: true
		}, () => {

			this.employee.employeeCustomTimeSchedules = this.employee.employeeCustomTimeSchedules.filter(s => s.employeeCustomTimeScheduleId !== employeeCustomTimeScheduleIdToDelete);
			this.appService.MarkFormDirty(this.employeeForm);

		});

		obj.dataItem = null;
		

	}
	
	getDayTypeString(dayType: number){
		var filtered = this.dayTypes.filter(x => x.Id == dayType);

		if (filtered.length === 0)
			return "";

		return filtered[0].Name;
	}

	get createNewEmployeeSalary(): any {

		var date = this.appService.parseDate(new Date());

		return function() {
			var es = new EmployeeSalary();
			es.startsFrom = date;
			es.amount = 0;
			return es;
		};
	}

	public employeeSalariesCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 1 || !dataItem.allowEdit)
			return;

        sender.editRow(rowIndex);
        
	}

	public employeeSalariesSaveHandler(obj: any) {
		obj.dataItem.startsFrom = this.appService.parseDate(obj.dataItem.startsFrom);
		this.appService.MarkFormDirty(this.employeeForm);
	}

	public employeeSalariesRemoveHandler(obj: any) {
		this.appService.MarkFormDirty(this.employeeForm);
	}

	cloneEmployee(){

		this.cloneInProgress = true;

		this.employeeService.cloneEmployee(this.employee.employeeId).subscribe(r => {

			this.cloneInProgress = false;

			if (r.isSuccessful){
				this.notificationService.success({message: "Сотрудник успешно создан!", title: `${this.employee.displayName} ${this.employee.name}`, notificationType: NotificationType.Toast});
				this.router.navigate(['/catalogs/employee', r.data]);
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.employee.displayName}`, notificationType: NotificationType.Toast});
			}
			
		});

	}


	get createNewUser(): any {

		return function() {
			var user = new User();
			return user;
		};
	}

	public employeeUserCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm)
			return;

        sender.editRow(rowIndex);
        
	}

	public employeeUserSaveHandler(obj: any) {

		if (this.employee.employeeLogins.some(x => x.userId == obj.dataItem.userId))
		{
			obj.dataItem = null;
			return;
		}

		obj.dataItem.startsFrom = this.appService.parseDate(obj.dataItem.startsFrom);
		this.appService.MarkFormDirty(this.employeeForm);
	}

	public employeeUserRemoveHandler(obj: any) {
		this.appService.MarkFormDirty(this.employeeForm);
	}

	getUserDisplayName(userId: number){
		var filtered = this.users.filter(x => x.userId == userId);

		if (filtered.length === 0)
			return "";

		return filtered[0].displayName;
	}

	get selectedPositionHasEmployeeCategory(): boolean {
		
		if (!this.employee.positionId)
			return false;

		var filtered = this.positions.filter(x => x.positionId == this.employee.positionId);

		if (filtered.length === 0)
			return false;

		return filtered[0].hasEmployeeCategory;
	}

	getTimeSchedulesById(timeScheduleId: number){
		var filtered = this.timeSchedules.filter(x => x.timeScheduleId == timeScheduleId);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}

}