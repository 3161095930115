import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { Customer } from '../models/Customer/Customer';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class CustomerService extends DataService<Customer> {
	
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "customers";
	}

}