import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { ServiceCenter } from '../models/ServiceCenter/ServiceCenter';

@Injectable()
export class ServiceCenterService extends DataService<ServiceCenter> {
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "serviceCenter";
	}
}