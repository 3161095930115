import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// *******************************************************************************
// Layouts

import { LayoutComponent } from './layout/layout/layout.component';
import { PageNotFoundPage } from './layout/errors/PageNotFound/PageNotFound.page';

// *******************************************************************************
// Pages

import { AuthGuard } from './core/guards/auth.guard';
import { CoreModule } from './core/core.module';
import { loginRoutes } from './login/login-routing.module';
import { homeRoutes } from './home/home-routing.module';
import { settingsRoutes } from './settings/settings-routing.module';
import { catalogsRoutes } from './catalogs/catalogs-routing.module';
import { documentsRoutes } from './documents/documents-routing.module';
import { reportsRoutes } from './reports/reports-routing.module';

// *******************************************************************************
// Routes

const routes: Routes = [
	{ path: 'login', children: loginRoutes },
	{
		path: '',
		component: LayoutComponent,
		children: [
			{ path: '', children: homeRoutes, canActivate: [AuthGuard]},
			{ path: 'settings', children: settingsRoutes },
			{ path: 'catalogs', children: catalogsRoutes },
			{ path: 'documents', children: documentsRoutes },
			{ path: 'reports', children: reportsRoutes },
			{path: '**', component: PageNotFoundPage, canActivate: [AuthGuard]}
		],
		
	}
];

@NgModule({
	imports: [
		CoreModule,
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }