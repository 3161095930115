import { BaseObject } from "../core/BaseObject";

export class MaintainObject extends BaseObject {
    maintainObjectId: number;
    objectCode: string;

    classifierId: number;

    address: string;
    postCode: string;
    district: string;
    city: string;
    street: number;
    house: string;
    block: string;
    // nearestMetroStation: string;
    roadType: number;
    beltwayDistance: number;
    latitude: number;
    longitude: number;

    classifierName: string;
}