import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { Position } from '../models/position/position';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class PositionService extends DataService<Position> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "positions";
	}

}