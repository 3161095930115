import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../../core/services/notification.service';
import { NotificationType } from '../../../../core/services/notification-type';
import { AppService } from '../../../../app.service';
import { MailingGroup } from '../../../../shared/models/mailings/mailing-group';
import { MailingGroupsService } from '../../../../shared/services/mailing-groups.service';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { Employee } from '../../../../shared/models/Employee/Employee';
import { EmployeeService } from '../../../../shared/services/employee.service';
import { MailingGroupEmployee } from '../../../../shared/models/mailings/mailing-group-employee';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { MailingRegister } from '../../../../shared/models/mailings/mailing-register';
import { MailingRegistersService } from '../../../../shared/services/mailing-registers.service';
import { SalaryCalcReportService } from '../../../../shared/services/salary-calc-report.service';
import { AnalyticReportService } from '../../../../shared/services/analytic-report.service';
import { RequestToAgreeReportService } from '../../../../shared/services/request-to-agree-report.service';
import { SalaryCalcReport } from '../../../../shared/models/Reports/SalaryCalc/SalaryCalcReport';
import { AnalyticReport } from '../../../../shared/models/Reports/Analytic/AnalyticReport';
import { RequestToAgreeReport } from '../../../../shared/models/Reports/RequestToAgree/request-to-agree-report';
import { MailingRegisterReport } from '../../../../shared/models/mailings/mailing-register-report';

@Component({
	selector: 'settings-mailing-register',
	templateUrl: './mailing-register.page.html',
	styleUrls: ['./mailing-register.page.scss']
})
export class MailingRegisterPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('mailingRegisterForm') public mailingRegisterForm: NgForm;

	hasChanges(): boolean{
		return !this.mailingRegisterForm.pristine;
	};

	months = [{ id: 1, name: 'Январь' }, { id: 2, name: 'Февраль' }, { id: 3, name: 'Март' }, { id: 4, name: 'Апрель' }, { id: 5, name: 'Май' }, { id: 6, name: 'Июнь' }, { id: 7, name: 'Июль' }, { id: 8, name: 'Август' }, { id: 9, name: 'Сентябрь' }, { id: 10, name: 'Октябрь' }, { id: 11, name: 'Ноябрь' }, { id: 12, name: 'Декабрь' }];

	isNew: boolean;
	canEditForm: boolean;
	mailingRegisterId: string;
	mailingRegister: MailingRegister;

	salaryCalcWorkdays: SalaryCalcReport[] = [];
	salaryCalcWeekends: SalaryCalcReport[] = [];

	analyticReportsWorkdays: AnalyticReport[] = [];
	analyticReportsWeekends: AnalyticReport[] = [];

	requestToAgreeReports: RequestToAgreeReport[] = [];

	mailingGroups: MailingGroup[] = [];

	mailingGroupState: DataSourceRequestState = {
		skip: 0,
		take: null,
		sort: [{ field: "name", dir: "asc" }],
		filter: {
			logic: "and",
			filters: [
				{ field: "entityStatus", operator: "eq", value: 1 }
			]
		}
	};

	reportsState: DataSourceRequestState = {
		skip: 0,
		take: null,
		sort: [{ field: "year", dir: "desc" }, { field: "month", dir: "desc" }],
		filter: {
			logic: "and",
			filters: [
				{ field: "entityStatus", operator: "eq", value: 1 }
			]
		}
	};

	public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

	public employeeToAddDefaultItem: { displayName: string, employeeId: number } = { displayName: "(Выберите сотрудника)", employeeId: null };

	reportTypes: any[] = [
		{ id: 1, name: 'Переработки (рабочие дни)' },
		{ id: 2, name: 'Переработки (выходные дни)' },
		{ id: 3, name: 'Отчет аналитика (рабочие дни)' },
		{ id: 4, name: 'Отчет аналитика (выходные дни)' },
		{ id: 5, name: 'Отчеты к оплате по заявкам на согласование' },
	];

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private employeeService: EmployeeService,
		private mailingGroupsService: MailingGroupsService,
		private mailingRegistersService: MailingRegistersService,
		private salaryCalcReportService: SalaryCalcReportService,
		private analyticReportService: AnalyticReportService,
		private requestToAgreeReportService: RequestToAgreeReportService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новое отправление";
		}

	ngOnInit() {

		this.mailingGroupsService.list(this.mailingGroupState).subscribe(data => {
			this.mailingGroups = data.data;
		});

		this.salaryCalcReportService.setKind(false);

		this.salaryCalcReportService.list(this.reportsState).subscribe(data => {
			this.salaryCalcWorkdays = data.data;
			this.salaryCalcWorkdays.forEach(x => x.name = this.getReportName(x));
		});

		this.salaryCalcReportService.setKind(true);

		this.salaryCalcReportService.list(this.reportsState).subscribe(data => {
			this.salaryCalcWeekends = data.data;
			this.salaryCalcWeekends.forEach(x => x.name = this.getReportName(x));
		});

		this.analyticReportService.setKind(false);

		this.analyticReportService.list(this.reportsState).subscribe(data => {
			this.analyticReportsWorkdays = data.data;
			this.analyticReportsWorkdays.forEach(x => x.name = this.getReportName(x));
		});

		this.analyticReportService.setKind(true);

		this.analyticReportService.list(this.reportsState).subscribe(data => {
			this.analyticReportsWeekends = data.data;
			this.analyticReportsWeekends.forEach(x => x.name = this.getReportName(x));
		});

		this.requestToAgreeReportService.list(this.reportsState).subscribe(data => {
			this.requestToAgreeReports = data.data;
			this.requestToAgreeReports.forEach(x => x.name = this.getReportName(x));
		});

		this.mailingRegisterId = this.route.snapshot.paramMap.get('mailingRegisterId');

		if (this.mailingRegisterId != null) {

			this.mailingRegistersService.getById(+this.mailingRegisterId).subscribe(r => {
				this.dataBind(r.data);
			});

		} else {

			this.mailingRegister = new MailingRegister();
			this.mailingRegister.createdDate = new Date();
			this.mailingRegister.entityStatus = 1;
			this.mailingRegister.entityStatusString = "Активен";

			this.dataBind(this.mailingRegister);

			this.canEditForm = true;
			this.isNew = true;
		}

	}

	dataBind(mailingRegister: MailingRegister){
		this.mailingRegister = mailingRegister;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.mailingRegistersService.upsert(this.mailingRegister).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.mailingRegisterForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.mailingRegister.mailingGroupName}`, notificationType: NotificationType.Toast});

				this.router.navigate(['/settings/mailing-registers']);
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.mailingRegister.mailingGroupName}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	get createNewReport(): any {

		return function() {
			var mailingRegisterReport = new MailingRegisterReport();
			mailingRegisterReport.reportType = 1;
			return mailingRegisterReport;
		};
	}

	public reportCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm)
			return;

        sender.editRow(rowIndex);
	}

	public reportSaveHandler(obj: any) {
		this.appService.MarkFormDirty(this.mailingRegisterForm);
	}

	public removeReportHandler(obj: any) {
		this.appService.MarkFormDirty(this.mailingRegisterForm);
	}

	public reportTypeChange(dataItem: MailingRegisterReport): void {
		dataItem.salaryCalcReportId = null;
		dataItem.analyticReportId = null;
		dataItem.requestToAgreeReportId = null;
	}

	getReportTypeName(dataItem: MailingRegisterReport) {

		var filtered = this.reportTypes.filter(x => x.id === dataItem.reportType);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}

	getReportName(reportItem: any) {

		var monthName = this.months.filter(x => x.id === reportItem.month);

		return `${monthName.length > 0 ? monthName[0].name : reportItem.month} ${reportItem.year}`;
	}

	getReportItemName(dataItem: MailingRegisterReport) {

		var filtered = [];

		switch (dataItem.reportType) {
			case 1:
				filtered = this.salaryCalcWorkdays.filter(x => x.salaryCalcReportId == dataItem.salaryCalcReportId);
				break;
			case 2:
				filtered = this.salaryCalcWeekends.filter(x => x.salaryCalcReportId == dataItem.salaryCalcReportId);
				break;
			case 3:
				filtered = this.analyticReportsWorkdays.filter(x => x.analyticReportId == dataItem.analyticReportId);
				break;
			case 4:
				filtered = this.analyticReportsWeekends.filter(x => x.analyticReportId == dataItem.analyticReportId);
				break;
			case 5:
				filtered = this.requestToAgreeReports.filter(x => x.requestToAgreeReportId == dataItem.requestToAgreeReportId);
				break;
		
			default:
				break;
		}

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}


}