import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';

import { RolesListPage } from './roles/list/list.page';
import { RolePage } from './roles/role/role.page';
import { RolesService } from './roles/roles.service';

import { UsersListPage } from './users/list/list.page';
import { UserPage } from './users/user/user.page';
import { UsersService } from './users/users.service';
import { ProdCalendarService } from './prod-calendar/prod-calendar.service';
import { ProdCalendarListPage } from './prod-calendar/list/list.page';
import { ProdCalendarPage } from './prod-calendar/prod-calendar/prod-calendar.page';
import { TimeScheduleService } from '../shared/services/time-schedule.service';
import { TimeScheduleListPage } from './time-schedules/list/list.page';
import { TimeSchedulePage } from './time-schedules/time-schedule/time-schedule.page';
import { FactorService } from './factor/factor.service';
import { FactorListPage } from './factor/list/list.page';
import { FactorSetPage } from './factor/factor-set/factor-set.page';
import { DistanceExtraPaysService } from './distance-extra-pays/distance-extra-pays.service';
import { DistanceExtraPayListPage } from './distance-extra-pays/list/list.page';
import { DistanceExtraPayPage } from './distance-extra-pays/distance-extra-pay/distance-extra-pay.page';
import { EmployeeCategoryListPage } from './employee-categories/list/list.page';
import { EmployeeCategoryPage } from './employee-categories/employee-category/employee-category.page';
import { MovementTypeListPage } from './movement-types/list/list.page';
import { MovementTypePage } from './movement-types/movement-type/movement-type.page';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { MailingGroupsListPage } from './mailings/mailing-groups/list/list.page';
import { MailingGroupPage } from './mailings/mailing-groups/mailing-group/mailing-group.page';
import { MailingRegistersListPage } from './mailings/mailing-registers/list/list.page';
import { MailingRegisterPage } from './mailings/mailing-registers/mailing-register/mailing-register.page';

@NgModule({
	declarations: [
		RolesListPage,
		RolePage,

		UsersListPage,
		UserPage,

		ProdCalendarListPage,
		ProdCalendarPage,

		TimeScheduleListPage,
		TimeSchedulePage,

		FactorListPage,
		FactorSetPage,

		DistanceExtraPayListPage,
		DistanceExtraPayPage,

		EmployeeCategoryListPage,
		EmployeeCategoryPage,

		MovementTypeListPage,
		MovementTypePage,

		MailingGroupsListPage,
		MailingGroupPage,

		MailingRegistersListPage,
		MailingRegisterPage,

	],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		RouterModule,
		PasswordStrengthBarModule,
		ExcelModule
	],
	providers: [
		RolesService,
		UsersService,
		ProdCalendarService,
		TimeScheduleService,
		FactorService,
		DistanceExtraPaysService
	]
})
export class SettingsModule { }
