import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { Customer } from '../../../shared/models/Customer/Customer';
import { CustomerService } from '../../../shared/services/customer.service';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'catalogs-customers',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class CustomerListPage extends ListPageBase {

	public customers: ActionDataResult<Customer[]>;

	constructor(
		appService: AppService,
	    router: Router,
		filterStorage: FilterStorage,
		private customerService: CustomerService,
		private notificationService: NotificationService) {
		super(appService, router, filterStorage, customerService);	
		this.appService.pageTitle = "Клиенты";
	}

	loadData(): void {
		super.loadData();

		this.customerService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.customers = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/catalogs/customer', dataItem.customerId]);
	}
}