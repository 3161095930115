import { Component, Input } from '@angular/core';
import { EmployeePeriod } from '../../models/Reports/employee-period';
import { SelectAllCheckboxState, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { EmployeeService } from '../../services/employee.service';
// import * as uuid from 'uuid';
// uuid.v4();

@Component({
	selector: 'employee-periods',
	templateUrl: './employee-periods.component.html',
	styleUrls: ['./employee-periods.component.scss']
})
export class EmployeePeriodsComponent {

	constructor(
		public employeeService: EmployeeService
	) { }

	@Input()
	gridData: EmployeePeriod[] = [];

	get selectedEmployeePeriod(): EmployeePeriod[]{
		return this.gridData.filter(x => this.userSelectionIds.some(selectedId => x.employeePeriodId == selectedId));
	}

    public userSelectionIds: string[] = [];
	public selectAllState: SelectAllCheckboxState = 'checked';
	
	public onSelectedKeysChange(e) {
        const len = this.userSelectionIds.length;

        if (len === 0) {
            this.selectAllState = 'unchecked';
        } else if (len > 0 && len < this.userSelectionIds.length) {
            this.selectAllState = 'indeterminate';
        } else {
            this.selectAllState = 'checked';
        }
    }

    public onSelectAllChange(checkedState: SelectAllCheckboxState) {
        if (checkedState === 'checked') {
            this.userSelectionIds = this.gridData.map(x => x.employeePeriodId);
            this.selectAllState = 'checked';
        } else {
            this.userSelectionIds = [];
            this.selectAllState = 'unchecked';
        }
	}
	
	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}
}
