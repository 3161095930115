import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../../core/services/notification.service';
import { NotificationType } from '../../../../core/services/notification-type';
import { AppService } from '../../../../app.service';
import { MailingGroup } from '../../../../shared/models/mailings/mailing-group';
import { MailingGroupsService } from '../../../../shared/services/mailing-groups.service';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { Employee } from '../../../../shared/models/Employee/Employee';
import { EmployeeService } from '../../../../shared/services/employee.service';
import { MailingGroupEmployee } from '../../../../shared/models/mailings/mailing-group-employee';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { DataSourceRequestState } from '@progress/kendo-data-query';

@Component({
	selector: 'settings-mailing-group',
	templateUrl: './mailing-group.page.html',
	styleUrls: ['./mailing-group.page.scss']
})
export class MailingGroupPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('mailingGroupForm') public mailingGroupForm: NgForm;

	hasChanges(): boolean{
		return !this.mailingGroupForm.pristine;
	};

	canEditForm: boolean;
	mailingGroupId: string;
	mailingGroup: MailingGroup;

	employees: Employee[] = [];

	employeesState: DataSourceRequestState = {
		skip: 0,
		take: null,
		sort: [{ field: "displayName", dir: "asc" }],
		filter: {
			logic: "and",
			filters: [
				{ field: "entityStatus", operator: "eq", value: 1 }
			]
		}
	};

	public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

	public employeeToAddDefaultItem: { displayName: string, employeeId: number } = { displayName: "(Выберите сотрудника)", employeeId: null };

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private employeeService: EmployeeService,
		private mailingGroupsService: MailingGroupsService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новая группа";
		}

	ngOnInit() {

		this.employeeService.list(this.employeesState).subscribe(data => {
			this.employees = data.data;
		});

		this.mailingGroupId = this.route.snapshot.paramMap.get('mailingGroupId');

		if (this.mailingGroupId != null) {

			this.mailingGroupsService.getById(+this.mailingGroupId).subscribe(r => {
				this.dataBind(r.data);
			});

		} else {

			this.mailingGroup = new MailingGroup();
			this.mailingGroup.createdDate = new Date();
			this.mailingGroup.entityStatus = 1;
			this.mailingGroup.entityStatusString = "Активен";

			this.dataBind(this.mailingGroup);

			this.canEditForm = true;
		}

	}

	dataBind(mailingGroup: MailingGroup){
		this.mailingGroup = mailingGroup;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.mailingGroupsService.upsert(this.mailingGroup).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.mailingGroupForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.mailingGroup.name}`, notificationType: NotificationType.Toast});

				this.router.navigate(['/settings/mailing-groups']);

			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.mailingGroup.name}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.mailingGroup.name}`,
			message: `Вы уверены что хотите удалить группу '${this.mailingGroup.name}'?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.mailingGroupsService.remove(this.mailingGroup.mailingGroupId).subscribe(r => {

				this.router.navigate(['/settings/mailing-groups']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Группа '${this.mailingGroup.name}' успешно удалена`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении группы ${this.mailingGroup.name}`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.mailingGroup.name}`,
			message: "Вы уверены что хотите архивировать данную группу?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.mailingGroupsService.archive(this.mailingGroup.mailingGroupId).subscribe(r => {

				this.router.navigate(['/settings/mailing-groups']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Группа '${this.mailingGroup.name}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании группы`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	get createNewEmployee(): any {

		return function() {
			var userEmployee = new MailingGroupEmployee();
			return userEmployee;
		};
	}

	public cellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 1)
			return;

        sender.editRow(rowIndex);
        
	}

	public saveHandler(obj: any) {
		this.appService.MarkFormDirty(this.mailingGroupForm);
	}

	public removeHandler(obj: any) {
        this.appService.MarkFormDirty(this.mailingGroupForm);
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}

	getEmployeeDisplayName(empoyeeId: number){

		var filtered = this.employees.filter(x => x.employeeId == empoyeeId);

		if (filtered.length === 0)
			return "";

		return filtered[0].displayName;
	}

}