import { Component, Input } from '@angular/core';

@Component({
	selector: 'loading-indicator',
	templateUrl: './loading-indicator.component.html',
	styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {

	constructor() { }

	@Input()
	isLoading: boolean = false;

	@Input()
	size: number;
}
