import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { AnalyticReport } from '../../../shared/models/Reports/Analytic/AnalyticReport';
import { AnalyticReportService } from '../../../shared/services/analytic-report.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'reports-analytic-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class AnalyticReportListPage extends ListPageBase {

	isForWeekends: boolean = false;
	newItemUrl: string = "/reports/analytic/workdays/new";
	pageHeader: string = "Рабочие дни";

	public reports: ActionDataResult<AnalyticReport[]>;

	public months: any[] = [ { id: 1, name: 'Январь' }, { id: 2, name: 'Февраль' }, {id: 3, name: 'Март'} , { id: 4, name: 'Апрель' }, { id: 5, name: 'Май' }, { id: 6, name: 'Июнь' }, {id: 7, name: 'Июль'}, { id: 8, name: 'Август'}, { id: 9, name: 'Сентябрь' }, { id: 10, name: 'Октябрь' }, { id: 11, name: 'Ноябрь' }, { id: 12, name: 'Декабрь' }];

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private route: ActivatedRoute,
		private analyticReportService: AnalyticReportService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, analyticReportService);

		this.appService.pageTitle = "Отчеты аналитика в рабочие дни";
	}

	loadData(): void {
		super.loadData();

		this.isForWeekends = <boolean>this.route.snapshot.data.isForWeekends;

		if (this.isForWeekends){
			this.appService.pageTitle = "Отчеты аналитика в выходные дни";
			this.newItemUrl = "/reports/analytic/weekends/new";
			this.pageHeader = "Выходные дни";
		}

		this.analyticReportService.setKind(this.isForWeekends);

		this.analyticReportService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.reports = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate([`/reports/analytic/${ this.isForWeekends ? 'weekends' : 'workdays' }`, dataItem.analyticReportId]);
	}

	getMonthName(monthNumber: number){

		if (!monthNumber)
			return "";

		var filtered = this.months.filter(x => x.id == monthNumber);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}
}