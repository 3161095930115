import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { EmployeeService } from '../../../shared/services/employee.service';
import { ManualActionsService } from '../../../shared/services/manual-actions.service';
import { ManualAction } from '../../../shared/models/ManualAction/ManualAction';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { createDate } from '@progress/kendo-date-math/dist/npm/create-date';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'documents-manual-actions',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class ManualActionListPage extends ListPageBase implements OnInit {

	public manualActions: ActionDataResult<ManualAction[]>;

	manualActionTypes: KeyValueObject[] = [{ id: 1, name: 'Штраф', entityStatusString: "" }, { id: 2, name: 'Не регламентное время', entityStatusString: "" }, { id: 3, name: 'Премия', entityStatusString: "" }];
	//measureUnits: KeyValueObject[] = [{ id: 1, name: 'Рубли', entityStatusString: "" }, { id: 2, name: 'Часы', entityStatusString: "" }, { id: 3, name: 'Рубли', entityStatusString: "" }];
	public months: any[] = [ { id: 1, name: 'Январь' }, { id: 2, name: 'Февраль' }, {id: 3, name: 'Март'} , { id: 4, name: 'Апрель' }, { id: 5, name: 'Май' }, { id: 6, name: 'Июнь' }, {id: 7, name: 'Июль'}, { id: 8, name: 'Август'}, { id: 9, name: 'Сентябрь' }, { id: 10, name: 'Октябрь' }, { id: 11, name: 'Ноябрь' }, { id: 12, name: 'Декабрь' }];

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private manualActionsService: ManualActionsService,
		public employeeService: EmployeeService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, manualActionsService);

		this.appService.pageTitle = "Ручные действия";
	}

    ngOnInit(): void {
		if (this.appService.OuterFilterRoute === this.router.routerState.snapshot.url) {
			this.state = this.appService.OuterFilter;

			this.appService.OuterFilterRoute = null;
			this.appService.OuterFilter = null;
		}

        super.ngOnInit();
	}

	loadData(): void {
		super.loadData();

		this.manualActionsService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.manualActions = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/documents/manual-action', dataItem.manualActionId]);
	}

	getMonthName(monthNumber: number){

		if (!monthNumber)
			return "";

		var filtered = this.months.filter(x => x.id == monthNumber);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}
}