import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { ProdCalendarService } from '../prod-calendar.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ProdCalendar } from '../../../shared/models/ProdCalendar/ProdCalendar';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'settings-prodCalendars',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class ProdCalendarListPage extends ListPageBase {

	public prodCalendars: ActionDataResult<ProdCalendar[]>;

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private prodCalendarService: ProdCalendarService,
		private notificationService: NotificationService) {

		super(appService, router, filterStorage, prodCalendarService);

		this.appService.pageTitle = "Производственный календарь";
	}

	loadData(): void {
		super.loadData();

		this.prodCalendarService.list(this.state).subscribe(data => {
			this.loading = false;
			return this.prodCalendars = data;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/settings/ProdCalendar', dataItem.prodCalendarId]);
	}
}