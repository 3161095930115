import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { Employee } from '../../../shared/models/Employee/Employee';
import { EmployeeService } from '../../../shared/services/employee.service';
import { TimeSchedule } from '../../../shared/models/TimeSchedule/TimeSchedule';
import { EmployeeCategory } from '../../../shared/models/EmployeeCategory/EmployeeCategory';
import { MovementType } from '../../../shared/models/MovementType/MovementType';
import { EmployeeCategoriesService } from '../../../shared/services/employee-categories.service';
import { MovementTypesService } from '../../../shared/services/movement-types.service';
import { TimeScheduleService } from '../../../shared/services/time-schedule.service';
import { DataSourceRequestState, SortDescriptor } from '@progress/kendo-data-query';
import { EmployeeCustomTimeSchedule } from '../../../shared/models/Employee/EmployeeCustomTimeSchedule';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { Customer } from '../../../shared/models/Customer/Customer';
import { CustomerService } from '../../../shared/services/customer.service';

@Component({
	selector: 'catalogs-customer',
	templateUrl: './customer.page.html',
	styleUrls: ['./customer.page.scss']
})
export class CustomerPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('customerForm') public customerForm: NgForm;

	hasChanges(): boolean{
		return !this.customerForm.pristine;
	};

	canEditForm: boolean;
	customerId: string;
	customer: Customer;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private customerService: CustomerService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новый клиент";
		}

	ngOnInit() {

		this.customerId = this.route.snapshot.paramMap.get('customerId');

		if (this.customerId != null){

			this.customerService.getById(+this.customerId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = r.data.name;
			});

		} else {

			this.customer = new Customer();
			this.customer.createdDate = new Date();
			this.customer.entityStatus = 1;
			this.customer.entityStatusString = "Активен";

			this.dataBind(this.customer);
			this.canEditForm = true;
		}

	}

	dataBind(customer: Customer){
		this.customer = customer;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.customerService.upsert(this.customer).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.customerForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.customer.name} ${this.customer.name}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.customer.name}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.customer.name}`,
			message: `Вы уверены что хотите удалить клиента '${this.customer.name}'?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.customerService.remove(this.customer.customerId).subscribe(r => {

				this.router.navigate(['/catalogs/customers']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Клиент '${this.customer.name}' успешно удален`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении клиента`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.customer.name}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.customerService.archive(this.customer.customerId).subscribe(r => {

				this.router.navigate(['/catalogs/customers']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.customer.name}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

}