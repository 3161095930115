import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { RequestToAgree } from '../models/requests-to-agree/requests-to-agree';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { RequestToAgreeCounter } from '../models/requests-to-agree/requests-to-agree-counter';
import { SecurityService } from '../../core/services/security.service';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { NotificationService } from '../../core/services/notification.service';
import { ActionResult } from '../models/core/ActionResult';
import { KeyValueObject } from '../models/core/KeyValueObject';

@Injectable()
export class RequestToAgreeService extends DataService<RequestToAgree> {

	constructor(http: HttpClient,
				notificationService: NotificationService,
				private securityService: SecurityService) {
		super(http, notificationService);
		this.controllerName = "requests-to-agree";
	}

	private counters = new BehaviorSubject<RequestToAgreeCounter[]>([]);
	counters$ = this.counters.asObservable();


	refreshCounters(): void {
		
		if (!this.securityService.currentUser.employeeId)
			return;

		this.getCounters(this.securityService.currentUser.employeeId).subscribe(x => {
			if (x.isSuccessful){
				this.counters.next(x.data);
			}
		});

	}

	public requestsList(state: DataSourceRequestState, actionTypeId: string): Observable<ActionDataResult<RequestToAgree[]>> {
		const queryStr = `${toDataSourceRequestString(state)}`;

		return this.http.get<ActionDataResult<RequestToAgree[]>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/requests-list?${queryStr}`, {
			headers: new HttpHeaders({
				'actionTypeId': actionTypeId ? actionTypeId : ""
			})
		});
	}

	public makeAction(requestToAgreeId: number, actionType: number): Observable<ActionDataResult<RequestToAgree>> {
		return this.http.put<ActionDataResult<RequestToAgree>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/${requestToAgreeId}/action/${actionType}`, null);
	}

	public makeActionResult(requestToAgreeId: number, actionResult: number, comment: string): Observable<ActionDataResult<RequestToAgree>> {
		return this.http.put<ActionDataResult<RequestToAgree>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/${requestToAgreeId}/action-result/${actionResult}?comment=${comment}`, null);
	}

	public getCounters(employeeId: number): Observable<ActionDataResult<RequestToAgreeCounter[]>> {
		return this.http.get<ActionDataResult<RequestToAgreeCounter[]>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/counters/${employeeId}`);
	}

	public validate(model: RequestToAgree): Observable<ActionDataResult<RequestToAgree>> {
		return this.http.post<ActionDataResult<RequestToAgree>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/validate`, model);
	}

	public updateFactsFields(request: RequestToAgree): Observable<ActionResult> {
		return this.http.put<ActionResult>(`${environment.salaryCalcServiceUrl}${this.controllerName}/${request.requestToAgreeId}/update-fact`, request);
	}

	public getRequestsForManualAction(year: number, month: number, employeeId: number): Observable<ActionDataResult<KeyValueObject[]>> {
		return this.http.get<ActionDataResult<KeyValueObject[]>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/for-manual-action?year=${year}&month=${month}&employeeId=${employeeId}`);
	}

}