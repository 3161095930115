import { BaseObject } from "../../core/BaseObject";
import { AnalyticReportRow } from "./AnalyticReportRow";

export class AnalyticReport extends BaseObject {
    analyticReportId: number;
    salaryCalcReportId: number;
    month: number;
    year: number;
    name: string;
    hasPaid: number;
    isForWeekends: boolean;
    rows: AnalyticReportRow[] = [];
}