import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { TimeScheduleService } from '../../../shared/services/time-schedule.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TimeSchedule } from '../../../shared/models/TimeSchedule/TimeSchedule';
import { TimeScheduleDay } from '../../../shared/models/TimeSchedule/TimeScheduleDay';

@Component({
	selector: 'settings-time-schedule',
	templateUrl: './time-schedule.page.html',
	styleUrls: ['./time-schedule.page.scss']
})
export class TimeSchedulePage implements OnInit, TrackChangesComponent {
	
	@ViewChild('timeScheduleForm') public timeScheduleForm: NgForm;

	hasChanges(): boolean{
		return !this.timeScheduleForm.pristine;
	};

	canEditForm: boolean;
	timeScheduleId: string;
	timeSchedule: TimeSchedule;

	public daysGridSort: SortDescriptor[] = [{ field: 'orderNum', dir: 'asc' }];

	dayTypes: any[] = [{ Id: 1, Name: 'Рабочий' }, { Id: 2, Name: 'Выходной' }];

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private intl: IntlService,
		private timeScheduleService: TimeScheduleService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новый график";
		}

	ngOnInit() {

		this.timeScheduleId = this.route.snapshot.paramMap.get('timeScheduleId');

		if (this.timeScheduleId != null){

			this.timeScheduleService.getById(+this.timeScheduleId).subscribe(r => {
				this.dataBind(r.data);
			});

		} else {

			this.timeSchedule = new TimeSchedule();
			this.timeSchedule.createdDate = new Date();
			this.timeSchedule.startFrom = new Date();
			this.timeSchedule.entityStatus = 1;
			this.timeSchedule.entityStatusString = "Активен";

			this.dataBind(this.timeSchedule);

			this.canEditForm = true;
		}

	}

	dataBind(timeSchedule: TimeSchedule){
		timeSchedule.startFrom = this.appService.parseDate(new Date(timeSchedule.startFrom));
		this.timeSchedule = timeSchedule;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.timeScheduleService.upsert(this.timeSchedule).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.timeScheduleForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `График ${this.timeSchedule.name}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `График ${this.timeSchedule.name}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `График ${this.timeSchedule.name}`,
			message: "Вы уверены что хотите удалить график?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.timeScheduleService.remove(this.timeSchedule.timeScheduleId).subscribe(r => {

				this.router.navigate(['/settings/timeSchedules']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `График ${this.timeSchedule.name} успешно удален`,
						//message: "Пользователь успешно удален",
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: "Ошибка при удалении графика",
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `График ${this.timeSchedule.name}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.timeScheduleService.archive(this.timeSchedule.timeScheduleId).subscribe(r => {

				this.router.navigate(['/settings/timeSchedules']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.timeSchedule.name}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	getDayTypeString(dayType: number){
		var filtered = this.dayTypes.filter(x => x.Id == dayType);

		if (filtered.length === 0)
			return "";

		return filtered[0].Name;
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}
	
	public cellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 1)
			return;

        sender.editRow(rowIndex);
        
	}

	get createNewDay(): any {

		var daysQuantity = this.timeSchedule.days.length;

		return function() {
			var day = new TimeScheduleDay();
			day.orderNum = daysQuantity + 1;
			day.dayType = 1;
			day.workHoursQuantity = 9;
			return day;
		};


	}
	
	public saveHandler(obj: any) {
		var workHoursQuantity = obj.dataItem.workHoursQuantity;

		if (workHoursQuantity == null)
			obj.dataItem = null;
		
		this.appService.MarkFormDirty(this.timeScheduleForm);
	}

	public removeHandler() {
        this.appService.MarkFormDirty(this.timeScheduleForm);
    }

}