import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { DirtyStateGuard } from '../core/guards/dirty-state.guard';

import { SalaryCalcReportListPage } from './salary-calc-reports/list/list.page';
import { SalaryCalcReportPage } from './salary-calc-reports/salary-calc-report/salary-calc-report.page';
import { AnalyticReportListPage } from './analytic-reports/list/list.page';
import { AnalyticReportPage } from './analytic-reports/analytic-report/analytic-report.page';
import { RequestToAgreeReportListPage } from './request-to-agree-reports/list/list.page';
import { RequestToAgreeReportPage } from './request-to-agree-reports/request-to-agree-report/request-to-agree-report.page';
import { OrgStructureReportPage } from './org-structure-report/org-structure-report.page';

export const reportsRoutes: Routes = [

  { path: 'salary-calc/workdays', component: SalaryCalcReportListPage, canActivate: [AuthGuard], data: { isForWeekends: false, categoryPermissionClaim: 'cat-reports-salary-calc-read' } },
  { path: 'salary-calc/weekends', component: SalaryCalcReportListPage, canActivate: [AuthGuard], data: { isForWeekends: true, categoryPermissionClaim: 'cat-reports-salary-calc-read' } },

  { path: 'salary-calc/workdays/new', component: SalaryCalcReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: false, categoryPermissionClaim: 'cat-reports-salary-calc-edit' } },
  { path: 'salary-calc/weekends/new', component: SalaryCalcReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: true, categoryPermissionClaim: 'cat-reports-salary-calc-edit' } },

  { path: 'salary-calc/workdays/:salaryCalcReportId', component: SalaryCalcReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: false, categoryPermissionClaim: 'cat-reports-salary-calc-read' } },
  { path: 'salary-calc/weekends/:salaryCalcReportId', component: SalaryCalcReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: true, categoryPermissionClaim: 'cat-reports-salary-calc-read' } },

  { path: 'analytic/workdays', component: AnalyticReportListPage, canActivate: [AuthGuard], data: { isForWeekends: false, categoryPermissionClaim: 'cat-reports-analytic-read' } },
  { path: 'analytic/weekends', component: AnalyticReportListPage, canActivate: [AuthGuard], data: { isForWeekends: true, categoryPermissionClaim: 'cat-reports-analytic-read' } },

  { path: 'analytic/workdays/new', component: AnalyticReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: false, categoryPermissionClaim: 'cat-reports-analytic-read' } },
  { path: 'analytic/weekends/new', component: AnalyticReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: true, categoryPermissionClaim: 'cat-reports-analytic-read' } },

  { path: 'analytic/workdays/:analyticReportId', component: AnalyticReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: false, categoryPermissionClaim: 'cat-reports-analytic-read' } },
  { path: 'analytic/weekends/:analyticReportId', component: AnalyticReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: true, categoryPermissionClaim: 'cat-reports-analytic-read' } },

  { path: 'requests-to-agree', component: RequestToAgreeReportListPage, canActivate: [AuthGuard], data: { isForWeekends: false, categoryPermissionClaim: 'cat-reports-request-to-agree-read' } },
  { path: 'requests-to-agree/new', component: RequestToAgreeReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: false, categoryPermissionClaim: 'cat-reports-request-to-agree-edit' } },
  { path: 'requests-to-agree/:requestToAgreeReportId', component: RequestToAgreeReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: true, categoryPermissionClaim: 'cat-reports-request-to-agree-read' } },

  { path: 'org-structure', component: OrgStructureReportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { isForWeekends: true, categoryPermissionClaim: 'cat-reports-orgstructure-read' } },
];
