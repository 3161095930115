import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { AuthUserDto } from '../../shared/models/Security/AuthUserDto';
import { ActionResult } from '../../shared/models/core/ActionResult';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import { LoginDto } from '../../shared/models/LoginDto';
import { environment } from '../../../environments/environment'

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};

@Injectable({
	providedIn: 'root'
})
export class SecurityService {
	_currentUser: AuthUserDto = new AuthUserDto();

	get currentUser(): AuthUserDto {
		return this._currentUser;
	}

	set currentUser(value: AuthUserDto) {
		this._currentUser = value;
	}

	constructor(private http: HttpClient) {
	}

	login(entity: LoginDto): Observable<ActionDataResult<AuthUserDto>> {
		// Initialize security object
		this.resetSecurityObject();

		const apiUrl = environment.salaryCalcServiceUrl + "security/login";

		return this.http.post<ActionDataResult<AuthUserDto>>(apiUrl, entity, httpOptions).pipe(
			tap(resp => {
				this._currentUser = resp.data;
				sessionStorage.setItem("authUser", JSON.stringify(this._currentUser));
			}));
	}

	logout(): void {
		this.resetSecurityObject();
	}

	resetSecurityObject(): void {

		if (this._currentUser) {
			this._currentUser.userName = "";
			this._currentUser.bearerToken = "";
			this._currentUser.isAuthenticated = false;
			this._currentUser.employeeId = null;
			this._currentUser.employeeDisplayName = null;
			this._currentUser.claims = [];
		}

		sessionStorage.removeItem("authUser");
	}

	hasClaim(claimKey: any, claimValue?: any): boolean {
		let ret: boolean = false;

		if (this.isClaimValid("IsAdmin"))
			return true;

		// See if an array of values was passed in.
		if (typeof claimKey === "string") {
			ret = this.isClaimValid(claimKey, claimValue);
		}
		else {
			let claims: string[] = claimKey;
			if (claims) {
				for (let index = 0; index < claims.length; index++) {
					ret = this.isClaimValid(claims[index]);
					// If one is successful, then let them in
					if (ret) {
						break;
					}
				}
			}
		}

		return ret;
	}


	private isClaimValid(claimKey: string, claimValue?: string): boolean {
		let ret: boolean = false;
		let auth: AuthUserDto = null;

		// Retrieve security object
		auth = this._currentUser;
		if (auth) {
			// See if the claim type has a value
			// *hasClaim="'claimType:value'"
			if (claimKey.indexOf(":") >= 0) {
				let words: string[] = claimKey.split(":");
				claimKey = words[0].toLowerCase();
				claimValue = words[1];
			}
			else {
				claimKey = claimKey.toLowerCase();
				// Either get the claim value, or assume 'true'
				claimValue = claimValue ? claimValue : "true";
			}
			// Attempt to find the claim
			ret = auth.claims.find(c =>
				c.key.toLowerCase() == claimKey &&
				c.value == claimValue) != null;
		}

		return ret;
	}
}