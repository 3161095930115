import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { MailingGroup } from '../models/mailings/mailing-group';

@Injectable()
export class MailingGroupsService extends DataService<MailingGroup> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "mailing-groups";
	}

}