import { BaseObject } from '../core/BaseObject';
import { RequestToAgreeTariffTimeSchedule } from './request-to-agree-tariff-time-schedule';
import { RequestToAgreeTariffDayType } from './request-to-agree-tariff-day-type';
import { RequestToAgreeTariffPeriod } from './request-to-agree-tariff-period';

export class RequestToAgreeTariff extends BaseObject {
    requestToAgreeTariffId: number;
    name: string;
    startsFrom: Date;
    isFree: boolean;

    daysQuantity: number;
    hoursQuantity: number;
    factor: number;
    entityStatusDate: Date;

    requestToAgreeTariffTimeSchedules: RequestToAgreeTariffTimeSchedule[] = [];
    requestToAgreeTariffDayTypes: RequestToAgreeTariffDayType[] = [];
    requestToAgreeTariffPeriods: RequestToAgreeTariffPeriod[] = [];
}