import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupDescriptor, DataResult, process, DataSourceRequestState, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { Observable, Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { UsersService } from '../users.service';
import { User } from '../../../shared/models/User/User';
import { RolesService } from '../../roles/roles.service';
import { Role } from '../../../shared/models/Role/Role';
import { Employee } from '../../../shared/models/Employee/Employee';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { UserRole } from '../../../shared/models/User/UserRole';
import { EmployeeService } from '../../../shared/services/employee.service';
import { UserEmployee } from '../../../shared/models/User/UserEmployee';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { OrgUnit } from '../../../shared/models/org-unit/org-unit';
import { OrgUnitService } from '../../../shared/services/org-unit.service';
import { UserOrgUnit } from '../../../shared/models/User/UserOrgUnit';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

const flatten = filter => {
    const filters = filter.filters;
    if (filters) {
        return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
    }
    return [];
};


@Component({
	selector: 'settings-user',
	templateUrl: './user.page.html',
	styleUrls: ['./user.page.scss']
})
export class UserPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('userForm') public userForm: NgForm;

	hasChanges(): boolean{
		return !this.userForm.pristine;
	};

	canEditForm: boolean;
	userId: string;
	user: User;

	public employeeToAddDefaultItem: { displayName: string, employeeId: number } = { displayName: "(Выберите сотрудника)", employeeId: null };
	public employeeToAdd: { displayName: string, employeeId: number } = this.employeeToAddDefaultItem;
	employees: Employee[] = [];

	private roleFilterValueSubject: Subject<string> = new Subject();
	public rolesToAddDefaultItem: { name: string, roleId: number } = { name: "(Выберите роль)", roleId: null };
	public roleToAdd: { name: string, roleId: number } = this.rolesToAddDefaultItem;
	roles: Role[] = [];

	public orgUnitToAddDefaultItem: { orgUnitName: string, orgUnitId: number } = { orgUnitName: "(Выберите орг. единицу)", orgUnitId: null };

	orgUnits: OrgUnit[] = [];


	private employeeFilterValueSubject: Subject<string> = new Subject();

	public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

	public rolesState: DataSourceRequestState = {
		skip: 0,
		take: 100000,
		sort: [{ field: "name", dir: "asc" }],
		filter: {
			logic: "and",
			filters: [
				{ field: "entityStatus", operator: "eq", value: 1 }
			]
		}
	};

	public employeesState: DataSourceRequestState = {
		skip: 0,
		take: 100000,
		sort: [{ field: "displayName", dir: "asc" }],
		filter: {
			logic: "and",
			filters: [
				{ field: "entityStatus", operator: "eq", value: 1 }
			]
		}
	};

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private notificationService: NotificationService,
		private usersService: UsersService,
		private rolesService: RolesService,
		private employeeService: EmployeeService,
		private orgUnitService: OrgUnitService,
		) {
			this.appService.pageTitle = "Новый пользователь";
		}

	ngOnInit() {

		this.userId = this.route.snapshot.paramMap.get('userId');

		if (this.userId != null){

			this.usersService.getById(+this.userId).subscribe(r => {
				this.dataBind(r.data);
			});

		} else {

			this.user = new User();
			this.user.createdDate = new Date();
			this.user.entityStatus = 1;
			this.user.entityStatusString = "Активен";

			this.dataBind(this.user);

			this.canEditForm = true;
		}

		this.orgUnitService.list({
			skip: 0,
			take: null,
			sort: [{ field: "orgUnitName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.subscribe(data => this.orgUnits = data);

		this.roleFilter("");
		this.employeeFilter("");

		this.roleFilterValueSubject.pipe(debounceTime(400)).subscribe(value => {
			this.roleFilter(value);
		});

		
		this.employeeFilterValueSubject.pipe(debounceTime(400)).subscribe(value => {
			this.employeeFilter(value);
		});

	}

	dataBind(user: User){
		this.user = user;
		//this.gridData = process(this.user.roleCategories, { group: [{ field: 'groupName' }], sort: [{ field: "categoryId" }] });

		if (this.user.name && this.user.name !== "")
			this.appService.pageTitle = this.user.name;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.usersService.upsert(this.user).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.userForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: this.user.name, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: "Ошибка при сохранении изменений!", title: this.user.name, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemoveUser(): void {

		this.notificationService.confirmation({
            title: '',
			message: "Вы уверены что хотите удалить пользователя?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.usersService.remove(this.user.userId).subscribe(r => {

				this.router.navigate(['/settings/users']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: '',
						message: "Пользователь успешно удален",
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: "Ошибка при удалении пользователя",
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});

	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.user.displayName}`,
			message: "Вы уверены что хотите архивировать данную запись?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.usersService.archive(this.user.userId).subscribe(r => {

				this.router.navigate(['/settings/users']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.user.displayName}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	handleRoleFilter(value) {
		this.roleFilterValueSubject.next(value);
	}

	handleEmployeeFilter(value) {
		this.employeeFilterValueSubject.next(value);
	}
	
	roleFilter(value: string){

		this.rolesState.filter.filters = [];

		this.rolesState.filter.filters.push({ field: "entityStatus", operator: "eq", value: 1 });
		
		if (value !== "") {
			this.rolesState.filter.filters.push({
				field: 'Name',
				operator: 'contains',
				value: value,
				ignoreCase: true
			});
		}

		this.rolesService.list(this.rolesState).subscribe(data => {
			return this.roles = data.data;
		});
	}

	employeeFilter(value: string){

		this.employeesState.filter.filters = [];

		this.employeesState.filter.filters.push({ field: "entityStatus", operator: "eq", value: 1 });
		
		if (value !== "") {
			this.employeesState.filter.filters.push({
				field: 'Surname',
				operator: 'contains',
				value: value,
				ignoreCase: true
			});
		}

		this.employeeService.list(this.employeesState).subscribe(data => {
			return this.employees = data.data;
		});
	}

	addNewRole(): void{

		var newUserRole = new UserRole();

		var filtered = this.user.userRoles.filter(x => x.roleId == this.roleToAdd.roleId);

		if (filtered.length > 0){
			newUserRole = filtered[0];
		} else{
			newUserRole.roleId = this.roleToAdd.roleId;
			newUserRole.roleName = this.roleToAdd.name;
			newUserRole.userId = this.user.userId;
			this.user.userRoles.push(newUserRole);
		}
	}

	addNewEmployee(): void{

		var newUserEmployee = new UserEmployee();

		var filtered = this.user.userEmployees.filter(x => x.employeeId == this.employeeToAdd.employeeId);

		if (filtered.length > 0){
			newUserEmployee = filtered[0];
		} else{
			newUserEmployee.employeeId = this.employeeToAdd.employeeId;
			newUserEmployee.employeeDisplayName = this.employeeToAdd.displayName;
			newUserEmployee.userId = this.user.userId;
			this.user.userEmployees.push(newUserEmployee);
		}
	}

	//////////////////////////////////

	get createNewEmployee(): any {

		return function() {
			var userEmployee = new UserEmployee();
			return userEmployee;
		};
	}

	get createNewRole(): any {

		return function() {
			var userRole = new UserRole();
			return userRole;
		};
	}

	public rowClass(context: RowClassArgs) {
		return {
			"cursor-pointer": true
		};
	}
	
	public cellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 0)
			return;

        sender.editRow(rowIndex);
        
	}

	public saveHandler(obj: any) {
		this.appService.MarkFormDirty(this.userForm);
	}

	public removeHandler(obj: any) {
        this.appService.MarkFormDirty(this.userForm);
	}
	
	getEmployeeDisplayName(empoyeeId: number){
		var filtered = this.employees.filter(x => x.employeeId == empoyeeId);

		if (filtered.length === 0)
			return "";

		return filtered[0].displayName;
	}

	getRoleName(roleId: number){
		var filtered = this.roles.filter(x => x.roleId == roleId);

		if (filtered.length === 0)
			return "";

		return filtered[0].name;
	}


	get createNewOrgUnit(): any {

		return function() {
			var userOrgUnit = new UserOrgUnit();
			userOrgUnit.userId = 0;
			return userOrgUnit;
		};
	}

	public orgUnitCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 0)
			return;

        sender.editRow(rowIndex);
        
	}

	public orgUnitSaveHandler(obj: any) {

		if (this.user.userOrgUnits.some(x => x.orgUnitId == obj.dataItem.orgUnitId))
		{
			obj.dataItem = null;
			return;
		}

		this.appService.MarkFormDirty(this.userForm);
	}

	public orgUnitRemoveHandler(obj: any) {
		this.appService.MarkFormDirty(this.userForm);
	}

	getOrgUnitName(orgUnitId: number){
		var filtered = this.orgUnits.filter(x => x.orgUnitId == orgUnitId);

		if (filtered.length === 0)
			return "";

		return filtered[0].orgUnitName;
	}


}