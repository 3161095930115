import { BaseObject } from '../core/BaseObject';
import { RequestToAgreeAction } from './request-to-agree-action';
import { RequestToAgreeValidation } from './request-to-agree-validation';


export class RequestToAgree extends BaseObject {
    requestToAgreeId: number;
    responsibleEmployeeId: number;
    responsibleEmployeeDisplayName: string;
    performerEmployeeId: number;
    performerEmployeeDisplayName: string;
    requestToAgreeTariffId: number;
    requestToAgreeTariffName: string;
    currentActionStage: number;
    description: string;
    status: number;
    statusString: string;
    useHigherRatio: boolean;
    higherRatio: number;
    plannedStartDate: Date;
    plannedEndDate: Date;
    plannedLunchTime: number;
    plannedTime: number;
    plannedPricePerUnit: number;
    plannedQuantity: number;
    plannedAmount: number;

    factStartDate: Date;
    factEndDate: Date;
    factLunchTime: number;
    factTime: number;
    factPricePerUnit: number;
    factQuantity: number;
    factAmount: number;

    totalAmount: number;
    totalAmountComment: string;

    comments: string;
    
    entityStatusDate: Date;

    actions: RequestToAgreeAction[] = [];
    validations: RequestToAgreeValidation[] = [];
}