import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityService } from './services/security.service';
import { HttpInterceptorModule } from './modules/http-interceptor.module';
import { DataService } from './services/data.service';
import swal from 'sweetalert2';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

import { NotificationService } from './services/notification.service';



@NgModule({
  imports: [
    CommonModule,
    HttpInterceptorModule,
    SweetAlert2Module,
    
  ],
  declarations: [

  ],
  providers: [
    SecurityService,
    DataService,
    NotificationService,
    { provide: 'sweetAlert', useValue: swal },
  ],
  exports: [HttpInterceptorModule]
})
export class CoreModule { }
