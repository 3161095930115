import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { Role } from '../../../shared/models/Role/Role';
import { RolesService } from '../roles.service';
import { AppService } from '../../../app.service';
import { FilterStorage } from '../../../shared/components/filter-storage/filter-storage'; 
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';

@Component({
	selector: 'settings-roles',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class RolesListPage extends ListPageBase {

	public roles: ActionDataResult<Role[]>;

	constructor(
		appService: AppService,
		router: Router,
		filterStorage: FilterStorage,
		private rolesService: RolesService) {

		super(appService, router, filterStorage, rolesService);

		this.appService.pageTitle = "Роли";
	}

	loadData(): void {
		super.loadData();

		this.rolesService.list(this.state).subscribe(r => {
			this.loading = false;
			return this.roles = r;
		});
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/settings/role', dataItem.roleId]);
	}
}