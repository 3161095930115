export enum RequestToAgreeActionResult{
    /** Согласовал */
    Approved = 1,

    /** Отклонил */
    Canceled = 2,

    /** Ознакомлен */
    Acquaintanced = 3,

    /** Выполнил */
    Completed = 4,

    /** Не выполнил */
    NotCompleted = 5,

    /** Подтвердил */
    Confirmed = 6,
}