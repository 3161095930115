import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from '../../shared/models/Role/Role';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class RolesService extends DataService<Role> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "roles";
	}
}