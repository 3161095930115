import { Component, OnInit, Renderer, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { LoginDto } from '../shared/models/LoginDto';
import { NgForm } from '@angular/forms';
import { SecurityService } from '../core/services/security.service';
import { RequestToAgreeService } from '../shared/services/request-to-agree.service';

@Component({
	selector: 'login',
	templateUrl: './login.page.html',
	styleUrls: [
		'../../vendor/styles/pages/authentication.scss',
		'./login.page.scss'
	]
})
export class LoginPage implements OnInit {

	@ViewChild('loginForm') loginForm: NgForm;

	credentials: LoginDto = new LoginDto();
	returnUrl: string;
	error: string = "";
	dataLoading: boolean = false;

	constructor(private appService: AppService,
		private securityService: SecurityService,
		private router: Router,
		private route: ActivatedRoute,
		private requestToAgreeService: RequestToAgreeService
		) {
		this.appService.pageTitle = 'Вход';
	}

	ngOnInit() {
		this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
	}

	signIn() {

		if (this.loginForm.invalid){
			this.appService.MarkFormDirty(this.loginForm);
			return;
		}
			

		this.error = '';
		this.dataLoading = true;
		this.securityService.login(this.credentials).subscribe(authUser => {

			this.dataLoading = false;
			if (!authUser.isSuccessful) {
				this.error = authUser.errorDescription;
				return;
			}

			this.requestToAgreeService.refreshCounters();

			this.router.navigateByUrl(this.returnUrl ? this.returnUrl : '');
		});
	}

}