import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { PositionService } from '../../../shared/services/position.service';
import { OrgUnit } from '../../../shared/models/org-unit/org-unit';
import { OrgUnitService } from '../../../shared/services/org-unit.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';

@Component({
	selector: 'catalogs-org-unit',
	templateUrl: './org-unit.page.html',
	styleUrls: ['./org-unit.page.scss']
})
export class OrgUnitPage implements OnInit, TrackChangesComponent {
	
	@ViewChild('orgUnitForm') public orgUnitForm: NgForm;

	hasChanges(): boolean{
		return !this.orgUnitForm.pristine;
	};

	canEditForm: boolean;
	orgUnitId: string;
	orgUnit: OrgUnit;
	public positions: KeyValueObject[] = [];
	public orgUnits: KeyValueObject[] = [];

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private orgUnitService: OrgUnitService,
		private positionService: PositionService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Новая орг. единица";
		}

	ngOnInit() {

		this.orgUnitId = this.route.snapshot.paramMap.get('orgUnitId');

		this.positionService.list({
			skip: 0,
			take: null,
			sort: [{ field: "positionName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.map(x => x.map(x => new KeyValueObject(x.positionId, x.positionName, x.entityStatusString)))
		.subscribe(data => this.positions = data);

		this.orgUnitService.list({
			skip: 0,
			take: null,
			sort: [{ field: "orgUnitName", dir: "asc" }],
			filter: {logic: "and",filters: [{ field: "entityStatus", operator: "eq", value: 1 }]}
		})
		.map(x => x.data)
		.map(x => x.map(x => new KeyValueObject(x.orgUnitId, x.orgUnitName, x.entityStatusString)))
		.subscribe(data => {

			if (this.orgUnitId != null){
				data = data.filter(x => x.id !== +this.orgUnitId);
			}

			this.orgUnits = data;
		});

		if (this.orgUnitId != null){

			this.orgUnitService.getById(+this.orgUnitId).subscribe(r => {
				this.dataBind(r.data);

				this.appService.pageTitle = r.data.orgUnitName;
			});

		} else {

			this.orgUnit = new OrgUnit();
			this.orgUnit.createdDate = new Date();
			this.orgUnit.entityStatus = 1;
			this.orgUnit.entityStatusString = "Активен";
			this.orgUnit.hasTakenPartInRequestAgreement = true;

			this.dataBind(this.orgUnit);
			this.canEditForm = true;
		}

	}

	dataBind(orgUnit: OrgUnit){
		this.orgUnit = orgUnit;
	}

	toggleFormEdit(): void{
		this.canEditForm = !this.canEditForm;
	}

	saveChanges(): void {

		this.orgUnitService.upsert(this.orgUnit).subscribe(r => {

			if (r.isSuccessful){
				this.dataBind(r.data);
				this.appService.MarkFormPristine(this.orgUnitForm);
				this.canEditForm = false;
				this.notificationService.success({message: "Изменения сохранены успешно!", title: `${this.orgUnit.orgUnitName}`, notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: `${this.orgUnit.orgUnitName}`, notificationType: NotificationType.Toast});
			}
			
		});
	}

	askRemove(){
		this.notificationService.confirmation({
            title: `${this.orgUnit.orgUnitName}`,
			message: `Вы уверены что хотите удалить орг. единицу'?`,
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.orgUnitService.remove(this.orgUnit.orgUnitId).subscribe(r => {

				this.router.navigate(['/catalogs/org-units']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Орг. единица '${this.orgUnit.orgUnitName}' успешно удалена`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при удалении орг. единицы`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

	askArchive() {
		this.notificationService.confirmation({
            title: `${this.orgUnit.orgUnitName}`,
			message: "Вы уверены что хотите архивировать данную орг. единицу?",
			type: "question",
			confirmButtonText: "Да",
            cancelButtonText: "Нет",
            showCloseButton: true
        }, () => {

			this.orgUnitService.archive(this.orgUnit.orgUnitId).subscribe(r => {

				this.router.navigate(['/catalogs/org-units']);
	
				if (r.isSuccessful){
					this.notificationService.success({
						title: `Запись '${this.orgUnit.orgUnitName}' успешно архивирована`,
						notificationType: NotificationType.SweetAlert
					});
	
				} else{
					this.notificationService.error({
						title: `Ошибка при архивировании записи`,
						message: r.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
	
			});

		});
	}

}