import { BaseObject } from "../core/BaseObject";

export class RequestFile extends BaseObject{
    requestFileId: number;
    uploadState: number;
    uploadStateName: string;
    fileName: string;
    //filePath: string;
    requestsCount: number;
    newRequestsCount: number;
    duplicateRequestsCount: number;
    errors: string;
    uploadedUserName: string;
}