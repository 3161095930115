import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { NotificationService } from '../../core/services/notification.service';
import { OrgStructureReportUnit } from '../models/Reports/OrgStructure/OrgStructureReportUnit'
import { tap } from 'rxjs/internal/operators/tap';
import { NotificationType } from '../../core/services/notification-type';
import { OrgStructureEmployee } from '../models/Reports/OrgStructure/OrgStructureEmployee';

@Injectable()
export class OrgStructureReporsService {

  protected controllerName: string = "orgstructure-report";

  constructor(protected http: HttpClient,
    protected notificationService: NotificationService
  ) { }

  public getStructure(orgUnitId?: number): Observable<ActionDataResult<OrgStructureReportUnit[]>> {
    return this.http.get<ActionDataResult<OrgStructureReportUnit[]>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/get-structure${orgUnitId ? `?orgUnitId=${orgUnitId}` : ''}`)
      .pipe(tap(resp => {
        if (!resp.isSuccessful) {
          this.notificationService.error({
            title: 'Ошибка',
            message: resp.errorDescription,
            notificationType: NotificationType.SweetAlert
          });
        }
      }));
  }

  public getApprovalChain(employeeId: number): Observable<ActionDataResult<OrgStructureEmployee>> {
    return this.http.get<ActionDataResult<OrgStructureEmployee>>(`${environment.salaryCalcServiceUrl}${this.controllerName}/get-approval-chain?employeeId=${employeeId}`)
      .pipe(tap(resp => {
        if (!resp.isSuccessful) {
          this.notificationService.error({
            title: 'Ошибка',
            message: resp.errorDescription,
            notificationType: NotificationType.SweetAlert
          });
        }
      }));
  }
}
