import { BaseObject } from "../core/BaseObject";
import { MailingRegisterReport } from "./mailing-register-report";

export class MailingRegister extends BaseObject {
    mailingRegisterId: number;
    mailingGroupId: number;
    mailingGroupName: string;
    reportTypes: string;

    reports: MailingRegisterReport[] = [];
}