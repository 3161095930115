import { Routes } from '@angular/router';
import { DirtyStateGuard } from '../core/guards/dirty-state.guard';
import { AuthGuard } from '../core/guards/auth.guard';

import { RequestListPage } from './requests/list/list.page';
import { RequestPage } from './requests/request/request.page';
import { RequestFilesListPage } from './request-files/list/list.page';
import { RequestFilePage } from './request-files/request-file/request-file.page';
import { UploadFilesPage } from './request-files/upload-files/upload-files.page';
import { ManualActionListPage } from './manual-actions/list/list.page';
import { ManualActionPage } from './manual-actions/manual-action/manual-action.page';
import { TariffListPage } from './tariffs/list/list.page';
import { TariffPage } from './tariffs/tariff/tariff.page';
import { RequestToAgreeTariffListPage } from './request-to-agree-tariff/list/list.page';
import { RequestToAgreeTariffPage } from './request-to-agree-tariff/request-to-agree-tariff/request-to-agree-tariff.page';
import { RequestToAgreeListPage } from './requests-to-agree/list/list.page';
import { RequestToAgreePage } from './requests-to-agree/request-to-agree/request-to-agree.page';

export const documentsRoutes: Routes = [

	{ path: 'requests', component: RequestListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-requests-read' } },
	{ path: 'request/new', component: RequestPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-requests-edit' } },
	{ path: 'request/:requestId', component: RequestPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-requests-read' } },

	{ path: 'request-files', component: RequestFilesListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-request-files-read' } },
	{ path: 'request-file/new', component: UploadFilesPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-request-files-edit' } },
	{ path: 'request-file/:requestFileId', component: RequestFilePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-request-files-read' } },

	{ path: 'manual-actions', component: ManualActionListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-manual-actions-read' } },
	{ path: 'manual-action/new', component: ManualActionPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-manual-actions-edit' } },
	{ path: 'manual-action/:manualActionId', component: ManualActionPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-manual-actions-read' } },

	{ path: 'tariffs', component: TariffListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-tariffs-read' } },
	{ path: 'tariff/new', component: TariffPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-tariffs-edit' } },
	{ path: 'tariff/:tariffId', component: TariffPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-tariffs-read' } },

	{ path: 'request-to-agree-tariffs', component: RequestToAgreeTariffListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-request-to-agree-tariffs-read' } },
	{ path: 'request-to-agree-tariff/new', component: RequestToAgreeTariffPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-request-to-agree-tariffs-edit' } },
	{ path: 'request-to-agree-tariff/:requestToAgreeTariffId', component: RequestToAgreeTariffPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-request-to-agree-tariffs-read' } },

	{ path: 'requests-to-agree', component: RequestToAgreeListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-requests-to-agree-read' } },
	{ path: 'requests-to-agree/:actionTypeId', component: RequestToAgreeListPage, canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-requests-to-agree-read' } },
	{ path: 'request-to-agree/new', component: RequestToAgreePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-requests-to-agree-edit' } },
	{ path: 'request-to-agree/:requestToAgreeId', component: RequestToAgreePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard], data: { categoryPermissionClaim: 'cat-documents-requests-to-agree-read' } },

];