import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { AppService } from '../../../app.service';
import { RequestFilesService } from '../../../shared/services/request-files.service';
import { FileInfo, FileRestrictions, UploadEvent } from '@progress/kendo-angular-upload';

@Component({
	selector: 'document-upload-files',
	templateUrl: './upload-files.page.html',
	styleUrls: [
		'./upload-files.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class UploadFilesPage implements OnInit, TrackChangesComponent {
	
	hasChanges(): boolean {
		return false;
	};

	canUpload: boolean = false;
	dataUploading: boolean = false;

	uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
	uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
	formData = new FormData();
	bulkUpdateName: string;
	tmpFiles: Array<FileInfo>;

	extensionRestrictions: FileRestrictions = {
		allowedExtensions: ['.xls', '.xlsx']
	};

	constructor(
		private appService: AppService,
		private router: Router,
		private requestFilesService: RequestFilesService,
		private notificationService: NotificationService) {
			this.appService.pageTitle = "Загрузка файлов";
		}

	ngOnInit() {

	}

	
	uploadEventHandler(e: UploadEvent) {

		var files = e.files;

		files.forEach(element => {

			if (this.formData.has(element.rawFile.name)) {
				this.formData.set(element.rawFile.name, element.rawFile);
			}
			else {
				this.formData.append(element.rawFile.name, element.rawFile);
			}

		});
	}

	successUploadedEventHandler(e: any) {
		this.canUpload = true;
	}

	Upload() {

		this.dataUploading = true;

		// setTimeout(() => {
		// 	this.dataUploading = false;
		// 	this.notificationService.success({message: "Файлы успешно загружены!", title: "Загрузка файлов", notificationType: NotificationType.Toast});
		// 	this.router.navigate(['/documents/request-files']);
		// }, 5000);

		this.requestFilesService.uploadFiles(this.formData).subscribe(r => {

			if (r.isSuccessful){
				this.notificationService.success({message: "Файлы успешно загружены!", title: "Загрузка файлов", notificationType: NotificationType.Toast});
			} else{
				this.notificationService.error({message: r.errorDescription, title: "Загрузка файлов", notificationType: NotificationType.Toast});
			}

			this.dataUploading = false;

			this.router.navigate(['/documents/request-files']);
			
		});


	}


}