import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { OrgUnit } from '../models/org-unit/org-unit';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class OrgUnitService extends DataService<OrgUnit> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = "org-units";
	}

}